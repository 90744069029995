import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import LoadingOverlay from "../../../common/userFeedback/LoadingOverlay";

const PlotlyRenderers = createPlotlyRenderers(Plot);
function GarmentPivotTable({ rawData }) {
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState(rawData);
  useEffect(() => {
    console.log("rawData", rawData);
    setData(rawData);
    setDataLoading(false);
  }, [rawData]);

  const { palette, typography } = useTheme();

  return (
    <>
      <LoadingOverlay open={dataLoading} />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            mt: 1,
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            minHeight: "50vh",
            overflowX: "auto",
            display: "flex",
          }}
        >
          <PivotTableUI
            data={data}
            onChange={setData}
            {...data}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
      </Box>
    </>
  );
}

export default GarmentPivotTable;
