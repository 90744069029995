import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  TextField,
  FormControl,
  Autocomplete,
  Box,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";

const SearchableMultiSelectV2 = ({
  typeMapping,
  selected,
  onSelectedChange,
  label,
  sx={},
  orderBy=null
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const currentRequest = useRef(null);

  const handleSelectionChange = (event, option) => {
    if (event.target.checked) {
      onSelectedChange([...selected, option]); // Add the whole option object
    } else {
      onSelectedChange(selected.filter((item) => item.id !== option.id)); // Remove by id
    }
  };

  const fetchOptions = async (searchTerm) => {
    if (currentRequest.current) {
      currentRequest.current.cancel();
    }

    try {
      const headers = await getHeaders();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      currentRequest.current = source;

      setLoading(true);
      console.log(`searchTermsearchTermsearchTerm: ${JSON.stringify(searchTerm)}`)
      const response = await axios.get(
        `${BASE_URL}tools/general/filteroptions/listall/${typeMapping}`,
        {
          ...headers,
          params: { search: searchTerm },
          cancelToken: source.token,
        }
      );

      let results = response.data.data;
      if (orderBy && orderBy !== "")
      {
        console.log("resultsresults", JSON.stringify(results));
        results = response.data.data.sort((a, b) => a[orderBy].localeCompare(b[orderBy]))
        console.log("resultsresults", JSON.stringify(results));
      }

      setOptions(results);
      setLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Failed to fetch options", error);
      }
      setLoading(false);
    }
  };

  const debouncedFetchOptions = debounce(fetchOptions, 300);

  useEffect(() => {
    if (inputValue) {
      debouncedFetchOptions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    fetchOptions("");
  }, []);

  return (
    <Box ref={componentRef} sx={sx}>
      <FormControl component="fieldset" variant="standard">
        <Autocomplete
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { s }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} 
                      checked={selected.some(
                        (selectedOption) => selectedOption.id === option.id
                      )}
                      onChange={(e) => handleSelectionChange(e, option)} />
              {option.name}
            </li>
          )}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              margin="normal"
              onChange={(e) => setInputValue(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={selected}
          onChange={(event, newValue) => {
            onSelectedChange(newValue);
          }}
        />
      </FormControl>
    </Box>
  );
};

export default SearchableMultiSelectV2;
