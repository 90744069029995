import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Modal,
  CircularProgress,
  Button,
  Checkbox,
  Grid,
} from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import { APP_BAR_HEIGHT } from "../../../../components/layout/AppBar/AppBarComponent";
import AggregationLinkingProgressPivotTable from "./AggregationLinkingProgressPivotTable";
import AggregationGroupingProgressPivotTable from "./AggregationGroupingProgressPivotTable";
import AggregationGroupingUserProgressPivotTable from "./AggregationGroupingUserProgressPivotTable";
import AggregationLinkingUserProgressPivotTable from "./AggregationLinkingUserProgressPivotTable";
const PlotlyRenderers = createPlotlyRenderers(Plot);

function AggregationAnalyticsDashboard() {
  const { palette, typography } = useTheme();
  const [dataLoading, setDataLoading] = useState(false);
  const aggregationLinkingPivotRef = useRef();
  const aggregationGroupingPivotRef = useRef();
  const aggregationGroupingUserProgressPivotRef = useRef();
  const aggregationLinkingUserProgressPivotRef = useRef();

  const refreshData = async () => {
    aggregationLinkingPivotRef.current?.refreshData();
    aggregationGroupingPivotRef.current?.refreshData();
    aggregationGroupingUserProgressPivotRef.current?.refreshData();
    aggregationLinkingUserProgressPivotRef.current?.refreshData();
  };

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
    <Box
    sx={{
      height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      p:2,
    }} 
    >
      <Box
        sx={{
          mb: 1,
          border: `1px solid ${palette.primary[200]}`,
          borderRadius: 1,
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            mb: 2,
          }}
        >
          Aggregation Analytics
        </Typography>
        
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            mt: -1
          }}
        >
          <Button variant="contained" onClick={refreshData}>
            Refresh
          </Button>
        </Box>
      </Box>

      <Grid container sx={{
          mt: -2,
        }}>
          <Grid item sx={{width:"100%"}} xs={12}>
            <AggregationLinkingProgressPivotTable fillPage={false} ref={aggregationLinkingPivotRef}/>
          </Grid>
          <Grid item sx={{width:"100%"}} xs={12}>
            <AggregationLinkingUserProgressPivotTable fillPage={false} ref={aggregationLinkingUserProgressPivotRef}/>
          </Grid>
          <Grid item sx={{width:"100%"}} xs={12}>
            <AggregationGroupingProgressPivotTable fillPage={false} ref={aggregationGroupingPivotRef}/>
          </Grid>
          <Grid item sx={{width:"100%"}} xs={12}>
            <AggregationGroupingUserProgressPivotTable fillPage={false} ref={aggregationGroupingUserProgressPivotRef}/>
          </Grid>
      </Grid>
      

    </Box>
    </>
  );
}

export default AggregationAnalyticsDashboard;
