import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { getHeaders } from "../../../utils/apiUtils";
import debuggerInstance from "../../../components/common/userFeedback/DebugComponent/debugger";
import PineconeIndexDropdown from "../../../components/forms/Dropdowns/PineconeIndexDropdown";
import { useDebugger } from "../../../contexts/DebuggerContext";
import StyledCard from "../../../components/ui/cards/StyledCard";
import StyledImageCarousel from "../../../components/ui/StyledImageCarousel";
import SingleSelectGarmentWindow from "../../../components/layout/Interactive/SingleSelectGarmentWindow";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import CustomModal from "../../../components/common/userFeedback/CustomModal";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";

import DatasetDropdown from "../../../components/forms/Dropdowns/DatasetDropdown";
import TakeActionScreenCover from "../../../components/common/userFeedback/TakeActionScreenCover";
import ImageIndexButtons from "../../../components/ui/buttons/ImageIndexButtons";
import StyledButton from "../../../components/ui/buttons/StyledButton";
import PageChangeButton from "../../../components/ui/buttons/PageChangeButton";
import { logPerformancePoint } from "../../../utils/sharedFunctions";
const SKIP_ID = 4908182;
const screenConfig = {
  slipstream: {
    title: "Cataloguing Slipstream",
    routePrefix: "slipstream",
  },
  groups: {
    title: "Cataloguing Groups",
    routePrefix: "groups",
  },
};
function CataloguingTool({ config }) {
  const { palette, typography } = useTheme();
  const { title, routePrefix } = screenConfig[config];

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [imageIndex, setImageIndex] = useState(1);

  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const [catalogGarments, setCatalogGarments] = useState([]);
  const [seedGarment, setSeedGarment] = useState({});
  const [seedGarmentImages, setSeedGarmentImages] = useState([]);
  const [selectedCatalogueItem, setSelectedCatalogueGarment] = useState({});
  const [metaData, setMetaData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [catalogueExhausted, setCatalogueExhausted] = useState(false);
  const [associateGarmentModalOpen, setAssociatedGarmentModalOpen] =
    useState(false);

  const [
    updateGroupRepresentativeModalOpen,
    setUpdateGroupRepresentativeModalOpen,
  ] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [openSearchIndex, setOpenSearchIndex] = useState(null);
  const [numberOfColumns, setNumberOfColumns] = useState(4);

  const { addLog } = useDebugger();

  // Lifecycle Methods
  useEffect(() => {
    if (openSearchIndex) {
      fetchSeedGarment();
    }
  }, [selectedDataset, openSearchIndex]);

  useEffect(() => {
    setPageSize(numberOfColumns * 3);
    setCatalogGarments([]);
  }, [numberOfColumns]);

  useEffect(() => {
    if (openSearchIndex && seedGarment.vector && page && pageSize) {
      queryCatalog({
        vector: seedGarment.vector,
        offset: page,
        page_size: pageSize,
        index: openSearchIndex,
      });
    }
  }, [page, pageSize]);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    if (
      selectedCatalogueItem &&
      Object.keys(selectedCatalogueItem).length > 0
    ) {
      setAssociatedGarmentModalOpen(true);
    }
  }, [selectedCatalogueItem]);

  // Axios methods
  const fetchSeedGarment = async () => {
    const headers = await getHeaders();
    setShowLoader(true);
    setButtonDisabled(true);
    setPage(1);
    setImageIndex(1);
    let url = `${BASE_URL}tools/summon/next-item/${routePrefix}/cataloguing?`;
    if (selectedDataset) {
      url += `dataset=${selectedDataset.id}&`;
    }
    if (openSearchIndex) {
      url += `index=${openSearchIndex}&`;
    }

    axios
      .get(url, headers)
      .then((res) => {
        const t0 = logPerformancePoint(0);
        resetState();
        addLog(res.data.data);
        setSeedGarment(res.data.data);
        let { num_images, uuid_public_id } = res.data.data;
        let imageUrls = [];
        for (let i = 1; i <= num_images; i++) {
          let imageUrl = `${BASE_IMAGE_URL}${uuid_public_id}-${i}.jpg`;
          imageUrls.push(imageUrl);
        }
        const t1 = logPerformancePoint(1,t0);
        setSeedGarmentImages(imageUrls);
        queryCatalog({
          vector: res.data.data.vector,
          offset: 1,
          page_size: pageSize,
          index: openSearchIndex,
        });

        const t2 = logPerformancePoint(2,t1);
        setShowLoader(false);
        setButtonDisabled(false);
      })

      .catch((err) => {
        showAlert(err);
        setShowLoader(false);
        setButtonDisabled(false);
      });
  };

  const queryCatalog = async (params) => {
    const headers = await getHeaders();
    setButtonDisabled(true);
    setShowLoader(true);
    axios
      .post(`${BASE_URL}tools/cataloguing/querypinecone`, params, headers)
      .then((res) => {
        setCatalogGarments(res.data.data);
        setShowLoader(false);
        setButtonDisabled(false);

        if (res.data.data.length == pageSize) {
          setCatalogueExhausted(false);
        } else {
          setCatalogueExhausted(true);
        }
      })
      .catch((err) => {
        showAlert(err);
        setShowLoader(false);
        setButtonDisabled(false);
      });
  };

  const createNewCatalogItem = async () => {
    const headers = await getHeaders();
    const params = {
      index: openSearchIndex,
      vector: seedGarment.vector,
      garment_id: seedGarment.garment_id,
      uuid_public_id: seedGarment.uuid_public_id,
      action: "CATALOGUE",
    };
    setShowLoader(true);
    setButtonDisabled(true);

    axios
      .post(`${BASE_URL}tools/classify/catalogue/new-item`, params, headers)
      .then((res) => {
        trackEvent("CatalogueItem", {
          garmentId: seedGarment.garment_id,
          proposedBy: res.data.metadata.userId,
          jobName: "cataloguing",
          toolType: `CATALOGUING${config === "slipstream" ? "_SLIPSTREAM" : ""}`,
          toolName: "CataloguingTool",
          action: "CATALOGUE",
          confidence: 0.4,
          index: seedGarment.index,
          vector: seedGarment.vector,
          uuidPublicId: seedGarment.uuid_public_id,
          associatedProposalGroupUuid: res.data.metadata.new_group_uuid,
          skipped: false,
          qa: false,
          slipstream: config === "slipstream",
          updateGroupRepresentative: false
        });

        resetState();
        fetchSeedGarment();
        setButtonDisabled(false);
      })
      .catch((err) => {
        showAlert(err);
        setButtonDisabled(false);
      });
  };

  const handleUpdateGroupRepresentative = async () => {
    const headers = await getHeaders();
    const params = {
      index: openSearchIndex,
      vector: seedGarment.vector,
      previous_garment_id: selectedCatalogueItem.garment_id,
      new_garment_id: seedGarment.garment_id,
    };
    setShowLoader(true);
    setButtonDisabled(true);
    axios
      .post(
        `${BASE_URL}tools/cataloguing/replace-catalogue-representative`,
        params,
        headers
      )
      .then((res) => {
        trackEvent("CatalogueItemUpdateGroupRepresentative", {
          garmentId: seedGarment.garment_id,
          proposedBy: res.data.metadata.userId,
          jobName: "cataloguing",
          toolType: `CATALOGUING${config === "slipstream" ? "_SLIPSTREAM" : ""}`,
          toolName: "CataloguingTool",
          action: "UPDATE_GROUP_REPRESENTATIVE",
          confidence: 1.0,
          index: seedGarment.index,
          vector: seedGarment.vector,
          uuidPublicId: seedGarment.uuid_public_id,
          previousGarmentId: selectedCatalogueItem.garment_id,
          newGarmentId: seedGarment.garment_id,
          skipped: false,
          qa: false,
          slipstream: config === "slipstream",
        });

        resetState();
        fetchSeedGarment();
        setButtonDisabled(false);
      })
      .catch((err) => {
        showAlert(err);
        setButtonDisabled(false);
      });
  };

  const asssociateToCatalogItem = async (update_representative) => {
    const headers = await getHeaders();
    const params = {
      index: seedGarment.index,
      garment_id: seedGarment.garment_id,
      associated_garment: {
        ...selectedCatalogueItem,
        index: selectedCatalogueItem.index + pageSize * (page - 1),
      },
      uuid_public_id: seedGarment.uuid_public_id,
      associated_garment_id: selectedCatalogueItem.garment_id,
      action: "AGGREGATE",
    };
    setShowLoader(true);
    setButtonDisabled(true);
    axios
      .post(`${BASE_URL}tools/classify/catalogue/associate`, params, headers)

      .then((res) => {
        trackEvent("CatalogueItem", {
          garmentId: seedGarment.garment_id,
          proposedBy: res.data.metadata.userId,
          jobName: "cataloguing",
          toolType: `CATALOGUING${config === "slipstream" ? "_SLIPSTREAM" : ""}`,
          toolName: "CataloguingTool",
          action: "AGGREGATE",
          confidence: 0.4,
          score: res.data.metadata.garment_similarity_score,
          index: seedGarment.index,
          vector: seedGarment.vector,
          uuidPublicId: seedGarment.uuid_public_id,
          associatedGarmentId: selectedCatalogueItem.garment_id,
          associatedProposalGroupUuid: res.data.metadata.associated_proposal_group_uuid,
          originalProposalGroupUuid: res.data.metadata.original_proposal_group_uuid,
          skipped: false,
          qa: false,
          slipstream: config === "slipstream",
          updateGroupRepresentative: update_representative
        });

        if (update_representative) {
          handleUpdateGroupRepresentative();
        } else {
          resetState();
          fetchSeedGarment();
          setButtonDisabled(false);
        }
      })
      .catch((err) => {
        showAlert(err);
        setButtonDisabled(false);
      });
  };

  const handleCancel = () => {
    setSelectedCatalogueGarment({});
    setAssociatedGarmentModalOpen(false);
  };

  const resetState = () => {
    setSeedGarment({});
    setButtonDisabled(true);
    setSeedGarmentImages([]);
    setMetaData({});
    setCatalogGarments([]);
    setPage(1);
    setSelectedCatalogueGarment({});
    setCatalogueExhausted(false);
  };

  const handleSkip = async () => {
    const headers = await getHeaders();
    const params = {
      index: seedGarment.index,
      garment_id: seedGarment.garment_id,
      associated_garment: {
        score: 1,
        index: 1,
      },
      associated_garment_id: SKIP_ID,
      action: "AGGREGATE",
    };
    setShowLoader(true);
    setButtonDisabled(true);
    const t0 = logPerformancePoint(0);
    axios
      .post(`${BASE_URL}tools/classify/catalogue/associate`, params, headers)
      .then((res) => {
        const t1 = logPerformancePoint(1,t0);
        trackEvent("CatalogueItem", {
          garmentId: seedGarment.garment_id,
          proposedBy: res.data.metadata.userId,
          jobName: "cataloguing",
          toolType: "CATALOGUING",
          toolName: "CataloguingTool",
          action: "AGGREGATE",
          confidence: 0.4,
          score: res.data.metadata.garment_similarity_score,
          index: seedGarment.index,
          vector: seedGarment.vector,
          uuidPublicId: seedGarment.uuid_public_id,
          associatedGarmentId: selectedCatalogueItem.garment_id,
          associatedProposalGroupUuid: res.data.metadata.associated_proposal_group_uuid,
          originalProposalGroupUuid: res.data.metadata.original_proposal_group_uuid,
          skipped: true,
          qa: false,
          slipstream: false,
          updateGroupRepresentative: false
        });
        const t2 = logPerformancePoint(2,t1);
        
        resetState();
        fetchSeedGarment();
        setButtonDisabled(false);
      })
      .catch((err) => {
        showAlert(err);
        setButtonDisabled(false);
      });
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Top Box with Fixed Height */}
      <Box
        sx={{
          height: "120px",
          flexShrink: 0,
          p: 1,
        }}
      >
        <Box
          sx={{
            justifyContent: "flex-end",
            flexDirection: "row",
            display: "flex",
            flex: 1,
          }}
        >
          <Typography variant="h3" sx={{ flex: 1 }}>
            {title}
          </Typography>
          <StyledButton
            variant="outlined"
            sx={{ mr: 2 }}
            color="primary"
            onClick={createNewCatalogItem}
            disabled={buttonDisabled}
          >
            Create New
          </StyledButton>
          <StyledButton
            variant="outlined"
            color="secondary"
            sx={{ mr: 2 }}
            onClick={handleSkip}
            disabled={buttonDisabled}
          >
            Skip
          </StyledButton>
          <PageChangeButton
            setPage={setPage}
            page={page}
            maxPage={Math.round(250 / pageSize)}
            disabled={buttonDisabled}
            exhausted={catalogueExhausted}
          />
        </Box>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <DatasetDropdown
            dataset={selectedDataset}
            setDataset={setSelectedDataset}
            sx={{ mr: 2 }}
          />
          <PineconeIndexDropdown
            index={openSearchIndex}
            setIndex={setOpenSearchIndex}
          />
          <Select
            value={numberOfColumns}
            onChange={(e) => setNumberOfColumns(e.target.value)}
            displayEmpty
            sx={{ ml: 2 }}
          >
            <MenuItem value={4}>4 Column</MenuItem>
            <MenuItem value={5}>5 Columns</MenuItem>
            <MenuItem value={6}>6 Columns</MenuItem>
            <MenuItem value={7}>7 Columns</MenuItem>
            <MenuItem value={8}>8 Columns</MenuItem>
          </Select>
          <ImageIndexButtons index={imageIndex} setIndex={setImageIndex} />
        </Box>
      </Box>

      {/* Main Content Area */}
      {openSearchIndex ? (
        <Box
          sx={{
            flexGrow: 1, // Fills the remaining space
            display: "flex",
            overflow: "hidden", // Ensures no overflow outside this box
          }}
        >
          <Box
            sx={{
              width: "25%",
            }}
          >
            <Box sx={{ padding: 2 }}>
              <Box sx={{ height: "60vh" }}>
                <StyledImageCarousel srcs={seedGarmentImages} />
              </Box>
              <Typography variant="h4">{seedGarment.title}</Typography>
              <Typography variant="body1">
                UUID: {seedGarment.uuid_public_id}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "75%",
              overflowY: "auto", // Enables scrolling
            }}
          >
            <SingleSelectGarmentWindow
              selectedGarment={selectedCatalogueItem}
              setSelectedGarment={setSelectedCatalogueGarment}
              garments={catalogGarments}
              imageIndex={imageIndex}
              numberOfColumns={numberOfColumns}
              showNoDataScreen={!showLoader}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{mt: 2}}>
          <TakeActionScreenCover/>
        </Box>
      )}
      <CustomModal
        showModal={associateGarmentModalOpen}
        title="Confirm Association"
        body="Are you sure you want to associate the selected garment?"
        onDismiss={handleCancel}
        onConfirm={() => {
          setAssociatedGarmentModalOpen(false);
          setUpdateGroupRepresentativeModalOpen(true);
        }}
        onDismissTitle="Cancel"
        onConfirmTitle="Confirm"
      />
      <CustomModal
        showModal={updateGroupRepresentativeModalOpen}
        title="Update Catalogue Representative?"
        body="Would you like to update the group representative to the selected garment?"
        onDismiss={() => {
          asssociateToCatalogItem(false);
          setUpdateGroupRepresentativeModalOpen(false);
        }}
        onConfirm={() => {
          asssociateToCatalogItem(true);
          setUpdateGroupRepresentativeModalOpen(false);
        }}
        onDismissTitle="No"
        onConfirmTitle="Yes"
      />
      <LoadingOverlay open={showLoader} />
    </Box>
  );
}

export default CataloguingTool;
/*
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Box
          sx={{
            justifyContent: "flex-end",
            flexDirection: "row",
            display: "flex",
            flex: 1,
          }}
        >
          <Typography variant="h3" sx={{ flex: 1 }}>
            Cataloguing Slipstream
          </Typography>
          <StyledButton
            variant="outlined"
            sx={{ mr: 2 }}
            color="primary"
            onClick={createNewCatalogItem}
            disabled={buttonDisabled}
          >
            Create New
          </StyledButton>
          <StyledButton
            variant="outlined"
            color="primary"
            sx={{ mr: 2 }}
            onClick={handleSkip}
            disabled={buttonDisabled}
          >
            Skip
          </StyledButton>
          <StyledButton
            variant="contained"
            color="success"
            onClick={handleNext}
            disabled={buttonDisabled || catalogueExhausted}
          >
            Next Page
          </StyledButton>
        </Box>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <DatasetDropdown
            dataset={selectedDataset}
            setDataset={setSelectedDataset}
            sx={{ mr: 2 }}
          />
          <PineconeIndexDropdown
            index={openSearchIndex}
            setIndex={setOpenSearchIndex}
          />
          <Select
            value={numberOfColumns}
            onChange={(e) => setNumberOfColumns(e.target.value)}
            displayEmpty
            sx={{ ml: 2 }}
          >
            <MenuItem value={4}>4 Column</MenuItem>
            <MenuItem value={5}>5 Columns</MenuItem>
            <MenuItem value={6}>6 Columns</MenuItem>
            <MenuItem value={7}>7 Columns</MenuItem>
            <MenuItem value={8}>8 Columns</MenuItem>
          </Select>
          <ImageIndexButtons index={imageIndex} setIndex={setImageIndex} />
        </Box>
      </Box>
      */
