import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import GroupConfigurationNavigationWidget from "../../../components/navigation/widgets/GroupConfigurationNavigationWidget";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import {
  DeleteButton,
  DisableButton,
  EditButton,
  EnableButton,
} from "../../../components/ui/buttons/TableButtons";
import { CheckCircleOutline, PriorityHigh } from "@mui/icons-material"; // Import icons from Material-UI
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import StyledDataGrid from "../../../components/ui/dataViews/StyledDataGrid";
const getColorForPriority = (priority) => {
  // Define color stops
  const colors = [
    "#FF5733", // Red
    "#FFA933", // Orange
    "#33FF57", // Green
  ];

  // Calculate index based on priority value
  const index = Math.min(Math.floor((priority - 1) / 3), 2);

  return colors[index];
};

function PipelineDashboard() {
  const navigate = useNavigate();

  const [pipelines, setPipelines] = useState([]);
  // State for handling dialog open/close

  useEffect(() => {
    fetchPipelines();
  }, []);
  const fetchPipelines = async () => {
    let headers = await getHeaders();
    axios
      .get(`${BASE_URL}tools/pipelines/listall`, headers)
      .then((res) => {
        // Reset form fields (optional)
        console.log(res.data.data);
        setPipelines(res.data.data);
      })
      .catch((err) => {
        showAlert(err);
      });
  };

  const deletePipeline = async (id) => {
    let headers = await getHeaders();
    axios
      .delete(`${BASE_URL}tools/pipelines/${id}`, headers)
      .then((res) => {
        // Reset form fields (optional)
        fetchPipelines();
      })
      .catch((err) => {
        showAlert(err);
      });
  };
  const enablePipeline = async (id) => {
    let headers = await getHeaders();
    axios
      .put(`${BASE_URL}tools/pipelines/enable/${id}`, {}, headers)
      .then((res) => {
        // Reset form fields (optional)
        fetchPipelines();
      })
      .catch((err) => {
        showAlert(err);
      });
  };

  const columns = [
    { field: "id", headerName: "id", flex: 1 },
    { field: "name", headerName: "Name", flex: 2, editable: false },
    {
      field: "target_quantity",
      headerName: "Target Quantity",
      flex: 1,
      editable: false,
    },
    {
      field: "is_archived",
      headerName: "Archived",
      flex: 1,
      editable: false,
      valueGetter: (params) => (params.value === 1 ? "Yes" : "No"), // Convert boolean to "Yes" or "No"
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: params.value === "Yes" ? "red" : "green",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          ></div>
          <span>{params.value}</span>
        </div>
      ),
    },
    /*
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: getColorForPriority(params.value),
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          ></div>
          <span>{params.value}</span>
        </div>
      ),
    },
    */
    {
      field: "edit",
      headerName: "",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <EditButton onEdit={() => navigate(`create/${params.row.id}`)} />
      ),
    },
    {
      field: "delete",
      headerName: "",
      width: 150,
      editable: false,
      renderCell: (params) => {
        if (params.row.is_archived === 1) {
          return <EnableButton action={() => enablePipeline(params.row.id)} />;
        } else {
          return <DisableButton action={() => deletePipeline(params.row.id)} />;
        }
      },
    },
    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
      sx={{
        padding: 2,
        paddingTop: 2,
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h2">Pipeline Dashboard</Typography>
        <Button
          variant="angular"
          color="primary"
          onClick={() => {
            navigate("create");
          }}
        >
          Create New
        </Button>
      </Box>

      <StyledDataGrid
        rows={pipelines}
        columns={columns}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
        initialState={{
          filter: {
            filterModel: {
              items: [
                {
                  field: "is_archived",
                  operator: "equals",
                  value: "No",
                },
              ],
            },
          },
        }}
      />
    </Box>
  );
}

export default PipelineDashboard;
