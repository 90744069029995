import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  Modal,
  Button,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { Dialog, CircularProgress } from "@mui/material";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import { ViewButton } from "../../../components/ui/buttons/TableButtons";
import HideableTable from "../../../components/ui/HideableTable";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";

const PlotlyRenderers = createPlotlyRenderers(Plot);
function DatasetPivotTable() {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [datasetDetails, setDatasetDetails] = useState({});
  const itemsPerPage = 2000;
  const { id: dataset_id } = useParams();

  const { palette, typography } = useTheme();
  const resetData = () => {
    setData([]);
    setDataLoading(true);
  };

  useEffect(() => {
    resetData();
    const fetchData = async (page = 1) => {
      const params = {
        page: page,
        limit: itemsPerPage,
        dataset_id: dataset_id,
      };
      try {
        const headers = await getHeaders();
        const response = await axios.post(
          `${BASE_URL}tools/admin/datasets/dataset-analytics`,
          params,
          headers
        );

        setData((prev) => [...prev, ...response.data.data]);
        console.log(response.data.data);

        // Check if there are more items to fetch
        if (response.data.data.length === itemsPerPage) {
          await fetchData(page + 1); // Fetch next page
        } else {
          setDataLoading(false);
        }
      } catch (error) {
        showAlert(error); // Automatically extracts the message and shows the alert
      }
    };
    fetchData();
  }, [dataset_id]); // Empty dependency array to only run once

  useEffect(() => {
    if (dataset_id) {
      fetchDatasetDetails();
    }
  }, [dataset_id]);

  const fetchDatasetDetails = async () => {
    const headers = await getHeaders();
    const response = await axios
      .get(`${BASE_URL}tools/admin/datasets/dataset/${dataset_id}`, headers)
      .then((res) => {
        setDatasetDetails(res.data.data);
      })
      .catch((err) => {
        showAlert(err);
      });
  };
  return (
    <>
      <LoadingOverlay open={dataLoading} />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant="h3" color="primary.800">
          {datasetDetails?.name} Analytics
        </Typography>

        <Box
          sx={{
            mt: 1,
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            minHeight: "50vh",
            overflowX: "auto",
            display: "flex",
          }}
        >
          <PivotTableUI
            data={data}
            onChange={setData}
            {...data}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
      </Box>
    </>
  );
}

export default DatasetPivotTable;
