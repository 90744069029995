import {
    Autocomplete,
    Avatar,
    Box,
    TextField,
    Divider,
    Typography,
    useTheme
  } from "@mui/material";
import {formatCurrency_GBP, range} from "../../../utils/sharedFunctions";

const FormattedCurrencyTypography = ({value, nullIfZero=false, ...props}) => {
    const theme = useTheme();
    return (
        <Typography style={{ fontWeight: "bold", color: nullIfZero && value == 0 || value == null ? theme.palette.primary[400] : "inherit"}}>
            {nullIfZero && value == 0 || value == null
                ? "-"
                : `${formatCurrency_GBP.format(value).substring(0,formatCurrency_GBP.format(value).length-2)}`}
            {nullIfZero && value == 0 || value == null 
                ? ""
                : <Typography style={{display: "inline", color: theme.palette.primary[400]}}>
                    {formatCurrency_GBP.format(value).substring(formatCurrency_GBP.format(value).length-2, formatCurrency_GBP.format(value).length)}
                </Typography>}
        </Typography>
    )
}

export default FormattedCurrencyTypography;