
import colorName from 'color-name';

// Utility function to convert RGB to Hex
export const rgbToHex = (r, g, b) => {
    return (
      '#' +
      [r, g, b]
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  };
  // Function to get Hex from color name using color-name
  export const colorNameToHex = (name) => {
    const rgb = colorName[name.toLowerCase()];
    if (!rgb) return null;
    return rgbToHex(...rgb);
};

// threshold scales towards white - 0.75 is ~75% white
export const colorIsCloserToWhiteThanBlack = (colorRGB, threshold=0.75) => {
  const luminescence = 0.2126*colorRGB.red + 0.0722*colorRGB.blue + 0.7152*colorRGB.green
  return luminescence < 128*threshold ? false/*black*/ : true/*white*/
}
  
export const hexToRgb = (hex) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
  
    // Parse the hexadecimal string into integer RGB values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
  
    return [r, g, b];
};

export const mixHexColors = (hexColors) => {
    // Initialize sums for each color component
    let totalR = 0;
    let totalG = 0;
    let totalB = 0;

    // Convert each hex color to RGB and sum up the components
    hexColors.forEach((hex) => {
        const [r, g, b] = hexToRgb(hex);
        totalR += r;
        totalG += g;
        totalB += b;
    });

    // Calculate the average for each color component
    const count = hexColors.length;
    const avgR = Math.round(totalR / count);
    const avgG = Math.round(totalG / count);
    const avgB = Math.round(totalB / count);

    // Convert the average RGB values back to a hex color
    return rgbToHex(avgR, avgG, avgB);
};

export const getHexColorFromWords = (sentence) => {
  let hexColor = colorNameToHex(sentence);
  if (!hexColor)
  {
    const words = sentence.split(" ");
    const hexColours = words.map(x => colorNameToHex(x)).filter(x => x !== null && x !== undefined);
    hexColor = mixHexColors(hexColours);
  }
  return hexColor;
}

export const getHexColorsFromWords = (wordArray) => {
  let hexColorArray;
  if (wordArray)
  {
    hexColorArray = [];
    wordArray.forEach((sentence) => {
      let hexColor = colorNameToHex(sentence);
      if (!hexColor)
      {
        const words = sentence.split(" ");
        const hexColours = words.map(x => colorNameToHex(x)).filter(x => x !== null && x !== undefined);
        hexColor = mixHexColors(hexColours);
      }
      hexColorArray.push(hexColor)
    })
  }
  
  return hexColorArray;
}