import { Box, Typography, Button, Grid, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  Legend
} from "recharts";
import {
  parseISO,
  format,
  startOfQuarter,
  addQuarters,
  isBefore,
  isAfter
} from "date-fns";
import {generateQuarters, formatQuarter} from "../../utils/dateUtils"
import {formatCurrency_GBP} from "../../utils/sharedFunctions"
import { APP_BAR_HEIGHT } from "../layout/AppBar/AppBarComponent";
import useMediaQuery from '@mui/material/useMediaQuery';

const PriceTrendsLineChart = ({ data, splitByCondition, splitByVendor, showLegend=true }) => {
// Extract unique conditions and vendors
const [chartData, setChartData] = useState([]);
const [lines, setLines] = useState([]);
const [maxPrice, setMaxPrice] = useState(0);
const [uniqueConditions, setUniqueConditions] = useState([]);
const [uniqueVendors, setUniqueVendors] = useState([]);

const theme = useTheme();
const largerThan2000 = useMediaQuery('(min-width:2000px)');

useEffect(() => {
  setUniqueConditions(data ? [...new Set(data.map((item) => item._condition))] : []);
  setUniqueVendors(data ? [...new Set(data.map((item) => item.seller))] : []);
}, [data])

// Generate unique colors for lines
const generateSoldColor = (index) => {
  const colors = [theme.palette.soldPriceLine[100],theme.palette.soldPriceLine[200],theme.palette.soldPriceLine[300],theme.palette.soldPriceLine[400],theme.palette.soldPriceLine[500]];
  return colors[index % colors.length];
};
const generateListedColor = (index) => {
  const colors = [theme.palette.listedPriceLine[100],theme.palette.listedPriceLine[200],theme.palette.listedPriceLine[300],theme.palette.listedPriceLine[400],theme.palette.listedPriceLine[500]];
  return colors[index % colors.length];
};

// Function to process data
const processData = () => {
  // Filter for only timestamp-soldprice pairs
  //const filteredData = data.filter(
  //  (item) => item.sold_timestamp && item.sold_price_in_gbp
  //);
  const filteredSoldData = data.filter(
    (item) => item.sold_timestamp && item.sold_price_in_gbp && item.sold_price_in_gbp !== 0
  );
  const filteredListedData = data.filter(
    (item) => item.listed_timestamp && item.listed_price_in_gbp && item.listed_price_in_gbp !== 0
  );
  const hasSoldData = filteredSoldData.length > 0
  const hasListedData = filteredListedData.length > 0
  // only check for any listed data, if none do not show graph
  if (hasSoldData || hasListedData)
  {
    if (hasSoldData)
    {
      // Sort data by date
      filteredSoldData.sort((a, b) =>
        isBefore(parseISO(a.sold_timestamp), parseISO(b.sold_timestamp)) ? 1 : -1
      );
    }
    if (hasListedData)
    {
      // Sort data by date
      filteredListedData.sort((a, b) =>
        isBefore(parseISO(a.listed_timestamp), parseISO(b.listed_timestamp)) ? 1 : -1
      );
    }

    const startSoldDate = hasSoldData ? parseISO(
      filteredSoldData[filteredSoldData.length - 1].sold_timestamp
    ) : null
    const endSoldDate = hasSoldData ? parseISO(filteredSoldData[0].sold_timestamp) : null

    const startListedDate = hasListedData ? parseISO(
      filteredListedData[filteredListedData.length - 1].listed_timestamp
    ) : null;
    const endListedDate = hasListedData ? parseISO(filteredListedData[0].listed_timestamp) : null;

    const startDate = hasSoldData 
      ? hasListedData 
        ? (isBefore(startSoldDate, startListedDate) 
          ? startSoldDate 
          : startListedDate) 
        : startSoldDate 
      : startListedDate
    const endDate = hasSoldData 
      ? hasListedData 
        ? (isAfter(endSoldDate, endListedDate) 
          ? endSoldDate 
          : endListedDate) 
        : endSoldDate 
      : endListedDate
    
    /* Max Price of listings NOT SPLIT BY QUARTER */
    //const listedMaxPrice = Math.max(...filteredListedData.map(x => x.listed_price_in_gbp))
    //let soldMaxPrice = 0
    //if (hasSoldData)
    //{
    //  soldMaxPrice = Math.max(...filteredSoldData.map(x => x.sold_price_in_gbp))
    //}
    //setMaxPrice(Math.max(soldMaxPrice, listedMaxPrice))

    const quarters = generateQuarters(startDate, endDate);

    //group data by date, condition, and vendor
    const groupedSoldData = hasSoldData
      ? filteredSoldData.reduce((acc, item) => {
          const date = format(
            startOfQuarter(parseISO(item.sold_timestamp)),
            "yyyy-QQ"
          ); // Extract quarter part
          let key;
          if (splitByCondition && splitByVendor) {
            key = `${date}-${item._condition}-${item.seller}`;
          } else if (splitByCondition) {
            key = `${date}-${item._condition}`;
          } else if (splitByVendor) {
            key = `${date}-${item.seller}`;
          } else {
            key = date;
          }
          acc[`sold-${key}`] = acc[`sold-${key}`] || [];
          acc[`sold-${key}`].push(item);
          return acc;
        }, {})
      : {}
    
    //group data by date, condition, and vendor
    const groupedListedData = hasListedData
    ? filteredListedData.reduce((acc, item) => {
        const date = format(
          startOfQuarter(parseISO(item.listed_timestamp)),
          "yyyy-QQ"
        ); // Extract quarter part
        let key;
        if (splitByCondition && splitByVendor) {
          key = `${date}-${item._condition}-${item.seller}`;
        } else if (splitByCondition) {
          key = `${date}-${item._condition}`;
        } else if (splitByVendor) {
          key = `${date}-${item.seller}`;
        } else {
          key = date;
        }
        acc[`listed-${key}`] = acc[`listed-${key}`] || [];
        acc[`listed-${key}`].push(item);
        return acc;
      }, {})
    : {};

    const averageData = quarters.map((quarter) => {
      const result = { date: quarter, formattedDate: formatQuarter(quarter) };
      if (splitByCondition || splitByVendor) {
        if (hasSoldData)
        {
          Object.keys(groupedSoldData).forEach((key) => {
            if (key.startsWith(`sold-${quarter}`)) {
              const [, , , condition, vendor] = key.split("-");
              const soldPrices = groupedSoldData[key].map((item) => item.sold_price_in_gbp);
              let averageSoldPrice = soldPrices.reduce((sum, price) => sum + price, 0) / soldPrices.length;
              averageSoldPrice = averageSoldPrice.toFixed(2);

              const dataKey =
                splitByCondition && splitByVendor
                  ? `${condition}-${vendor}`
                  : splitByCondition
                    ? condition
                    : vendor;
              result[`sold-${dataKey}`] = averageSoldPrice;
              result['type'] = 'sold'
            }
          });
        }

        if (hasListedData)
        {
          Object.keys(groupedListedData).forEach((key) => {
            if (key.startsWith(`listed-${quarter}`)) {
              const [, , , condition, vendor] = key.split("-");
              const listedPrices = groupedListedData[key].map((item) => item.listed_price_in_gbp);
              let averageListedPrice = listedPrices.reduce((sum, price) => sum + price, 0) / listedPrices.length;
              averageListedPrice = averageListedPrice.toFixed(2);

              const dataKey =
                splitByCondition && splitByVendor
                  ? `${condition}-${vendor}`
                  : splitByCondition
                    ? condition
                    : vendor;
              result[`listed-${dataKey}`] = averageListedPrice;
              result['type'] = 'listed'
            }
          });
        }
      } else {
        if (hasSoldData)
        {
          if (groupedSoldData[`sold-${quarter}`]) {
            const soldPrices = groupedSoldData[`sold-${quarter}`].map((item) => item.sold_price_in_gbp);
            result.averageSoldPrice = (soldPrices.reduce((sum, price) => sum + price, 0) / soldPrices.length).toFixed(2);
          } 
        }
        if (hasListedData)
        {
          if (groupedListedData[`listed-${quarter}`]) {
            const listedPrices = groupedListedData[`listed-${quarter}`].map((item) => item.listed_price_in_gbp);
            result.averageListedPrice = (listedPrices.reduce((sum, price) => sum + price, 0) / listedPrices.length).toFixed(2);
          }
        }
        if (!groupedSoldData[`sold-${quarter}`] && !groupedListedData[`listed-${quarter}`]) {
          result.averageSoldPrice = null;
          result.averageListedPrice = null;
        }
      }
      //const listedDataMaxPrice = Math.max(...groupedListedData.map(x => x.listed_price_in_gbp))
     //let soldMaxPrice = 0
     //if (hasSoldData)
     //{
     //  soldMaxPrice = Math.max(...filteredSoldData.map(x => x.sold_price_in_gbp))
     //}
     //setMaxPrice(Math.max(soldMaxPrice, listedMaxPrice))

      return result;
    });

    // Generate lines configuration
    const linesConfig = [];
    if (splitByCondition || splitByVendor) {
      let lineKeysSold = new Set(
        Object.keys(groupedSoldData).map((key) =>
          splitByCondition && splitByVendor
            ? `sold-${key.split("-")[3]}-${key.split("-")[4]}`
            : splitByCondition
              ? `sold-${key.split("-")[3]}`
              : `sold-${key.split("-")[4]}`
      ));
      let indexSold = 0;
      lineKeysSold.forEach((key) => {
        linesConfig.push({
          key,
          color: generateSoldColor(indexSold),
          name: key,
        });
        indexSold++;
      });

      
      let lineKeysListed = new Set(
        Object.keys(groupedListedData).filter(x => x.startsWith('listed')).map((key) =>
          splitByCondition && splitByVendor
            ? `listed-${key.split("-")[3]}-${key.split("-")[4]}`
            : splitByCondition
            ? `listed-${key.split("-")[3]}`
            : `listed-${key.split("-")[4]}`
        )
      );
      let indexListed = 0;
      lineKeysListed.forEach((key) => {
        linesConfig.push({
          key,
          color: generateListedColor(indexListed),
          name: key,
        });
        indexListed++;
      });
    } else {
      linesConfig.push({
        key: "averageSoldPrice",
        color: theme.palette.soldPriceLine.main,
        name: "Average Sold Price",
      });
      linesConfig.push({
        key: "averageListedPrice",
        color: theme.palette.listedPriceLine.main,
        name: "Average Listed Price",
      });
    }
    //const averageListedPrices = averageData.map(x => {
    //  const groupListedPriceKeys = Object.keys(x).filter(k => k.startsWith('listed-'))
    //  const groupListedPrice = groupListedPriceKeys.reduce((acc, k) => acc + parseFloat(x[k], 10), 0);
    //  return groupListedPrice;
    //})

    /* Max Price of listings SPLIT BY QUARTER */
    let maxPriceFinal = 0;
    if (splitByCondition)
    {
      let maxAverageSoldPrice = 0
      let maxAverageListedPrice = 0
      if (hasListedData)
      {
        const averageListedPrices = averageData.map(x => {
          const groupListedPriceKeys = Object.keys(x).filter(k => k.startsWith('listed-'))
          const maxGroupListedPrice = groupListedPriceKeys.reduce((acc, k) => Math.max(acc, parseFloat(x[k], 10)), 0);
          return maxGroupListedPrice;
        })
        maxAverageListedPrice = Math.max(...averageListedPrices)
      }
      if (hasSoldData)
      {
        const averageSoldPrices = averageData.map(x => {
          const groupSoldPriceKeys = Object.keys(x).filter(k => k.startsWith('sold-'))
          const maxGroupSoldPrice = groupSoldPriceKeys.reduce((acc, k) => Math.max(acc, parseFloat(x[k], 10)), 0);
          return maxGroupSoldPrice;
        })
        maxAverageSoldPrice = Math.max(...averageSoldPrices)
      }
      maxPriceFinal = hasSoldData ? Math.max(maxAverageListedPrice, maxAverageSoldPrice) : maxAverageListedPrice
    } else {
      let averageListedPrices = averageData.map(x => parseFloat(x.averageListedPrice, 10)).filter(y => y && y!==0)
      let averageSoldPrices = averageData.map(x => parseFloat(x.averageSoldPrice, 10)).filter(y => y && y!==0)
      let maxAverageListedPrice = Math.max(...averageListedPrices)
      let maxAverageSoldPrice = Math.max(...averageSoldPrices)
      maxPriceFinal = Math.max(maxAverageListedPrice, maxAverageSoldPrice)
    }
    setMaxPrice(Math.ceil(maxPriceFinal/100)*100 + 100)

    //const listedPriceMax = splitByCondition ? Math.max(...averageListedPrices) : Math.max(...averageListedPrices)
    //const soldPriceMax = splitByCondition ? Math.max(...averageSoldPrices) : Math.max(...averageData.map(x => x.averageSoldPrice))
    //const maxPriceFinal = Math.max(listedPriceMax, soldPriceMax)
    //console.log(`absolute max: ${maxPriceFinal}`)
    setChartData(averageData);
    setLines(linesConfig);
  }
};
// Effect to process data when split options change
useEffect(() => {
  if (data && data.length > 0)
  {
    processData();
  }
}, [splitByCondition, splitByVendor, data]);

  const chartLabelStyles = {
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    textTransform: "uppercase",
    userSelect: "none",
    textAnchor: "middle"
  }

  const chartAxisStyles = {
    fontFamily: "Rigid Square",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    userSelect: "none",
    textAnchor: "middle"
  }

  const graphHeight = 500

  return (
    <Box sx={{}}>
      {chartData && chartData.length > 0 
        ? <ResponsiveContainer width="100%" height={graphHeight}>
            <LineChart
              data={chartData}
              margin={{ top: 40, right: 40, left: 100, bottom: showLegend ? (splitByCondition ? 10 : 10) : 60 }}
            >
              {showLegend && <Legend wrapperStyle={{ paddingTop: 64, paddingBotom: 20 }} />}
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                style={{...chartAxisStyles, fontSize: "12px"}}
                dataKey="formattedDate"
                interval={0}
                angle={30}
                tick={{ fontSize: 12 }}
                height={20} // Adjust the height to give more space for angled labels
                padding={{ left: 20, right: 20, top: 0 }}
                dy={10}
              >
                <Label value="Date" offset={showLegend ? (lines.length > 6 ? -50 : -50) : -50} position="insideBottom" style={chartLabelStyles}/>
              </XAxis>
              <YAxis tick={{ fontSize: 12 }} dx={-40} style={chartAxisStyles} tickFormatter={(moneyValue) => formatCurrency_GBP.format(moneyValue)} domain={[0, Math.ceil(maxPrice/100)*100]}>
                <Label value="Average Price" angle={-90} offset={-60} position="insideLeft" style={chartLabelStyles}/>
              </YAxis>
              <Tooltip labelFormatter={(label) => <Typography variant="subheading">{`${label}`}</Typography>}/>
              {lines.map((line) => (
                <Line
                  strokeOpacity={splitByCondition ? 0.75 : 1}
                  strokeWidth={4}
                  key={line.key}
                  type="monotone"
                  dataKey={line.key}
                  name={line.name}
                  stroke={line.color}
                  connectNulls
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        : <Box sx={{alignItems: "center", justifyContent: "center", height: graphHeight, verticalAlign: "middle"}}>
            <Typography variant="h3" sx={{color: theme.palette.primary[400], p: 2, fontWeight: "normal", textAlign: "center", marginTop: "auto", marginBottom: "auto"}}>no pricing data found for this garment</Typography>
          </Box>
      }
    </Box>
  );
};

export default PriceTrendsLineChart;
