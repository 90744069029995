/**
 * @fileoverview AmplitudeTrackingContext is a context that provides event tracking across Bridge using Amplitude.
 * It is used to provide Amplitude to the App.
 * It is used to autotrack all events into Ampltiude.
 * It is used to send user information along with events to Amplitude.
  @function useAmpltiudeEventTrackingContext - A custom hook to enable Event Tracking using Amplitude
 */

import React, { createContext, useState, useMemo, useContext, useEffect, useRef } from "react";
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin, getSessionReplayProperties } from '@amplitude/plugin-session-replay-browser';
import { useAuth } from "./AuthContext";
import axios from "axios";
import { getTrackedEvent, minInactivityDurationMinutes, getUserActiveHeartbeatProperties } from '../config/eventTracking/trackedEvents.ts';
import { useIdleTimer } from "react-idle-timer"
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';
import { showAlert } from "../components/common/userFeedback/CustomAlert.js";

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
export const useIdleTimeout = ({ onIdle, onActive = null, stopOnIdle = false, idleTime = 1 }) => {
    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false)

    const handleIdle = () => {
        setIdle(true);
        onIdle();
    }

    let useIdleTimerOptions = {
        timeout: idleTimeout,
        onPrompt: onIdle, //called when timeout reached
        onIdle: handleIdle, //called when user becomes idle from active
        stopOnIdle: stopOnIdle,
        debounce: 500
    }
    if (onActive)
    {
        useIdleTimerOptions = {
            ...useIdleTimerOptions,
            onActive: onActive
        }
    }
    const idleTimer = useIdleTimer(useIdleTimerOptions)

    return {
        isIdle,
        setIdle,
        idleTimer
    }
}

const AmplitudeTrackingContext = createContext();

export const AmplitudeTrackingProvider = ({ children, apiKey, secretApiKey, options }) => {
    const apiOptions = options["apiOptions"];
    const sessionReplayOptions = options["sessionReplayOptions"];
    const defaultEnableAutoTracking = options["defaultOptions"]["enableAutoTracking"];
    const defaultEnableTracking = options["defaultOptions"]["enableTracking"];
    const defaultEnableSessionTracking = options["defaultOptions"]["enableSessionTracking"];

    const [sessionReplayTrackingInstance, setSessionReplayTrackingInstance] = useState(null);
    const [amplitudeTrackingInstance, setAmplitudeTrackingInstance] = useState(null);
    const [amplitudeSessionReplayTrackingInstance, setAmplitudeSessionReplayTrackingInstance] = useState(null);
    const [trackedEventCategories, setTrackedEventCategories] = useState(null);
    
    const minInactivityDurationSeconds = minInactivityDurationMinutes * 60; //Max Inactivity before a UserInactive event is 10 minutes

    const [globalInactivityDurationSeconds, setGlobalInactivityDurationSeconds] = useState(0);
    const [inactivityTimestamp, setInactivityTimestamp] = useState(null);

    const [activePipelineSessionEventProperties, setActivePipelineSessionEventProperties] = useState(null);

    const [hasEndedPipeline, setHasEndedPipeline] = useState(true)

    const [heartbeatCount, setHeartbeatCount] = useState(0)
    const [heartbeatTimerIsTicking, setHeartbeatTimerIsTicking] = useState(true)
    const blankHeartbeatEvents = {
        heartbeatDurationSeconds: minInactivityDurationSeconds,
        proposalCount: 0,
        proposalQACount: 0,
        aggregationCount: 0,
        aggregationNextPageCount: 0,
        aggregationQACount: 0,
        aggregationQASetGroupRepresentativeCount: 0,
        hasHadUsefulActivity: false
    }
    const userActiveHeartbeatEventsRef = useRef(blankHeartbeatEvents);
    const userActiveHeartbeatTimerRef = useRef(undefined);
    const [userActiveHeartbeatEvents, setUserActiveHeartbeatEvents] = useState(blankHeartbeatEvents);
    const [channel, setChannel] = useState(new BroadcastChannel('amplitudeUserActiveEventChannel'))

    useEffect(() => {
        const newBrowserId = Math.random()
        let masterBrowserId = JSON.parse(localStorage.getItem("masterBrowserId"));
        let localBrowserId = JSON.parse(sessionStorage.getItem("browserId"));
        console.log(`masterBrowserId get: ${masterBrowserId}`)

        console.log(`masterBrowserId: ${masterBrowserId}, masterBrowserId === null: ${masterBrowserId === null}`)
        if (masterBrowserId === null || masterBrowserId === undefined)
        {
            localStorage.setItem("masterBrowserId", newBrowserId);
        }
        
        sessionStorage.setItem("browserId", newBrowserId)

        masterBrowserId = JSON.parse(localStorage.getItem("masterBrowserId"));
        localBrowserId = JSON.parse(sessionStorage.getItem("browserId"));
        console.log(`masterBrowserId: ${masterBrowserId}, localBrowserId: ${localBrowserId}, masterBrowserId === localBrowserId: ${(masterBrowserId === localBrowserId)}`)
        if ((masterBrowserId === localBrowserId) !== heartbeatTimerIsTicking)
        {
            console.log(`UserActiveHeartbeat timer ${masterBrowserId === localBrowserId ? "ENABLED" : "DISABLED"}.`)
            setHeartbeatTimerIsTicking(masterBrowserId === localBrowserId)
        }

        console.log(`masterBrowserId set: ${masterBrowserId}`)
        console.log(`browserId set: ${localBrowserId}`)
    }, [])

    useEffect(() => {
        const handleUnload = () => {
            let masterBrowserId = JSON.parse(localStorage.getItem("masterBrowserId"));
            let browserId = JSON.parse(sessionStorage.getItem("browserId"));
            if (masterBrowserId === browserId)
            {
                localStorage.setItem("masterBrowserId", null);
            }
        };
        window.addEventListener('unload', handleUnload);
        return () => {
          window.removeEventListener('unload', handleUnload);
        };
      }, []);

      useEffect(() => {
          const handleUnload = () => {
              let masterBrowserId = JSON.parse(localStorage.getItem("masterBrowserId"));
              let browserId = JSON.parse(sessionStorage.getItem("browserId"));
              if (masterBrowserId === browserId)
              {
                  localStorage.setItem("masterBrowserId", null);
              }
          };
          window.addEventListener('beforeunload', handleUnload);
          return () => {
            window.removeEventListener('beforeunload', handleUnload);
          };
        }, []);
  

    window.addEventListener("storage", () => {
        let masterBrowserId = JSON.parse(localStorage.getItem("masterBrowserId"));
        let localBrowserId = JSON.parse(sessionStorage.getItem("browserId"))
        if (masterBrowserId === null || masterBrowserId === undefined)
        {
            localStorage.setItem("masterBrowserId", localBrowserId);
            masterBrowserId = JSON.parse(localStorage.getItem("masterBrowserId"));
            console.log(`UserActiveHeartbeat timer SET NEW masterBrowserId: ${masterBrowserId}`)
        }

        masterBrowserId = JSON.parse(localStorage.getItem("masterBrowserId"));
        localBrowserId = JSON.parse(sessionStorage.getItem("browserId"));
        setHeartbeatTimerIsTicking(masterBrowserId === localBrowserId)
        if ((masterBrowserId === localBrowserId) === false)
        {
            console.log(`UserActiveHeartbeat timer DISABLED.`)
        }
    });

    const useTrackInactiveEvent = (durationSeconds) => {
        const [hasSentEvent, setHasSentEvent] = useState(false);
        const {isIdle, isIdleTimer} = useIdleTimeout({ onIdle: () => {
            if (!hasSentEvent)
            {
                trackEvent("UserInactive", {
                    minInactivityDurationSeconds: durationSeconds
                });
                setInactivityTimestamp(Date.now());
                setHasSentEvent(true);
                setGlobalInactivityDurationSeconds(durationSeconds);
            }
        },
        onActive: () => {
            let inactivityDurationSeconds = globalInactivityDurationSeconds;
            if (inactivityTimestamp)
            {
                const endDate = Date.now();
                inactivityDurationSeconds = Math.floor((endDate - inactivityTimestamp) / 1000) + globalInactivityDurationSeconds;
                setInactivityTimestamp(null);
                // only send one UserActive event
                if (durationSeconds === 60)
                {
                    trackEvent("UserActive", {
                        inactivityDurationSeconds: inactivityDurationSeconds
                    });
                }
                setHasSentEvent(false);
                setGlobalInactivityDurationSeconds(0);
            }
        },
        idleTime: durationSeconds })

        return {isIdle, isIdleTimer, hasSentEvent, setHasSentEvent}
    }

    /* UNUSED BROKEN
    const setHeartbeatEvent = (heartbeatEvents) => {
        if (heartbeatEvents)
        {
            let localProposalCount = heartbeatEvents.proposalCount ? heartbeatEvents.proposalCount + userActiveHeartbeatEvents.proposalCount : userActiveHeartbeatEvents.proposalCount;
            let localProposalQACount = heartbeatEvents.proposalQACount ? heartbeatEvents.proposalQACount + userActiveHeartbeatEvents.proposalQACount : userActiveHeartbeatEvents.proposalQACount;
            let localAggregationCount = heartbeatEvents.aggregationCount ? heartbeatEvents.aggregationCount + userActiveHeartbeatEvents.aggregationCount : userActiveHeartbeatEvents.aggregationCount;
            let localAggregationNextPageCount = heartbeatEvents.aggregationPageCount ? heartbeatEvents.aggregationPageCount + userActiveHeartbeatEvents.aggregationPageCount : userActiveHeartbeatEvents.aggregationPageCount;
            let localAggregationQACount = heartbeatEvents.aggregationQACount ? heartbeatEvents.aggregationQACount + userActiveHeartbeatEvents.aggregationQACount : userActiveHeartbeatEvents.aggregationQACount;
            let localAggregationQASetGroupRepresentativeCount = heartbeatEvents.aggregationQASetGroupRepresentativeCount ? heartbeatEvents.aggregationQASetGroupRepresentativeCount + userActiveHeartbeatEvents.aggregationQASetGroupRepresentativeCount : userActiveHeartbeatEvents.aggregationQASetGroupRepresentativeCount;
            
            setUserActiveHeartbeatEvents({
                proposalCount: localProposalCount,
                proposalQACount: localProposalQACount,
                aggregationCount: localAggregationCount,
                aggregationNextPageCount: localAggregationNextPageCount,
                aggregationQACount: localAggregationQACount,
                aggregationQASetGroupRepresentativeCount: localAggregationQASetGroupRepresentativeCount,
                hasHadUsefulActivity: localProposalCount > 0 ||
                                        localProposalQACount > 0 ||
                                        localAggregationCount > 0 ||
                                        localAggregationNextPageCount > 0 ||
                                        localAggregationQACount > 0 ||
                                        localAggregationQASetGroupRepresentativeCount > 0,
                ...userActiveHeartbeatEvents
            })
        }
    }*/
    const setHeartbeatEventProperty = (eventPropertyName) => {
        let localUserActiveHeartbeatEvents = JSON.parse(localStorage.getItem("userActiveHeartbeatEvents"))
        let localUserActiveHeartbeatEventsObj = localUserActiveHeartbeatEvents === null || localUserActiveHeartbeatEvents === "null" ? blankHeartbeatEvents : localUserActiveHeartbeatEvents
        let formattedEventPropertyName = eventPropertyName.charAt(0).toLowerCase() + eventPropertyName.slice(1) + `Count`;
        const obj = {
            ...localUserActiveHeartbeatEventsObj,
            hasHadUsefulActivity: true
        }
        obj[`${formattedEventPropertyName}`] = localUserActiveHeartbeatEventsObj[`${formattedEventPropertyName}`] + 1
        localStorage.setItem("userActiveHeartbeatEvents", JSON.stringify(obj))
        localUserActiveHeartbeatEventsObj = JSON.parse(localStorage.getItem("userActiveHeartbeatEvents"))
        setUserActiveHeartbeatEvents(obj)
        console.log(`UserActiveEvent properties added to: ${JSON.stringify(localUserActiveHeartbeatEventsObj)}`)
    }
    
    useEffect(() => {
        // THIS IS THE MAGIC PART
        //let localUserActiveHeartbeatEvents = JSON.parse(localStorage.getItem("userActiveHeartbeatEvents"))
        //let localUserActiveHeartbeatEventsObj = localUserActiveHeartbeatEvents === null || localUserActiveHeartbeatEvents === "null" ? blankHeartbeatEvents : localUserActiveHeartbeatEvents
        //    
        //userActiveHeartbeatEventsRef.current = localUserActiveHeartbeatEventsObj;
    }, [userActiveHeartbeatEvents]);

    const {isIdle_1min, idleTimer_1min, hasSentEvent_1min, setHasSentEvent_1min} = useTrackInactiveEvent(1 * 60); 
    const {isIdle_5min, idleTimer_5min, hasSentEvent_5min, setHasSentEvent_5min} = useTrackInactiveEvent(5 * 60); 
    const {isIdle_10min, idleTimer_10min, hasSentEvent_10min, setHasSentEvent_10min} = useTrackInactiveEvent(10 * 60); 
    const {isIdle_15min, idleTimer_15min, hasSentEvent_15min, setHasSentEvent_15min} = useTrackInactiveEvent(15 * 60); 
    const {isIdle_20min, idleTimer_20min, hasSentEvent_20min, setHasSentEvent_20min} = useTrackInactiveEvent(20 * 60); 
    const {isIdle_25min, idleTimer_25min, hasSentEvent_25min, setHasSentEvent_25min} = useTrackInactiveEvent(25 * 60); 
    const {isIdle_30min, idleTimer_30min, hasSentEvent_30min, setHasSentEvent_30min} = useTrackInactiveEvent(30 * 60); 

    useEffect(() => {
        if (!hasSentEvent_1min || !hasSentEvent_5min || !hasSentEvent_10min || !hasSentEvent_15min || !hasSentEvent_20min || !hasSentEvent_25min || !hasSentEvent_30min)
        {
            if (idleTimer_1min)
                idleTimer_1min.reset();
            if (idleTimer_5min)
                idleTimer_5min.reset();
            if (idleTimer_10min)
                idleTimer_10min.reset();
            if (idleTimer_15min)
                idleTimer_15min.reset();
            if (idleTimer_20min)
                idleTimer_20min.reset();
            if (idleTimer_25min)
                idleTimer_25min.reset();
            if (idleTimer_30min)
                idleTimer_30min.reset();
        }
    }, [hasSentEvent_1min, setHasSentEvent_1min,
        hasSentEvent_5min, setHasSentEvent_5min,
        hasSentEvent_10min, setHasSentEvent_10min,
        hasSentEvent_15min, setHasSentEvent_15min,
        hasSentEvent_20min, setHasSentEvent_20min,
        hasSentEvent_25min, setHasSentEvent_25min,
        hasSentEvent_30min, setHasSentEvent_30min
    ]);

    const { currentUser, isAuthenticated } = useAuth();
    const userID = (isAuthenticated && !!currentUser)
        ? currentUser["signInDetails"]["loginId"] 
        : null; // - https://amplitude.com/docs/get-started/identify-users


    // Session replay - records each user session and adds session information to events (see trackEvent();)
    // - https://www.npmjs.com/package/@amplitude/session-replay-browser (docs)
    // - https://app.amplitude.com/analytics/trussarchive-419047/session-replay (video tutorial)
    // - https://amplitude.com/docs/session-replay (viewing Session Replay in Amplitude console)
    // - https://amplitude.com/docs/session-replay/session-replay-plugin
    useEffect(() => {
        if (defaultEnableTracking) {
            if (defaultEnableSessionTracking)
            {
                // For use with 3rd party tracking (not Amplitude) - amplitudeSessionReplay.init(apiKey, amplitudeSessionReplayOptions)
                const _amplitudeSessionReplayTrackingInstance = sessionReplayPlugin(sessionReplayOptions);
                setAmplitudeSessionReplayTrackingInstance(_amplitudeSessionReplayTrackingInstance);
                
                amplitude.add(_amplitudeSessionReplayTrackingInstance);
                setAmplitudeTrackingInstance(amplitudeTrackingInstance);
            }
        } else {
            if (!(sessionReplayTrackingInstance === null || sessionReplayTrackingInstance === undefined))
            {
                sessionReplayTrackingInstance.shutdown();
            }
        }
    }, [])

    useEffect(() => {
        setActivePipelineSessionEventProperties(JSON.parse(window.localStorage.getItem('activePipelineSessionEventProperties')));
    }, []);

    useEffect(() => {
        window.localStorage.setItem('activePipelineSessionEventProperties', JSON.stringify(activePipelineSessionEventProperties));
    }, [activePipelineSessionEventProperties]);
 
    useEffect(() => {
        const amplitudeInstance = amplitude.createInstance();
        console.log(`amplitudeInstance: ${JSON.stringify(amplitudeInstance)}`)
        if (!amplitudeInstance) {
          throw new Error('Amplitude initialization failed: API key is not set.');
        }
        if (defaultEnableTracking)
        {
            // - https://stackoverflow.com/questions/39823648/how-do-integrate-amplitude-analytics-with-a-react-app
            // UNUSED - USE ONL IF USER CHANGES DURING APP amplitude.setUserId(userName);
            async function initInstance() {
                if (!!userID)
                {
                    const response = await amplitudeInstance.init(apiKey, userID, apiOptions)
                } else {
                    const response = await amplitudeInstance.init(apiKey, apiOptions)
                }
            }
            try
            {
                initInstance();
            } catch (err) {
                
                showAlert(JSON.stringify(err));
                console.log(`ERROR Amplitude: Could not init Amplitude tracking:\n${JSON.stringify(err)}`)
            }
        }
        if (defaultEnableAutoTracking)
        {
            amplitudeInstance.add(autocapturePlugin());
        }
        setAmplitudeTrackingInstance(amplitudeInstance);
    }, [defaultEnableTracking, defaultEnableAutoTracking, defaultEnableSessionTracking, userID, apiKey, apiOptions]);
    
    /**
     * This is an example plugin that enriches events with event_type "Page View" by adding
     * more event_properties on top of what @amplitude/analytics-browser provides out of the box
     *
     * @returns EnrichmentPlugin
     
    - https://github.com/amplitude/Amplitude-TypeScript/blob/main/examples/plugins/page-view-tracking-enrichment/index.ts
    */
    const pageViewTrackingEnrichment = () => {
        return {
            name: 'page-view-tracking-enrichment',
            type: 'enrichment',
            setup: async () => undefined,
            execute: async (event) => {
                let inactivityDurationSeconds = globalInactivityDurationSeconds;
                if (inactivityTimestamp)
                {
                    const endDate = Date.now();
                    inactivityDurationSeconds = Math.floor((endDate - inactivityTimestamp) / 1000) + globalInactivityDurationSeconds;
                    setInactivityTimestamp(null);
                    // only send one UserActive event
                    trackEvent("UserActive", {
                        inactivityDurationSeconds: inactivityDurationSeconds
                    });
                    setHasSentEvent_1min(false);
                    setHasSentEvent_5min(false);
                    setHasSentEvent_10min(false);
                    setHasSentEvent_15min(false);
                    setHasSentEvent_20min(false);
                    setHasSentEvent_25min(false);
                    setHasSentEvent_30min(false);
                    setGlobalInactivityDurationSeconds(0);
                }

                if (event.event_type === '[Amplitude] Page Viewed')
                {
                    console.log(`PAGEVIEWED activePipelineSessionEventProperties: ${JSON.stringify(activePipelineSessionEventProperties)} hasEndedPipeline: ${hasEndedPipeline}`)
                    // if (activePipelineSessionEventProperties && activePipelineSessionEventProperties['jobName'])
                    if (!hasEndedPipeline)
                    {
                        console.log(`event.event_properties['[Amplitude] Page Path']: ${event.event_properties['[Amplitude] Page Path']}`)
                        const formattedUrl = `${activePipelineSessionEventProperties['jobName']}/${activePipelineSessionEventProperties['pipelineJobId']}`
                        if (!event.event_properties['[Amplitude] Page Path'].includes(formattedUrl))
                        {
                            trackEvent("EndPipelineSession", {
                                ...activePipelineSessionEventProperties
                            });
                            setHasEndedPipeline(true)
                        }
                    }
                }

                event.event_properties = {
                    ...event.event_properties,
                    environment: process.env.REACT_APP_NODE_ENV, //added 'environment' event property
                    // TODO: Add more new event properties here; can also ENRICH sessions, fileDownloads etc. (those in eventTrackingConfig [apiOptions])
                };
                return event;
            },
        };
    };

    useEffect(() => {
        if (amplitudeTrackingInstance)
        {
            amplitudeTrackingInstance.add(pageViewTrackingEnrichment());
            // TODO Add more ENRICHMENT functions here (sessions, fileDownloads etc.)
        }
    }, [amplitudeTrackingInstance]);

    // Event Tracking - call this with eventName to track a new/existing event
    // Optional parameter sessionReplayProperties
    const trackEvent = (eventName, eventProperties, sessionReplayProperties={}) => {
        ////todo add to PageView

        if (eventName === 'StartPipelineSession')
        {
            setActivePipelineSessionEventProperties(eventProperties);
            setHasEndedPipeline(false)
        }

        // Heartbeats
        if (getUserActiveHeartbeatProperties().map(x => x.replace("Count", "").toLowerCase()).includes(eventName.toLowerCase()))
        {
            setHeartbeatEventProperty(eventName);
        }


        const customEventProperties = {
            ...eventProperties,
            environment: process.env.REACT_APP_NODE_ENV,
        };

        const customSessionReplayProperties = {
            ...AmplitudeTrackingContext.Provider.sessionReplayOptions,
            ...sessionReplayProperties,
        };

        const trackedEvent = getTrackedEvent(eventName, eventProperties);
        if (trackedEvent)
        {
            //try
            //{
                amplitudeTrackingInstance.track(trackedEvent['eventName'], {
                    ...customEventProperties,
                    ...customSessionReplayProperties
                });
                console.log(`Amplitude: Logged new TrackedEvent:\n${JSON.stringify(trackedEvent)}`)
            //} catch (err)
            //{
            //    showAlert(err);
            //    console.log(`ERROR Amplitude: Could not log new TrackedEvent:\n${JSON.stringify(trackedEvent)}`)
            //}
        } else {
            console.error(`Cannot get TrackedEvent with eventName '${eventName}' and eventProperties \n${JSON.stringify(eventProperties)}`)
        }

        /*
        window.localStorage.setItem('activePipelineSessionEventProperties', JSON.stringify(null));
                            setActivePipelineSessionEventProperties(null);
                            console.log(`activePipelineSessionEventProperties: ${JSON.stringify(activePipelineSessionEventProperties)}`)    
                            
        */
    }

    /*
        Unused Amplitude Taxonomy API - 404 unauthorized issue with CORS policy
        - https://amplitude.com/docs/apis/analytics/taxonomy
    */
    //useEffect(() => {
    //    if (secretApiKey && apiKey)
    //    {
    //        const authorizationHeader = `Basic ${apiKey}:${secretApiKey}`;
    //        let headers = {
    //            Authorization: authorizationHeader
    //        };
    //        axios.post(
    //            `${'https://amplitude.com/api/2/taxonomy/category'}`,
    //            headers
    //        )
    //        .then((res) => {
    //            console.log("tracked event categories", res);
    //            setTrackedEventCategories(res.data);
    //        })
    //        .catch((err) => {
    //            alert(
    //                `Failed to fetch tracked event categories using the Ampltiude Taxonomy API`
    //            );
    //        });
    //    } else {
    //        console.log(`Could not retrieve ApiKey and SecretKey for Amplitude. Amplitude Taxonomy API calls will fail.`)
    //    }
    //}, [apiKey, secretApiKey]);


    /*
        Event tracking using Amplitude:
        - https://app.amplitude.com/data/trussarchive-419047/default/catalog/setup/default/SDK_TS
    */

        
        useEffect(() => {
            //console.log("UserActiveHeartbeat timer started.")
            //const timer = setTimeout(() => {
            //    const heartbeatEventsObj = userActiveHeartbeatEventsRef.current;
            //    trackEvent("UserActiveHeartbeat", heartbeatEventsObj);
            //    setHeartbeatCount(heartbeatCount+1);
            //    setUserActiveHeartbeatEvents(blankHeartbeatEvents);
            //    console.log("UserActiveHeartbeat timer ended.")
            //}, minInactivityDurationSeconds * 1000)
            //return () => clearTimeout(timer)
    
            if (heartbeatTimerIsTicking && amplitudeTrackingInstance)
            {
                let timerDuration = minInactivityDurationSeconds
                let storedSeconds = window.localStorage.getItem("seconds") !== null && window.localStorage.getItem("seconds") !== undefined ? parseInt(window.localStorage.getItem("seconds")) : null
                let storedMinutes = window.localStorage.getItem("minutes") !== null && window.localStorage.getItem("minutes") !== undefined ? parseInt(window.localStorage.getItem("minutes")) : null
                let storedDuration = (storedMinutes*60)+storedSeconds
                if (storedDuration && storedDuration !== minInactivityDurationSeconds) {
                    timerDuration = storedDuration
                } else if (storedDuration === 0)
                {
                    timerDuration = minInactivityDurationSeconds
                }
                
                //channel.postMessage(stopSendingActiveHeartbeatEvents);
                console.log("UserActiveHeartbeat timer started.")
                startTimer(timerDuration);
            } else {
                // stop if turned off
                handleHeartbeatRefReset();
                handleReset();
                //channel.close()
            }
        }, [heartbeatCount, heartbeatTimerIsTicking, amplitudeTrackingInstance, userActiveHeartbeatTimerRef])

        //channel.onmessage = function(e) {
        //    let localBrowserIdsStr = localStorage.getItem("browserIds");
        //    let localBrowserIds = JSON.parse(localBrowserIdsStr)
        //    console.log(`BROADCAST RECIEVED: data: ${e.data}, browserIds: ${localBrowserIds}, shouldHearbeatTimerBeTicking: ${e.data}, heartbeatTimerIsTicking: ${heartbeatTimerIsTicking}`)
        //    if (e.data)
        //    {
        //        setHeartbeatTimerIsTicking(false);
        //        console.log(`UserActiveHeartbeat timer DISABLED. hearbeatTimerBeTicking: ${heartbeatTimerIsTicking}`)
        //    }
        //};
        
        const handleReset = () => {
            // stop on reset
            clearInterval(userActiveHeartbeatTimerRef.current) 
            userActiveHeartbeatTimerRef.current = undefined;
        };

        const handleHeartbeatRefReset = () => {
            //const heartbeatEventsObj = userActiveHeartbeatEventsRef.current;
            localStorage.setItem("userActiveHeartbeatEvents", JSON.stringify(blankHeartbeatEvents))
            setUserActiveHeartbeatEvents(blankHeartbeatEvents);
        }

        function startTimer(duration) {
            let storedSeconds = window.localStorage.getItem("seconds") !== null && window.localStorage.getItem("seconds") !== undefined ? parseInt(window.localStorage.getItem("seconds")) : null
            let storedMinutes = window.localStorage.getItem("minutes") !== null && window.localStorage.getItem("minutes") !== undefined ? parseInt(window.localStorage.getItem("minutes")) : null
            let storedDuration = (storedMinutes*60)+storedSeconds
            
            if (!userActiveHeartbeatTimerRef.current) {
                var timer = duration, minutes, seconds;

                userActiveHeartbeatTimerRef.current = setInterval(function () {
                    minutes = parseInt(timer / 60, 10)
                    seconds = parseInt(timer % 60, 10);
                    if (storedDuration && storedDuration !== minInactivityDurationSeconds) {
                        duration = storedDuration
                    }
                    
                    if (--timer < 0) {
                        let localUserActiveHeartbeatEvents = JSON.parse(localStorage.getItem("userActiveHeartbeatEvents"))
                        let localUserActiveHeartbeatEventsObj = localUserActiveHeartbeatEvents === null || localUserActiveHeartbeatEvents === "null" ? blankHeartbeatEvents : localUserActiveHeartbeatEvents
                        trackEvent("UserActiveHeartbeat", localUserActiveHeartbeatEventsObj);
                        setHeartbeatCount(heartbeatCount+1);
                        window.localStorage.setItem("lastHeartbeatSent", Date.now())
                        
                        timer = minInactivityDurationSeconds;
                        duration = minInactivityDurationSeconds;
                        minutes = parseInt(timer / 60, 10);
                        seconds = parseInt(timer % 60, 10);

                        handleHeartbeatRefReset()
                        handleReset()
                    }
                    window.localStorage.setItem("seconds",seconds)
                    window.localStorage.setItem("minutes",minutes)
                }, 1000);
            } else {
                //let storedSeconds = window.localStorage.getItem("seconds") ? parseInt(window.localStorage.getItem("seconds")) : null
                //let storedMinutes = window.localStorage.getItem("minutes") ? parseInt(window.localStorage.getItem("minutes")) : null
                let storedSeconds = window.localStorage.getItem("seconds") ? parseInt(window.localStorage.getItem("seconds")) : null
                let storedMinutes = window.localStorage.getItem("minutes") ? parseInt(window.localStorage.getItem("minutes")) : null
                let storedDuration = (storedMinutes*60)+storedSeconds
                let timerDuration = minInactivityDurationSeconds - storedDuration
                
                if (timerDuration !== 0)
                {
                    let localUserActiveHeartbeatEvents = JSON.parse(localStorage.getItem("userActiveHeartbeatEvents"))
                    let localUserActiveHeartbeatEventsObj = localUserActiveHeartbeatEvents === null || localUserActiveHeartbeatEvents === "null" ? blankHeartbeatEvents : localUserActiveHeartbeatEvents
            
                    localUserActiveHeartbeatEventsObj.heartbeatDurationSeconds = timerDuration;
                    trackEvent("UserActiveHeartbeat", localUserActiveHeartbeatEventsObj);
                    setHeartbeatCount(heartbeatCount+1);
                    window.localStorage.setItem("lastHeartbeatSent", Date.now())
                }

                handleHeartbeatRefReset();
                handleReset();
                console.log("UserActiveHeartbeat timer ended.")
            }
        }

        window.onload = function () {
            const sec  = parseInt(window.localStorage.getItem("seconds"))
            const min = parseInt(window.localStorage.getItem("minutes"))
        
            let timerSeconds = minInactivityDurationSeconds
            if(parseInt(min*sec)){
                timerSeconds = (parseInt(min*60)+sec);
            }else{
                timerSeconds = minInactivityDurationSeconds;
            }
            // var fiveMinutes = 60 * 5;
            //display = document.querySelector('#amplitude-heartbeat-timer');
                
            if (heartbeatTimerIsTicking && amplitudeTrackingInstance)
            {
                startTimer(timerSeconds);
            } else {
                // stop if turned off
                handleHeartbeatRefReset();
                handleReset()
            }
        };

    return (
      <AmplitudeTrackingContext.Provider
        value={{
            sessionReplayTrackingInstance, 
            setSessionReplayTrackingInstance, 
            amplitudeTrackingInstance, 
            setAmplitudeTrackingInstance,
            trackEvent
        }}
      >
        <div id="amplitude-heartbeat-timer" />
        {children}
      </AmplitudeTrackingContext.Provider>
    );
  };
  
export const useAmpltiudeEventTrackingContext = () => useContext(AmplitudeTrackingContext);