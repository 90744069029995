import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { LicenseInfo } from "@mui/x-license-pro";

Amplify.configure(awsconfig);
LicenseInfo.setLicenseKey(
  "b5dd34fb5e1544164076b246810c56f2Tz05NDEwMyxFPTE3NTIzMTM0NTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
