// debugger.js

class Debugger {
  constructor() {
    this.logs = [];
  }

  log(message) {
    const event = new CustomEvent("debugger-log", { detail: message });
    this.logs.push(message);
    window.dispatchEvent(event);
  }

  getLogs() {
    return this.logs;
  }
}

const debuggerInstance = new Debugger();
export default debuggerInstance;
