// src/components/AppBarComponent.js
import React from "react";
import { AppBar, Toolbar, Typography, IconButton, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../../contexts/AuthContext";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
export const APP_BAR_HEIGHT = 64;
const env = process.env.REACT_APP_NODE_ENV;

const AppBarComponent = ({ handleDrawerToggle }) => {
  const { signOutUser, currentUser } = useAuth();
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const handleSignOut = () => {
    signOutUser();
    // Track Amplitude Event: "Logout"
    trackEvent("Logout", { success: true });
  };

  return (
    <AppBar
      position="fixed"
      height={APP_BAR_HEIGHT}
      sx={{
        height: APP_BAR_HEIGHT,
        color: (theme) => {
          if (env === "development") {
            return theme.palette.primary[100];
          } else if (env === "production") {
            return theme.palette.primary[800];
          } else if (env === "staging") {
            return theme.palette.primary[100];
          }
        },
        boxShadow: "none",
        borderBottom: (theme) => `2px solid ${theme.palette.primary[800]}`,
        backgroundColor: (theme) => {
          if (env === "development") {
            return theme.palette.secondary[800];
          } else if (env === "production") {
            return theme.palette.primary[100];
          } else if (env === "staging") {
            return theme.palette.secondary[800];
          }
        },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h2"
          sx={{
            ml: 2,
          }}
        >
          BRIDGE
        </Typography>
        {env != "production" ? (
          <Typography
            variant="h2"
            sx={{
              ml: 2,
            }}
          >
            {env.toUpperCase()}
          </Typography>
        ) : null}
        <Button
          variant="text-link"
          color="inherit"
          sx={{ ml: "auto" }}
          onClick={handleSignOut}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
