// src/routes/AttributeManagementRoutes.js
import React, { useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import {
  attributeTypes,
  attributeScreenConfig,
} from "../config/attributeTypes";
import {
  AttributeTypeProvider,
  useAttributeType,
} from "../contexts/AttributeTypeContext";
import AttributeDashboard from "../screens/admin/attributes/AttributeDashboard";

import AttributeGroupDashboard from "../screens/admin/attributes/AttributeGroupDashboard";
import CreateAttributeGroupScreen from "../screens/admin/attributes/CreateAttributeGroupScreen";
import CreateAttributeScreen from "../screens/admin/attributes/CreateAttributeScreen";

const AttributeRoutes = () => {
  const { setAttributeType, setAttributeScreenConfig } = useAttributeType();
  const { attributeType } = useParams();

  useEffect(() => {
    if (attributeType) {
      setAttributeType(attributeTypes[attributeType]);
      setAttributeScreenConfig(
        attributeScreenConfig[attributeTypes[attributeType]]
      );
    }
  }, [attributeType, setAttributeType]);

  return (
    <Routes>
      <Route path="" element={<AttributeDashboard />} />
      <Route path="create/:id?" element={<CreateAttributeScreen />} />
      <Route path="groups" element={<AttributeGroupDashboard />} />
      <Route
        path="groups/create/:id?"
        element={<CreateAttributeGroupScreen />}
      />
    </Routes>
  );
};

const AttributeManagementRoutes = () => {
  return (
    <AttributeTypeProvider>
      <Routes>
        <Route path=":attributeType/*" element={<AttributeRoutes />} />
      </Routes>
    </AttributeTypeProvider>
  );
};

export default AttributeManagementRoutes;
