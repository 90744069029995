export const clientNames = ["Depop", "Farfetch", "Selfridges", "Ebay"]

export const ENABLED_CLIENT = clientNames[0]

export const isValidClientName = (clientName) => clientNames.includes(clientName)

export const clientIdClientNameMap = {
  "Depop": {
    ids: [9]
  },
  "Farfetch": {
    ids: [11]
  },
  "Selfridges": {
    ids: [7]
  },
  "Ebay": {
    ids: [9]
  }
}

// only gets the first name associated with the dataset. we will need to add extra fields to clientIdClientNameMap to get this to work for a many-to-many relationship
export const getClientNameFromDatasetID = (id) => {
  let intId = Number(id)
  let returnClientName = "Depop"; //Depop view returned by default
  Object.keys(clientIdClientNameMap).forEach(function(clientName) {
    const clientMap = clientIdClientNameMap[clientName];
    const found = clientMap.ids.includes(intId);
    if (found) 
    {
      returnClientName = clientName;
      return returnClientName;
    }
  });

  return returnClientName;
}