/**
 * @fileoverview ThemeContext is a context that provides the theming and stylesheets for a given brand.
 * It is used to manage the state of the user's dark/light mode setting in the browser
 * It is used to apply brand themes to the App's CSS
 * It is used to hold the list of all available themes; each theme has an boolean option "hasDarkMode"
  @state {Boolean} userDarkModePreference - The user's browser/OS setting for Dark Mode
  @function createTheme - A MUI function which creates the theme from the "themeStyles" in config
 */

import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { getThemeStyling } from "../config/themeStylesConfig"
import useMediaQuery from '@mui/material/useMediaQuery';
import { baseTheme } from "../theme/BaseTheme";
import { DEFAULT_ENABLE_DARK_MODE } from "../config/themeStylesConfig"

const ThemeContext = createContext({ toggleColorMode: () => {} });

export const ThemeStylingProvider = ({ children, id }) => {
    const defaultEnableDarkMode = DEFAULT_ENABLE_DARK_MODE;
    // State to hold the value of the context
    const [themeName, setThemeName] = useState("truss");
    // state to manage the dark mode
    const [toggleDarkMode, setDarkThemeEnabled] = useState(defaultEnableDarkMode);
    // check user browser/OS settings for darkmode preference: https://mui.com/material-ui/customization/dark-mode/
    const userDarkModePreference = useMediaQuery('(prefers-color-scheme: dark)');

    const getDarkModeSetting = useCallback(() => {
        //logic for if no manual darkmode toggle, then use browser/OS settings
        const shouldEnableDarkMode = defaultEnableDarkMode ? userDarkModePreference : defaultEnableDarkMode;
        return shouldEnableDarkMode;
    }, [userDarkModePreference, defaultEnableDarkMode]);

    useEffect(() => {
      getDarkModeSetting();
    }, [userDarkModePreference, toggleDarkMode, getDarkModeSetting]); // Add dependencies to useEffect's dependency array
  
    /*
        Toggle for darkmode
    */
    const [mode, setMode] = useState(defaultEnableDarkMode ? 'dark' : 'light');
    // function to toggle the dark mode as true or false [TODO add a button which calls toggleDarkTheme()]
    const toggleDarkTheme = useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setMode((prevPaletteMode) =>
                    prevPaletteMode === 'light' ? 'dark' : 'light',
                );
                setDarkThemeEnabled(!toggleDarkMode);
            },
        }),
        [toggleDarkMode],
    );
    
    // Update the theme only if the mode changes
    const theme = useMemo(
        () =>
        createTheme({
            ...({palette: { 
                    mode: mode 
                }}),
            ...baseTheme,
            ...(getThemeStyling(themeName, getDarkModeSetting()))
        }),
        [getDarkModeSetting, mode, themeName],
    );

    /*
        Dark Mode:
        - https://mui.com/material-ui/customization/dark-mode/
        - https://semaphoreci.com/blog/dark-mode-reactjs-material-ui#:~:text=Enabling%20Dark%20Mode%20using%20Material,when%20implementing%20the%20Dark%20mode.
        - https://mui.com/material-ui/customization/dark-mode/
    */
    return (
      <ThemeContext.Provider
        value={{
            themeName
        }}
      >
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
      </ThemeContext.Provider>
    );
  };
  
export const useThemeContext = () => useContext(ThemeContext);