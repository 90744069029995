import { Box } from "@mui/material";
import React from "react";
import DefineGarmentSection from "../../../components/features/Pipelines/DefineGarmentSection";
import PipelineDetails from "../../../components/features/Pipelines/PipelineDetails";

function DefineGarmentSubScreen() {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          padding: 1,
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <PipelineDetails />
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          padding: 1,
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <DefineGarmentSection />
      </Box>
    </Box>
  );
}

export default DefineGarmentSubScreen;
