import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";

const SelectableList = ({
  items,
  selectedItems,
  onSelect,
  multiselect = true,
}) => {
  const { palette } = useTheme();
  const isSelected = (item) => {
    return selectedItems?.id === item.id;
  };
  const updateSelection = (item) => {
    if (isSelected(item)) {
      onSelect(null);
    } else {
      onSelect(item);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key;
      const number = parseInt(key, 10);
      if (!isNaN(number) && number >= 1 && number <= items.length) {
        updateSelection(items[number - 1]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [items, selectedItems, onSelect]);

  return (
    <Box
      sx={{
        border: `1px solid ${palette.primary[200]}`,
        borderRadius: 1,
        minHeight: 200,
        display: "flex",
        flexDirection: "column",
        p: 1,
      }}
    >
      {items.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box
            sx={{
              border: `1px solid ${palette.primary[200]}`,
              borderRadius: 1,
              pr: 3,
              pl: 3,
              pt: 1,
              pb: 1,
            }}
          >
            <Typography variant="body1">List Empty</Typography>
          </Box>
        </Box>
      ) : (
        false
      )}
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            p: 0.5,
          }}
        >
          <Typography
            variant="body1"
            style={{
              textDecoration: isSelected(item) ? "underline" : "none",

              cursor: "pointer",
              fontFamily: isSelected(item) ? "Inter-Medium" : "Inter-Regular",
            }}
            onClick={() => updateSelection(item)}
          >
            {index + 1}. {item.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SelectableList;
