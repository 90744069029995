import React from "react";
import { Routes, Route } from "react-router-dom";
import { jobTypes, jobNamesMap } from "../config/jobTypes"; // Assuming your job types and mapping are defined in a constants file
import GarmentAttributeTool from "../screens/tools/GarmentAttributeTool/GarmentAttributeTool";
import ImageAttributeTool from "../screens/tools/ImageAttributeTool/ImageAttributeTool";
import GarmentAttributeQaTool from "../screens/tools/GarmentAttributeTool/GarmentAttributeQATool";
import AggregationTool from "../screens/tools/AggregationTool/AggregationTool";
import MultiGarmentAttributeTool from "../screens/tools/GarmentAttributeTool/MultiGarmentAttributeTool";
import ImageAttributeQaTool from "../screens/tools/ImageAttributeTool/ImageAttributeQATool";
import AggregationQATool from "../screens/tools/AggregationTool/AggregationQATool";

const JobsRoutes = () => {
  // Function to determine which tool to render based on the job type
  const renderToolForJob = (jobType) => {
    switch (jobNamesMap[jobType]) {
      case jobTypes.IMAGE_TAGGING:
        return <ImageAttributeTool />;
      case jobTypes.IMAGE_TAGGING_QA:
        return <ImageAttributeQaTool />;
      // Add cases for other job types and tools as needed
      case jobTypes.TYPE:
        return <GarmentAttributeTool />;
      case jobTypes.TYPE_QA:
        return <GarmentAttributeQaTool />;
      case jobTypes.STYLE_QA:
        return <GarmentAttributeQaTool />;
      case jobTypes.STYLE_MAGNETS:
        return <MultiGarmentAttributeTool />;
      case jobTypes.STYLE:
        return <GarmentAttributeTool />;
      default:
        return null; // Render nothing if the job type is not recognized
    }
  };

  return (
    <Routes>
      {/* Map job types to corresponding routes */}
      {Object.keys(jobNamesMap).map((jobName) => (
        <Route
          key={jobName}
          path={`/${jobName}/:id`} // Assuming your job names map directly to route paths
          element={renderToolForJob(jobName)}
        />
      ))}
      <Route path="aggregation" element={<AggregationTool />} />
      <Route path="aggregation/qa" element={<AggregationQATool />} />
    </Routes>
  );
};

export default JobsRoutes;
