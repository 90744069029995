import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import ZoomImageModal from "../../../common/userFeedback/modals/ZoomImageModal"
import ScrollImageModal from "../../../common/userFeedback/modals/ScrollImageModal"
import { BASE_IMAGE_URL } from "../../../../config/apiConfig";

function ClickableScrollImage({ uuid_public_id, sxImg={}, image=null, num_images=null }) {
  const [columns, setColumns] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [images, setImages] = useState(null);
  const imageRef = useRef(null);
  const { palette } = useTheme();

  const handleRightClick = (event) => {
    event.preventDefault(); // Prevent default context menu
    event.stopPropagation(); // Prevents the event from bubbling up to the Box

    setOpenModal(true);
  };

  const handleClose = (event) => {
    event.stopPropagation(); // Prevents the event from bubbling up to the Box
    setOpenModal(false);
  };

  useEffect(() => {
    if (!num_images)
    {
      //TODOWP get num_images
      num_images = 1
      
      console.log(`params.row.num_images: ${num_images} ${uuid_public_id}`)
    }

    let imageUrls = [];
    for (let i = 1; i <= num_images; i++) {
      let imageUrl = `${BASE_IMAGE_URL}${uuid_public_id}-${i}.jpg`;
      console.log(imageUrl);
      imageUrls.push(imageUrl);
    }
    setImages(imageUrls);
  }, [num_images, BASE_IMAGE_URL, uuid_public_id])

  return (
    <Box
      sx={{
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        height: "100%",
        position: "relative",
      }}
      onContextMenu={handleRightClick} // Add this line to ensure right-click is captured
    >
      <Box
        component="img"
        src={image ?? images[0]}
        alt={`image`}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          cursor: "context-menu",
          marginLeft: -2,
          padding: 1,
          ...sxImg
        }} // Indicate right-click is available
      />

      <ScrollImageModal handleClose={handleClose} openModal={openModal} images={images} />
    </Box>
  );
}

export default ClickableScrollImage;
