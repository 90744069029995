import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { showAlert } from "../components/common/userFeedback/CustomAlert";
import { BASE_URL } from "../config/apiConfig";
import { jobNamesMap } from "../config/jobTypes";
import { getHeaders } from "../utils/apiUtils";
import { useAmpltiudeEventTrackingContext } from "./AmplitudeTrackingContext";
const normaliseFilters = (filters, current_job_index) => {
  try {
    const normalisedFilters = {};
    const filterHardness = {};

    Object.keys(filters).map((filterType) => {
      let typeDenotedFilters = filters[filterType];
      normalisedFilters[filterType] = [];
      typeDenotedFilters.map((filter) => {
        let attributes = filter.attributes;
        let index = filter.pipeline_job_index;
        if (index >= current_job_index) {
          filterHardness[filterType] = {
            hardness: "SOFT",
            requiredConfidence: 0,
          };
        } else {
          filterHardness[filterType] = {
            hardness: "HARD",
            requiredConfidence: 0, //filter.graduation_confidence,
          };
        }
        attributes.map((attribute) => {
          if (attribute.carry_forward && index >= current_job_index) {
            normalisedFilters[filterType].push(attribute.attribute_id);
          }
          if (attribute.required && index < current_job_index) {
            normalisedFilters[filterType].push(attribute.attribute_id);
          }
        });
      });
    });
    return { normalisedFilters, filterHardness };
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Create a context
const ToolContext = createContext();

// Create a provider component
export const ToolProvider = ({ children, id }) => {
  // State to hold the value of the context
  const [pipelineJobId, setPipelineJobId] = useState(null);
  const [inputFilters, setInputFilters] = useState([]);
  const [jobDetails, setJobDetails] = useState({});
  const [outputFiltersList, setOutputFilterList] = useState([]);
  const [jobDataLoaded, setJobDataLoaded] = useState(false);
  const [filterHardness, setFilterHardness] = useState();
  const [toolType, setToolType] = useState();
  const [allPipelineJobs, setAllPipelineJobs] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [shownAttributes, setShownAttributes] = useState([]);
  const [toolName, setToolName] = useState([]);
  const [counts, setCounts] = useState({
    jobPipelineAttributeCount: 100,
    jobAttributeCount: 1000,
    jobPipelineGarmentCount: 10,
    jobGarmentCount: 100,
  });
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleTabClosing);
    };
  });
  const increaseCount = (name, amount) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [name]: prevCounts[name] + amount,
    }));
  };

  const handleTabClosing = () => {
    trackEvent("EndPipelineSession", {
      pipelineJobId: jobDetails.pipeline_job_link_id,
      pipelineName: jobDetails.pipeline_name,
      jobId: jobDetails.job_id,
      jobName: jobDetails.job,
      toolType: jobNamesMap[jobDetails.job],
      confidence: jobDetails.qa_confidence,
    });
  };

  const alertUser = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "Enter":
          break;
        case "Tab":
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Add dependencies to useEffect's dependency array
  useEffect(() => {
    console.log(datasets);
  }, [datasets]);

  const fetchPipelineDetails = async (id) => {
    const headers = await getHeaders();
    await axios
      .get(`${BASE_URL}tools/pipelines/get-pipeline/${id}`, headers)
      .then((response) => {
        setDatasets(response.data.datasets);
      })
      .then(() => {
        setJobDataLoaded(true);
        setShownAttributes(["BRAND"]);
      })
      .catch((error) => {
        console.log(error);
        showAlert(error);
      });
  };
  const fetchCountDetails = async (props) => {
    const headers = await getHeaders();
    if (!jobDetails.job_id || !jobDetails.pipeline_job_link_id || !toolType) {
      return;
    }
    await axios
      .post(
        `${BASE_URL}tools/job-counts`,
        {
          pipelineJobId: jobDetails.pipeline_job_link_id,
          jobId: jobDetails.job_id,
          toolType: toolType,
          ...props,
        },
        headers
      )
      .then((response) => {
        setCounts(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        showAlert(error);
      });
  };

  const fetchJobDetails = async (id) => {
    const headers = await getHeaders();
    await axios
      .get(`${BASE_URL}tools/pipelines/job-details/${id}`, headers)
      .then((response) => {
        const {
          current_job,
          filters,
          current_job_filters,
          pipeline_jobs,
          pipeline_id,
        } = response.data.data;
        console.log("====================================");
        console.log(response.data.data);
        console.log("====================================");

        setToolName(current_job.job);
        setAllPipelineJobs(pipeline_jobs);
        setPipelineJobId(id);
        fetchPipelineDetails(pipeline_id);
        setJobDetails(current_job);
        console.log(filters);
        const { normalisedFilters, filterHardness } = normaliseFilters(
          filters,
          current_job.pipeline_job_index + 1
        );
        console.log(normalisedFilters);
        setFilterHardness(filterHardness);
        setInputFilters(normalisedFilters);
        setOutputFilterList(current_job_filters);
        setToolType(jobNamesMap[current_job.job]);

        trackEvent("StartPipelineSession", {
          pipelineJobId: current_job.pipeline_job_link_id,
          pipelineName: current_job.pipeline_name,
          jobId: current_job.job_id,
          jobName: current_job.job,
          toolType: jobNamesMap[current_job.job],
          confidence: current_job.qa_confidence,
        });
      })

      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchJobDetails(id);
  }, [id]);
  useEffect(() => {
    fetchCountDetails();
  }, [toolType, jobDetails]);

  return (
    <ToolContext.Provider
      value={{
        pipelineJobId,
        jobDetails,
        inputFilters,
        outputFiltersList,
        jobDataLoaded,
        toolType,
        filterHardness,
        allPipelineJobs,
        datasets,
        shownAttributes,
        counts,
        increaseCount,
        fetchCountDetails,
      }}
    >
      {children}
    </ToolContext.Provider>
  );
};

// Custom hook to consume the ToolContext
export const useToolContext = () => {
  return useContext(ToolContext);
};
