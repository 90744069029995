import ImageIcon from "@mui/icons-material/Image";
import CopyrightIcon from "@mui/icons-material/Copyright";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import WebhookIcon from "@mui/icons-material/Webhook";
import StyleIcon from "@mui/icons-material/Style";
export const defaultFilters = {
  BRAND: {},
  TYPE: {},
  STYLE: {},
  IMAGE_CLASS: {},
};

export const defaultJobs = [
  {
    id: "IMAGE_TAGGING",
    name: "Image Tagging Tool",
    graduationConfidence: 0.7,
    filterMap: "IMAGE_CLASS",
    users: [],
    mapping_value: "job",
    icon: <ImageIcon />,
    requires: ["IMAGE_TAGGING_QA"],
    jobName: "manual_image_tagging",
  },
  {
    id: "TYPE",
    name: "Type Tagging Tool",
    filterMap: "TYPE",
    graduationConfidence: 0.7,
    users: [],
    mapping_value: "job",
    requires: ["TYPE_QA"],
    icon: <CheckroomIcon />,
    jobName: "type_manual_tagging",
  },
  {
    id: "STYLE",
    name: "Style Tagging Tool",
    graduationConfidence: 0.7,
    filterMap: "STYLE",
    users: [],
    mapping_value: "job",
    icon: <StyleIcon />,
    requires: ["STYLE_QA"],
    jobName: "style_manual_tagging",
  },
  {
    id: "STYLE_MAGNETS",
    name: "Multi Garment Style Tagging Tool",
    graduationConfidence: 0.7,
    filterMap: "STYLE",
    users: [],
    mapping_value: "job",
    icon: <StyleIcon />,
    requires: ["STYLE_QA"],
    jobName: "style_manual_tagging_magnets",
  },
  {
    id: "IMAGE_TAGGING_QA",
    name: "Image Tagging QA",
    filterMap: "IMAGE_CLASS",
    graduationConfidence: 0.7,
    users: [],
    mapping_value: "qa",
    icon: <ImageIcon />,
    jobName: "manual_image_qa",
  },
  {
    id: "TYPE_QA",
    name: "Type Tagging QA",
    filterMap: "TYPE",
    graduationConfidence: 0.7,
    users: [],
    mapping_value: "qa",
    icon: <CheckroomIcon />,
    jobName: "type_manual_qa",
  },
  {
    id: "STYLE_QA",
    name: "Style Tagging QA",
    graduationConfidence: 0.7,
    filterMap: "STYLE",
    users: [],
    mapping_value: "qa",
    icon: <StyleIcon />,
    jobName: "style_manual_qa",
  },

  {
    id: "WEBHOOK_INVOKE",
    name: "Webhook Invoke",
    mapping_value: "function",
    icon: <WebhookIcon />,
  },
];
