import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { createRoot } from "react-dom/client";

// Create a functional component for the alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Manage the alert display from a single function
const openSnackbar = ({ message, type }) => {
  const container = document.createElement("div");
  document.body.appendChild(container);
  const root = createRoot(container);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (container.parentNode) {
      root.unmount();
      document.body.removeChild(container);
    }
  };
  //Handle timeout
  setTimeout(() => {
    handleClose();
  }, 6000);

  root.render(
    <Snackbar open onClose={handleClose}>
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

// Function to handle error objects directly
export const showAlert = (response) => {
  let message;
  let type;
  if (response instanceof Error) {
    console.log(response);
    type = "error";
    try {
      console.log(response.response);
      message = response.response.data.message;
      if (!message) message = response.message;
    } catch (error) {
      message = "An unknown error occurred";
    }
  } else {
    type = "success";
    try {
      console.log(response);
      message = response.data.message;
    } catch (error) {
      message = "Successful request";
    }
  }

  openSnackbar({ message: message, type: type });
};

export const showAlertWithMessage = (response) => {
  let type;
  let message = response;
  if (response instanceof Error) {
    console.log(response);
    type = "error";
    try {
      console.log(response.response);
    } catch (error) {
      message = "An unknown error occurred";
    }
  } else {
    type = "success";
    try {
      console.log(response);
    } catch (error) {
      message = "Successful request";
    }
  }

  openSnackbar({ message: message, type: type });
}