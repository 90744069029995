import React from "react";
import { Box, Typography, Container, useTheme, Button } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { BackButton } from "../../ui/buttons/Buttons";

const JobCompleteScreenCover = ({ showBackButton = true }) => {
  const { palette } = useTheme();

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
          borderRadius: 2,
          textAlign: "center",
          border: `3px solid ${palette.success.main}`,
        }}
      >
        <WarningIcon sx={{ fontSize: 60, mb: 2, color: "success.main" }} />
        <Typography variant="h4" component="div" gutterBottom>
          Job Complete
        </Typography>
        <Typography variant="body1">
          could not find any data for this job
        </Typography>
        {showBackButton ? (
          <BackButton
            title="Back"
            disabled={false}
            sx={{
              mt: 1,
            }}
          />
        ) : (
          false
        )}
      </Box>
    </Container>
  );
};

export default JobCompleteScreenCover;
