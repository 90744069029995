import React, { useEffect } from "react";

export default function KeyEventHandler({
  children,
  onEnter,
  onTab,
  onDelete,
  onShiftEnter,
  disableKeys=false
}) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!disableKeys) {
        console.log(event.key);

        switch (event.key) {
          case "Enter":
            console.log("Enter pressed");
            console.log(event.shiftKey);
            if (event.shiftKey) {
              // Handle Shift+Enter separately
              if (onShiftEnter) {
                event.preventDefault();
                onShiftEnter();
              } else {
                alert("Shift+Enter pressed. No action defined.");
              }
            } else {
              // Handle Enter (without Shift)
              if (onEnter) {
                event.preventDefault();
                onEnter();
              } else {
                alert("Enter pressed. No action defined.");
              }
            }
            break;
          case "Tab":
            if (onTab) {
              event.preventDefault();
              onTab();
            } else {
              alert("Tab pressed. No action defined.");
            }
            break;
          case "Delete":
          case "Backspace":
            if (onDelete) {
              event.preventDefault();
              onDelete();
            } else {
              alert("Delete pressed. No action defined.");
            }
            break;
          default:
            break;
        }
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onEnter, onTab, onDelete]); // Dependencies for useEffect

  return <>{children}</>; // Render children
}
