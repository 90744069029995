import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import StyledButton from "./StyledButton";

const PageChangeButton = ({ setPage, page, maxPage, disabled, exhausted }) => {
  const { palette, spacing } = useTheme();
  const [previousDisabled, setPreviousDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  useEffect(() => {
    if (page == 1) {
      setPreviousDisabled(true);
    } else {
      setPreviousDisabled(false);
    }
    if (page == maxPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [page]);

  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <StyledButton
        variant="outlined"
        color="primary"
        disabled={previousDisabled || disabled}
        onClick={() => {
          if (previousDisabled || disabled) return;
          setPage((page) => page - 1);
        }}
      >
        <ArrowBack sx={{ marginRight: spacing(1) }} />
        <Typography variant="h5">Back</Typography>
      </StyledButton>
      <Typography
        variant="h5"
        sx={{
          color: disabled ? palette.grey[500] : palette.primary.main,
          margin: spacing(1),
        }}
      >
        {page} / {maxPage}
      </Typography>

      <StyledButton
        variant="outlined"
        color="primary"
        disabled={nextDisabled || disabled || exhausted}
        onClick={() => {
          if (nextDisabled || disabled) return;
          setPage((page) => page + 1);
        }}
      >
        <Typography variant="h5">Next</Typography>
        <ArrowForward sx={{ marginLeft: spacing(1) }} />
      </StyledButton>
    </Box>
  );
};

export default PageChangeButton;
