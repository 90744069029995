import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

function CustomLoadingModal({ waitToShowModal, title, body,
        onDismissTitle="Return", onDismiss, 
        onConfirmTitle="Confirm", onConfirm=null }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [componentRendered, setComponentRendered] = useState(false);

  useEffect(() => {
    if (!waitToShowModal && componentRendered) {
      // Show the dialog when waitToShowModal is complete
      setDialogOpen(true);
    } else {
      setComponentRendered(true);
    }
  }, [waitToShowModal]);
  const handlePreventClose = (event) => {
    event.preventDefault();
  };

  //if onConfirm is not supplied (is null) then show first set of dialogActions, otherwise the other one
  const dialogActions = !onConfirm ? (
    <>
    <Button
      onClick={() => {
        onDismiss();
        setDialogOpen(false);
      }}
      color="primary"
      autoFocus
    >
      {onDismissTitle}
    </Button>
    </>
  ) : (
    <>
    <Button
      onClick={() => {
        onDismiss();
        setDialogOpen(false);
      }}
      color="primary"
      autoFocus
    >
      {onDismissTitle}
    </Button>
    
    <Button
      onClick={() => {
        onConfirm();
        setDialogOpen(false);
      }}
      color="primary"
      sx={{bgcolor: 'black'}}
      autoFocus
    >
      <Typography color="white">{onConfirmTitle}</Typography>
    </Button>
    </>
  )

  return (
    <>
      <Backdrop open={waitToShowModal} style={{ color: "#fff", zIndex: 2000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={dialogOpen}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogActions}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomLoadingModal;
