import React, { useState, useRef } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";

function ScrollImageModal({ openModal, handleClose, images, sx }) {
  const theme = useTheme()
  return (
    <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflowY: "scroll", width: "75%", height: "75%", backgroundColor: theme.palette.primary[100], margin: "auto", zIndex: 9999}}
      >
        <Grid container spacing={1} sx={{...sx, ...({backgroundColor: theme.palette.primary[100], p: 4})}}>
            {images?.map((image, index) => (
            <Grid item xs={6}>
                <img
                key={index}
                src={image}
                alt={`Garment Image ${index}`}
                style={{ width: "100%", marginBottom: "10px" }}
                />
            </Grid>
            ))}
        </Grid>
    </Modal>
  );
}

export default ScrollImageModal;
