// UserGroupMultiSelect.js
import React, { useEffect, useState } from "react";
import SearchableMultiSelect from "./SearchableMultiSelect";
import {
  Checkbox,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Box,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
const UserGroupMultiSelect = ({ setSelectedUsers }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const handleUserSelection = (event, value) => {
    setSelectedUsers((prev) => [
      ...prev,
      ...value.users.map((user) => {
        return { id: user.id, name: user.bridge_user };
      }),
    ]);
  };
  useEffect(() => {
    fetchUsers();
  }, [searchText]); // fetch brands whenever searchText changes

  const fetchUsers = async () => {
    let headers = await getHeaders();
    try {
      const response = await axios.get(
        `${BASE_URL}tools/admin/user-groups?extended=true`,
        headers
      );
      console.log(response.data.data);
      setUsers(response.data.data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  return (
    <Autocomplete
      sx={{
        mb: 1,
        mt: 1,
      }}
      options={users}
      getOptionLabel={(option) => option.bridge_user_group}
      value={selectedUser}
      onInputChange={(event, newInputValue) => {
        setSearchText(newInputValue);
      }}
      onChange={handleUserSelection}
      renderInput={(params) => (
        <TextField {...params} label="Select User Group" variant="outlined" />
      )}
    />
  );
};

export default UserGroupMultiSelect;
