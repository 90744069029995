import React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Typography, useTheme } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
const UserGridCustomToolbar = ({ onAddToGroupClick, sx={} }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      width: "100%",
      m: 1,
      cursor: "pointer",
      ...sx
    }}
    onClick={onAddToGroupClick}
  >
    <GroupAddIcon color="primary" />
    <Typography
      variant="label"
      sx={{
        marginLeft: 0.5,
      }}
    >
      Add to Group
    </Typography>
  </Box>
);

export default UserGridCustomToolbar;
