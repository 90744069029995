/**
 * AuthContext.js
 *
 * This file defines the AuthContext for the application, which is used to manage
 * and provide the authentication state throughout the application using AWS Amplify.
 *
 * The AuthContext provides a global state containing the current authenticated user,
 * loading state, and functions for signing in and signing out. It uses AWS Amplify
 * to interact with the authentication backend, which in this case is likely Amazon Cognito.
 *
 * The `AuthProvider` component defined in this file wraps the application (or part of it)
 * to provide the AuthContext to all its child components. It includes an effect hook to
 * check for the current authenticated user when the application loads.
 *
 * The `useAuth` custom hook is a convenient way to access the authentication state and
 * functions provided by the AuthContext from any component.
 *
 * Usage:
 * Wrap your top-level component with <AuthProvider> to make the authentication context
 * available throughout your application. Then, use the `useAuth` hook in any component
 * to access the current user and authentication functions like signing in and signing out.
 */

import React, { createContext, useContext, useState, useEffect } from "react";
import { signOut, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { getHeaders } from "../utils/apiUtils";
import axios from "axios";
import { BASE_URL } from "../config/apiConfig";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userGroup, setUserGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Function to check the current user's authentication status
    const checkUser = async () => {
      try {
        const user = await getCurrentUser();
        const session = await fetchAuthSession();
        setCurrentUser(user);
        // Extract group information from token

        const groups =
          session.tokens.accessToken.payload["cognito:groups"] || [];
        setIsAdmin(groups.includes("Admin"));
        if (groups && groups.length > 0) {
          setUserGroup(groups[0]); // Assuming the user belongs to one group
        }
        // Fetch user data from your backend
        let headers = await getHeaders();
        axios
          .get(`${BASE_URL}tools/general/get-user`, headers)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.error("Error checking authenticated user", error);
      }
      setIsLoading(false);
    };
    // Listen for authentication events
    checkUser();
    Hub.listen("auth", (data) => {
      console.log("auth event", data.payload.event);
      checkUser();
    });
  }, []);

  const signOutUser = async () => {
    await signOut();
    setCurrentUser(null);
  };

  const isAuthenticated = !!currentUser;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        userGroup,
        isLoading,
        isAdmin,
        signOutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
