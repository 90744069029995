import React, { useState, useRef } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";

function ImageWindow({ images }) {
  const [columns, setColumns] = useState(5);
  const [clickedImage, setClickedImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const imageRef = useRef(null);
  const { palette } = useTheme();

  const handleRightClick = (index, event) => {
    event.preventDefault(); // Prevent default context menu
    setClickedImage(index);
    setOpenModal(true);
  };

  const handleClose = () => {
    setClickedImage(null);
    setOpenModal(false);
  };

  const handleColumnsChange = (event) => {
    setColumns(event.target.value);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    imageRef.current.style.backgroundPosition = `${x}% ${y}%`;
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        overflow: "auto",
        border: `1px solid ${palette.primary[200]}`,
        p: 1,
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette.primary[200],
          borderRadius: 1,
          border: `1px solid ${palette.primary[500]}`,
          mb: 1,
          p: 1,
          alignItems: "flex-end",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Select
          value={columns}
          onChange={handleColumnsChange}
          size="small"
          displayEmpty
        >
          {[1, 2, 3, 4, 5, 6].map((col) => (
            <MenuItem key={col} value={col}>
              {col} {col === 1 ? "Column" : "Columns"}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Grid container spacing={1}>
        {images.map((image, index) => (
          <Grid key={index} item xs={12 / columns}>
            <Box
              sx={{
                p: 1,
                borderRadius: 1,
                border: `1px solid ${palette.primary[200]}`,
              }}
            >
              <img
                src={image}
                alt={`image-${index}`}
                style={{ width: "100%", cursor: "context-menu" }} // Indicate right-click is available
                onContextMenu={(event) => handleRightClick(index, event)}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Modal open={openModal} onClose={handleClose}>
        <Box
          ref={imageRef}
          onClick={handleClose}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundImage:
              clickedImage !== null ? `url(${images[clickedImage]})` : "none",
            backgroundRepeat: "no-repeat",
            width: "60vw",
            height: "80vh",
            backgroundSize: "200%",
            transition: "background-size 0.3s ease-in-out",
            "&:hover": {
              backgroundSize: "250%",
            },
          }}
          onMouseMove={handleMouseMove}
        />
      </Modal>
    </Box>
  );
}

export default ImageWindow;
