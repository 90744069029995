import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography, useTheme, Grid } from "@mui/material";
import { getHeaders } from "../../utils/apiUtils";
import { BASE_URL } from "../../config/apiConfig";
import { showAlert } from "../../components/common/userFeedback/CustomAlert";
import { StartButton } from "../../components/ui/buttons/TableButtons";
import { APP_BAR_HEIGHT } from "../../components/layout/AppBar/AppBarComponent";
import { useAmpltiudeEventTrackingContext } from "../../contexts/AmplitudeTrackingContext";
import StyledDataGrid from "../../components/ui/dataViews/StyledDataGrid";
import {
  isValidClientName,
  getClientNameFromDatasetID,
} from "../../config/clientDataViewConfig";
import StyledCard from "../../components/ui/cards/StyledCard";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ViewButton } from "../../components/ui/buttons/TableButtons";

const ClientDashboard = ({ clientNameProvided = null }) => {
  const navigate = useNavigate();
  const { trackEvent } = useAmpltiudeEventTrackingContext();
  const location = useLocation();

  const [jobs, setJobs] = useState([]);
  // State for handling dialog open/close
  const { id } = useParams(); // Retrieve the ID from the URL if present

  const [clientName, setClientName] = useState(clientNameProvided);

  const theme = useTheme();

  const [datasetDetails, setDatasetDetails] = useState({});
  const [catalogueDetails, setCatalogueDetails] = useState({});
  const [datasetCount, setDatasetCount] = useState(null);
  const [catalogueCount, setCatalogueCount] = useState(null);

  const fetchDatasetDetails = async () => {
    const headers = await getHeaders();
    const response = await axios
      .get(`${BASE_URL}tools/admin/datasets/dataset/${id}`, headers)
      .then((res) => {
        setDatasetDetails(res.data.data);
        console.log(
          `res.data.data fetchDatasetDetails: ${JSON.stringify(res.data.data)}`
        );
      })
      .catch((err) => {
        console.log(`ERROR in fetchDatasetDetails: ${err}`);
        showAlert(err);
      });
  };

  const fetchCatalogueCount = async () => {
    const headers = await getHeaders();
    const response = await axios
      .get(`${BASE_URL}tools/admin/catalogue/garments/count`, {
        params: {
          dataset_id: id,
        },
        ...headers,
      })
      .then((res) => {
        setCatalogueCount(res.data.total);
        console.log(
          `res.data.data fetchCatalogueCount: ${JSON.stringify(res.data.total)}`
        );
      })
      .catch((err) => {
        console.log(`ERROR in fetchCatalogueCount: ${err}`);
        showAlert(err);
      });
  };

  const fetchDatasetCount = async () => {
    const headers = await getHeaders();
    const response = await axios
      .get(`${BASE_URL}tools/admin/datasets/garments/count`, {
        params: {
          dataset_id: id,
        },
        ...headers,
      })
      .then((res) => {
        setDatasetCount(res.data.total);
        console.log(
          `res.data.data fetchDatasetCount: ${JSON.stringify(res.data.total)}`
        );
      })
      .catch((err) => {
        console.log(`ERROR in fetchDatasetCount: ${err}`);
        showAlert(err);
      });
  };

  useEffect(() => {
    if (id && id !== "all") {
      fetchDatasetDetails();
      fetchDatasetCount();
      fetchCatalogueCount();
    } else {
      setDatasetDetails({ name: "All Data" });
      setCatalogueDetails({ name: "All Catalogue" });
    }
  }, [id]);

  useEffect(() => {
    if (!clientNameProvided) {
      setClientName(getClientNameFromDatasetID(id));
    }
  }, [clientNameProvided]);

  const columns = [
    { field: "pipeline_job_id", headerName: "id", flex: 1 },
    { field: "name", headerName: "Name", flex: 1, editable: false },
    {
      field: "pipeline_name",
      headerName: "Pipeline Name",
      flex: 1,
      editable: false,
    },
    {
      field: "pipeline_priority",
      headerName: "Priority",
      flex: 1,
      editable: false,
    },
    {
      field: "pipeline_id",
      headerName: "Pipeline ID",
      flex: 1,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <StartButton
          action={() => {
            //todowp finish StartPipelineSession event adn add EndPipelineSession
            //trackEvent("StartPipelineSession", {
            //  toolName: "Aggregation",
            //  garmentId: garmentID,
            //  jobId: 17,
            //  skipped: false
            //});
            navigate(`jobs/${params.row.name}/${params.row.pipeline_job_id}`);
          }}
        />
      ),
    },
    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        width: "100%",
        padding: 2,
        paddingTop: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* UNUSED isValidClientName(clientName) - checking 
      
      <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h1">The client name provided <Typography display="inline" variant="h1" sx={{color: theme.palette.primary[400]}}>'{clientName}'</Typography> is not valid - it must be configured in 'clientDataViewConfig.js'.</Typography>
      </Box></> */}

      <Box
        sx={{
          pb: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{ color: theme.palette.primary[600], mt: 1 }}
        >
          Welcome
        </Typography>
        <Typography variant="h2">{datasetDetails.name ?? "-"}</Typography>
      </Box>

      <Grid
        container
        flexDirection="row"
        sx={{
          //justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
          pt: 2,
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <StyledCard
            titleVariant="h2"
            title={`VIEW DATASET`}
            sxHeaderTitle={{ py: 4 }}
            sx={{ pb: 4 }}
          >
            <Typography
              variant="h3"
              sx={{ color: `${theme.palette.primary.main} !important` }}
            >
              Number of Items
            </Typography>
            <Grid
              container
              justifyContent="space-between"
              flexDirection={"row"}
            >
              <Grid item>
                <Typography
                  variant="h1"
                  sx={{
                    color: `${theme.palette.primary.main} !important`,
                    pt: 1,
                  }}
                >
                  {datasetCount ?? "-"}
                </Typography>
              </Grid>

              <Grid item>
                <ViewButton
                  isBlack
                  action={() =>
                    navigate(
                      `${location.pathname.replace(
                        "dashboard",
                        "garments/dataset"
                      )}`
                    )
                  }
                />
              </Grid>
            </Grid>
          </StyledCard>
        </Grid>

        <Grid item xs={12} sm={6}>
          <StyledCard
            titleVariant="h2"
            title={`VIEW CATALOGUE`}
            sxHeaderTitle={{ py: 4 }}
            sx={{ pb: 4 }}
          >
            <Typography
              variant="h3"
              sx={{ color: `${theme.palette.primary.main} !important` }}
            >
              Number of Items
            </Typography>
            <Grid
              container
              justifyContent="space-between"
              flexDirection={"row"}
            >
              <Grid item>
                <Typography
                  variant="h1"
                  sx={{
                    color: `${theme.palette.primary.main} !important`,
                    pt: 1,
                  }}
                >
                  {catalogueCount ?? "-"}
                </Typography>
              </Grid>

              <Grid item>
                <ViewButton
                  isBlack
                  action={() =>
                    navigate(
                      `${location.pathname.replace(
                        "dashboard",
                        "garments/catalogue"
                      )}`
                    )
                  }
                />
              </Grid>
            </Grid>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientDashboard;
