import React, { useEffect, useState } from "react";
import {  gridClasses } from "@mui/x-data-grid-pro";
import axios from "axios";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import AddToGroupDialog from "../../../components/features/UserGroups/AddToGroupDialog";
import CustomToolbar from "../../../components/common/toolbars/UserGridCustomToolbar";
import GroupConfigurationNavigationWidget from "../../../components/navigation/widgets/GroupConfigurationNavigationWidget";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import StyledDataGrid from "../../../components/ui/dataViews/StyledDataGrid";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {compareNamesAsc} from "../../../utils/sharedFunctions"
import UserDashboardToolbar from "../../../components/common/toolbars/UserDashboardToolbar";

function UserDashboard() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const theme = useTheme();

  // State for handling dialog open/close
  const [openDialog, setOpenDialog] = useState(false);
  const handleRowSelection = (selectionModel) => {
    setSelectedUsers(selectionModel);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/admin/combined-userdata`, headers)
        .then((res) => {
          // Reset form fields (optional)

          const sortedData = res.data.data
          sortedData.sort(compareNamesAsc)

          setUsers(sortedData);
        })
        .catch((err) => {
          alert(err.response.data);
        });
    };

    fetchUsers();
  }, []);

  const columns = [
    { field: "sub", headerName: "Sub", flex: 1 },
    { field: "sql_id", headerName: "User ID", flex: 1, editable: false },
    {
      field: "name",
      headerName: "User Name",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      editable: false,
    },
    {
      field: "is_human",
      headerName: "Human",
      flex: 1,
      type: "boolean",
      editable: false,
    },
    {
      field: "email_verified",
      headerName: "Email Verified",
      type: "boolean",
      flex: 1,
      editable: false,
    },
    {
      field: "Enabled",
      headerName: "Access Enabled",
      type: "boolean",
      flex: 1,
      editable: false,
    },
    {
      field: "User Status",
      headerName: "User Status",
      flex: 1,
      editable: false,
    },
    {
      field: "userGroups",
      headerName: "AWS User Groups",
      flex: 1,
      editable: false,
    },

    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        width: "100%",
        padding: 2,
        paddingTop: 2,
        display: "flex",
        flexDirection: "column",
        [`.${gridClasses.cell}.red`]: {
          backgroundColor: theme.palette.error.main, // Light amber background
        },
        [`.${gridClasses.cell}.amber`]: {
          backgroundColor: theme.palette.warning.main, // Light red background
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h2">User Dashboard</Typography>
      </Box>
      <StyledDataGrid
        rows={users}
        columns={columns}
        getRowId={(row) => row.sql_id}
        pageSize={5}
        checkboxSelection
        onRowSelectionModelChange={handleRowSelection}
        selectionModel={selectedUsers}
        components={{
          Toolbar: UserDashboardToolbar,
        }}
        componentsProps={{
          toolbar: {
            onAddToGroupClick: handleOpenDialog,
          },
        }}
        getCellClassName={(params) => {
          if (params.value == null && params.field !== "is_human") {
            return "red";
          }
          if (params.field === "is_human" && params.value === false) {
            return "amber";
          }
        }}
      />
      <AddToGroupDialog
        open={openDialog}
        onClose={handleCloseDialog}
        selectedUsers={selectedUsers}
      />
    </Box>
  );
}

export default UserDashboard;
