import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { showAlert } from "../../common/userFeedback/CustomAlert";

function CopyToDatasetDialog({ open, onClose, garments }) {
  const [selectedDataset, setSelectedDataset] = useState("");
  const [datasets, setDatasets] = useState([]);

  const handleDatasetChange = (event) => {
    setSelectedDataset(event.target.value);
  };
  const fetchDatasets = async () => {
    try {
      const headers = await getHeaders();
      const response = await axios.get(
        `${BASE_URL}tools/admin/datasets/listall`,
        headers
      );
      setDatasets(response.data.data);
    } catch (err) {
      showAlert(err);
    }
  };

  const handleCopy = async (datasetId) => {
    // Implement your copy logic here
    console.log(`Copying ${garments.length} garments to dataset ${datasetId}`);
    try {
      // Implement your copy logic here
      const headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/admin/datasets/add-to-dataset/${selectedDataset}`,
        {
          garment_ids: garments,
        },
        headers
      );
      showAlert(response);
    } catch (err) {
      showAlert(err);
    }
    onClose();
  };
  useEffect(() => {
    fetchDatasets();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Copy Garments to Dataset</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{mb: 1}}>
          You have selected {garments.length} garments. Choose a dataset to copy
          them to.
        </DialogContentText>
        <TextField
          select
          label="Select Dataset"
          value={selectedDataset}
          onChange={handleDatasetChange}
          fullWidth
        >
          {datasets.map((dataset) => (
            <MenuItem key={dataset.id} value={dataset.id}>
              {dataset.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCopy} disabled={!selectedDataset}>
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CopyToDatasetDialog;
