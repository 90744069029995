import React, { useState, useCallback } from "react";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import StyledImageCarousel from "../../../components/ui/StyledImageCarousel";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import PromptSettingsDialog from "./PromptSettingsDialog";
import SettingsIcon from "@mui/icons-material/Settings";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
// Recursive component to render any value from the JSON
const RenderValue = ({ value }) => {
  if (value && typeof value === "object" && !Array.isArray(value)) {
    // It's a nested object
    return <RenderObject data={value} />;
  } else if (Array.isArray(value)) {
    // It's an array, loop through each element
    return (
      <Table size="small" sx={{ ml: 2, mt: 1 }}>
        <TableBody>
          {value.map((item, index) => (
            <TableRow key={index}>
              <TableCell variant="body">
                <RenderValue value={item} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  } else {
    // It's a primitive value (string, number, boolean, null)
    const displayValue = value === null ? "null" : value.toString();
    return <Typography variant="body2">{displayValue}</Typography>;
  }
};

// Recursive component to render an object as a table
const RenderObject = ({ data }) => {
  const entries = Object.entries(data);

  return (
    <Table size="small" sx={{ ml: 2, border: "1px solid #ddd" }}>
      <TableBody>
        {entries.map(([key, value]) => (
          <TableRow key={key}>
            <TableCell variant="head">
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                {key}
              </Typography>
            </TableCell>
            <TableCell variant="body">
              <RenderValue value={value} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
const AutomatedTagging = () => {
  const [files, setFiles] = useState([]);
  const [jsonData, setJsonData] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("hello...");
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [promptSettings, setPromptSettings] = useState({
    promptTemplate: `Analyse the following images of garment tags and provide a valid JSON object describing the single item that these tags represent.
    The JSON should be an object, where each element corresponds to a value, in the same order. Each object should describe the garment using the taxonomy from the reference data of values. Include all of these fields in every case, just list as 'not applicable' or if there is no value extracted.
    Columns/Values Fields
    For these fields, only return the predetermined values that are listed in the respective columns for the fields:
    ‘Brand’ return values from the predetermined brand lists. Ensure that sub brands from the values are considered. E.g. instead of Ralph Lauren, Polo Ralph Lauren.
    ‘Season’ should return only SS25, AW24, SS24, AW23, SS23, AW22, Beauty, VINTAGE - you may use the date on the tag to assign a season suggestion FW23, would correspond to AW23 for example.
    ‘collection’ (collection should only return CRUISE, MAIN COLLECTION, PRE-COLLECTION, RESORT).
    ‘category tree' (list the tree from the values considering what is most closely represented on the tag image e.g. Unisex > Clothing > Sweaters & Knitwear, Kids > Boys Shoes > Flip Flops, Kids > Girls Accessories > Hair Accessories.
    'shipping box' consider which box best represents the corresponding type and include all of the text not just the number from the predefined taxonomy:
    13 - Large Boots (70x40x15)
    14 - Large Coats/Bags (48.8x42.6x19.4)
    15 - Accessories/Jewelry (22,5x13x6,5)
    16 - Env/Box T-shirts (36x26x4,1)
    17 - Env/Box Clothing (37x27x6,2)
    20 - Env/Box Kids/Activewear (29x20x3,4)
    21 - Large Accessories (23,5x17x11)
    22 - Env/Box Large Clothing (37x27x8,3)
    3 - Shoes (33,4x22x12,5)
    30 - XL Coats/Bags (58.5x46.1x25.5)
    4 - Large Shoes (35,9x23,9x13,4)
    5 - Boots/Bags (39,3x27,4x14,9)
    6 - Double Shoes (34,5x43,1x15,5)
    7 - Coats (61.5x37.4x13.4)
    Other (38x7x28)
    ‘size scale' - this is brand specific and should only consider values from the predefined taxonomy e.g. DONSJE > Kids > Baby Girl Clothing : Donsje Clothing Baby Age (Months): Donsje One Size. This will usually correspond to the brand, so search for the brand itself to find the size scale from the list and extract the size from the tag.
    ‘made in country’ should consider the country and country code listed in the values column e.g. China (CN)
    ‘washing instructions’ washing instructions should only consider values from the predefined taxonomy i.e. HAND WASH, MACHINE WASH, DO NOT DRY CLEAN, SPECIALIST, CLEANING, DRY CLEAN ONLY, READ MANUFACTURER GUIDELINES. You can include multiple values if displayed. They will usually be symbols but will sometimes be written. You can include DRY CLEAN ONLY as well as HAND WASH for example.
    ‘main colour’ only include colours from the predefined taxonomy.ORANGE, PURPLE, GOLD, WHITE, YELLOW, NEUTRALS, SILVER, BLUE, RED, MULTICOLOUR, BLACK, GREY, GREEN, BROWN, PINK, METALLIC E.g. if light black is listed default to black.
    ‘secondary colour’ Only provide values from the predefined taxonomy and if there is no secondary colour list as 'not applicable'. ORANGE, PURPLE, GOLD, WHITE, YELLOW, NEUTRALS, SILVER, BLUE, RED, MULTICOLOUR, BLACK, GREY, GREEN, BROWN, PINK, METALLIC.
    ‘colour type’ only include the predefined taxonomy PATTERNED, ANIMAL or MULTI-COLOUR and return 'not applicable' if this isn’t stated.
    Material: Surface: Indicate the section (e.g., outer, lining, sole). Return 'not applicable' for products with no material surface specified on the label. If there is only one surface, then return 'not applicable'
    Material: Composition: Use the predefined material taxonomy (e.g., "Artificial > Artificial Leather").
    Material: Percentage: Specify the percentage. Ensure the percentage always adds up to 100%
    If multiple materials are listed, provide as material 2, material 3, etc. For single-material products, return 'not applicable'. If they are part of the same surface, state not applicable for material surface but separate into different rows.
    For all of these, only return the structured, predetermined values. Even if other values might exist.
    2. Unstructured/No predetermined values:
    For these values do not use the ‘values’ columns and return any string of alphanumeric identifiers.
    'Product ID' (can have any value) but may follow something like 'art' or 'SKU’ this is not the RN number and will usually be a long number. Only include the code, not the letters SKU or ART.
    ’Brand Colour ID' (can have any value but may be listed as to col:). The brand's identifier for the colour of each product, as it's written on the tag or the purchase order.In cases where brands provide both a code and a name, provide the code first then the name (e.g. ‘030 Black/Yellow’). The colour code may be connected to a product ID with a dash. this may also be listed near the words colour or colore in different languages. Return 'not found' if no colour ID can be extracted.
    These will be numerical strings. If there is no obvious brand or colour ID, leave these fields as 'not found’.`,
    temperature: 0.4,
    useContext: true,
    model: { name: "gpt-4o-mini", id: "gpt-4o-mini" },
  });

  const handleOpen = () => setSettingsDialogOpen(true);
  const handleClose = () => setSettingsDialogOpen(false);
  const handleSave = ({ promptTemplate, temperature, useContext, model }) => {
    setPromptSettings({ promptTemplate, temperature, useContext, model });
    setSettingsDialogOpen(false);
  };
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (files.length === 0) {
      alert("Please select at least one image");
      return;
    }

    const formData = new FormData();
    for (const file of files) {
      formData.append("images", file); // Append each file under the same field name
    }
    const { promptTemplate, temperature, useContext, model } = promptSettings;
    formData.append("prompt_template", promptTemplate);
    formData.append("temperature", temperature); // convert to string if needed
    formData.append("use_context", useContext); // convert boolean to string if needed
    formData.append("model", model.id);
    const headers = await getHeaders(); // Your custom headers if any

    axios
      .post(`${BASE_URL}tools/general/upload-and-analyse`, formData, headers)
      .then((res) => {
        setImageUrls(res.data.images);
        setJsonData(res.data.analysis);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        showAlert(err);
        setLoading(false);
      });
  };

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          mb: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            flex: 1,
          }}
          gutterBottom
        >
          Automated Tagging
        </Typography>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
        <IconButton onClick={handleOpen}>
          <SettingsIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: 1 }}>
          {imageUrls.length == 0 ? (
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #ccc",
                p: 4,
                textAlign: "center",
                cursor: "pointer",
                bgcolor: isDragActive ? "#f0f0f0" : "transparent",
                mb: 2,
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography>Drop the files here...</Typography>
              ) : (
                <Typography>
                  Drag & drop some files here, or click to select files
                </Typography>
              )}
            </Box>
          ) : (
            <Box sx={{ height: "60vh" }}>
              <StyledImageCarousel srcs={imageUrls} />
            </Box>
          )}
        </Box>
        <Box sx={{ flex: 2 }}>
          {jsonData && <RenderObject data={jsonData} />}
        </Box>

        {/* Fullscreen loading overlay */}
        <Backdrop
          open={loading}
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: "column",
          }}
        >
          <CircularProgress color="inherit" sx={{ mb: 2 }} />
          <Typography variant="h6">{currentMessage}</Typography>
        </Backdrop>
        <PromptSettingsDialog
          open={settingsDialogOpen}
          initialPrompt={promptSettings.promptTemplate}
          initialTemperature={promptSettings.temperature}
          initialUseContext={promptSettings.useContext}
          initialModel={promptSettings.model}
          onClose={handleClose}
          onSave={handleSave}
        />
      </Box>
    </Box>
  );
};

export default AutomatedTagging;
