import { Box, Typography } from "@mui/material";
import React from "react";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import AttributeGroupDropdown from "../../forms/Dropdowns/AttributeGroupDropdown";

function DefineGarmentSection(props) {
  const {
    name,
    setName,
    targetQuantity,
    setTargetQuantity,
    priority,
    setPriority,
    filters,
    setFilters,
  } = usePipelineContext();
  const handleFilterChange = (type, newFilters) => {
    setFilters({ ...filters, [type]: newFilters });
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Typography variant="h3">Build-a-Garment</Typography>
      <AttributeGroupDropdown
        attributeType={"IMAGE_CLASS"}
        setAttributeGroup={handleFilterChange}
        attributeGroup={filters["IMAGE_CLASS"]}
      />
      <AttributeGroupDropdown
        attributeType={"TYPE"}
        setAttributeGroup={handleFilterChange}
        attributeGroup={filters["TYPE"]}
      />
      <AttributeGroupDropdown
        attributeType={"STYLE"}
        setAttributeGroup={handleFilterChange}
        attributeGroup={filters["STYLE"]}
      />
    </Box>
  );
}

export default DefineGarmentSection;
