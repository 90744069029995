/*
  Gets a different Ampltiude config for each environment
*/
export const getEventTrackingOptions = (trackingProviderName) => {
  switch (trackingProviderName.toLowerCase()){
    case "amplitude":
    default:
      switch (process.env.REACT_APP_NODE_ENV) {
        case "development":
          return eventTrackingOptions_Amplitude_DEV;
        case "production":
          return eventTrackingOptions_Amplitude_PRD;
        case "staging":
          return eventTrackingOptions_Amplitude_PRD;
        default:
          return eventTrackingOptions_Amplitude_PRD;
      }
  }
}



/*
  Amplitude
*/
export const eventTrackingAPIKey_Amplitude = process.env.REACT_APP_EVENT_TRACKING_AMPLITUDE_API_KEY;
export const eventTrackingSecretKey_Amplitude = process.env.REACT_APP_EVENT_TRACKING_AMPLITUDE_SECRET_KEY;

export const eventTrackingOptions_Amplitude_DEV = {
  defaultOptions: {
    enableTracking: true,
    enableAutoTracking: false,
    enableSessionTracking: false,
  },
  apiOptions: {
    // https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2
    defaultTracking: {
        /*
        Amplitude tracks marketing attribution to identify your user's traffic source using the UTM, referrer and click ID parameters.
        UTM (Urchin Traffic Monitor) parameters are useful for analyzing the effectiveness of different ad campaigns and referring sites. UTM parameters are case-sensitive, so they're treated as different values when the capitalization varies.
        */
      attribution: true,
      pageViews: true,
      sessions: false, //TODOWP
      formInteractions: true,
      fileDownloads: false,
    }
  },
  sessionReplayOptions: {
    sampleRate: 1,
    //privacyConfig: {
    //    blockSelector: ['.ignoreClass', '#ignoreId']
    //}
  }
};

export const eventTrackingOptions_Amplitude_PRD = {
  ...eventTrackingOptions_Amplitude_DEV,
  ...{
    sessionReplayOptions: {
      sampleRate: 0.1 //10% of sessions are replays
    }
  }
}