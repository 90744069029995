// src/config/apiConfig.js


export const BASE_IMAGE_URL =
  "https://scraped-garment-images.s3.eu-west-2.amazonaws.com/";

// Use localhost for development
const devUrl = "http://localhost:8080/api/v1/";

// Staging (DEV -> STAGING -> PRD)
const stagingURL =
  "https://yr1yvtzabi.execute-api.eu-west-2.amazonaws.com/bridge-staging/api/v1/";

// Set your production URL here
const prodUrl =
  "https://yr1yvtzabi.execute-api.eu-west-2.amazonaws.com/bridge/api/v1/";

const BASE_URL_METHOD = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "development":
      return devUrl;
    case "production":
      return prodUrl;
    case "staging":
      return stagingURL;
    default:
      return prodUrl;
  }
};
export const BASE_URL = BASE_URL_METHOD();
