import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

function CustomModal({
  showModal,
  title,
  body,
  onDismissTitle = "Return",
  onDismiss,
  onConfirmTitle = "Confirm",
  onConfirm = null,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [componentRendered, setComponentRendered] = useState(false);

  useEffect(() => {
    if (showModal && componentRendered) {
      // Show the dialog when waitToShowModal is complete
      setDialogOpen(true);
    } else {
      console.log("setComponentRendered");
      setComponentRendered(true);
    }
  }, [showModal]);

  const handlePreventClose = (event) => {
    event.preventDefault();
  };

  //if onConfirm is not supplied (is null) then show first set of dialogActions, otherwise show the second set (both buttons)
  const dialogActions = !onConfirm ? (
    <>
      <Button
        onClick={() => {
          onDismiss();
          setDialogOpen(false);
        }}
        color="primary"
        autoFocus
      >
        {onDismissTitle}
      </Button>
    </>
  ) : (
    <Box
      sx={{ justifyContent: "space-between", display: "flex", width: "100%" }}
    >
      <Button
        onClick={() => {
          onDismiss();
          setDialogOpen(false);
        }}
        color="primary"
        autoFocus
      >
        {onDismissTitle}
      </Button>

      <Button
        onClick={() => {
          onConfirm();
          setDialogOpen(false);
        }}
        color="primary"
        sx={{ bgcolor: "black" }}
        autoFocus
      >
        <Typography color="white" sx={{ "&:hover": { color: "black" } }}>
          {onConfirmTitle}
        </Typography>
      </Button>
    </Box>
  );

  return (
    <>
      <Dialog
        open={dialogOpen}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
    </>
  );
}

export default CustomModal;
