import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
  Checkbox,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { getHeaders } from "../../../utils/apiUtils";
import {
  attributeScreenConfig,
  attributeTypes,
} from "../../../config/attributeTypes";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import ToggleableBox from "../../layout/Interactive/ToggleableBox";

function AttributeGroupDropdown({
  attributeType,
  attributeGroup,
  setAttributeGroup,
}) {
  const ChipLabelWithIcons = ({ name, isRequired, isCarryForward }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      {isRequired ? (
        <CheckCircleOutlineIcon
          sx={{ color: palette.primary[700], fontSize: "20px" }}
        />
      ) : (
        false
      )}
      {isCarryForward ? (
        <KeyboardDoubleArrowLeftIcon
          sx={{ color: palette.primary[700], fontSize: "20px" }}
        />
      ) : (
        false
      )}
      <Typography variant="body2" component="span">
        {name}
      </Typography>
    </Box>
  );
  const { palette } = useTheme();
  const [attributeGroups, setAttributeGroups] = useState([]);

  useEffect(() => {
    const fetchAttributeGroups = async () => {
      let headers = await getHeaders();
      axios
        .get(
          `${BASE_URL}tools/admin/attribute-groups/${attributeType}?extended=true`,
          headers
        )
        .then((res) => {
          console.log("attribute groups", res.data.data);
          // Reset form fields (optional)
          setAttributeGroups(res.data.data);
        })
        .catch((err) => {
          alert(
            `Failed to fetch ${attributeScreenConfig[attributeType].name} groups`
          );
        });
    };

    if (attributeType) {
      fetchAttributeGroups();
    }
  }, [attributeType]);

  const handleAddAttributeGroup = (_, newValue) => {
    setAttributeGroup(attributeType, newValue);
  };

  return (
    <Box
      sx={{
        mt: 1,
        p: 1,
        borderRadius: 1,
        overflow: "auto",
      }}
    >
      <Typography variant="h8">
        {attributeScreenConfig[attributeType].name} GROUP
      </Typography>
      <Autocomplete
        disablePortal
        sx={{
          mt: 1,
        }}
        value={attributeGroup}
        options={attributeGroups}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.group_name || ""}
        onChange={handleAddAttributeGroup}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Select ${attributeScreenConfig[attributeType]?.name} group`}
          />
        )}
      />
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1 }}>
        {attributeGroup?.attributes?.map((attribute) => (
          <Chip
            key={attribute.id}
            label={
              <ChipLabelWithIcons
                name={
                  attribute.name ? attribute.name : attribute.attribute_name
                }
                isRequired={attribute.required}
                isCarryForward={attribute.carry_forward}
              />
            }
            sx={{
              mr: 1,
              mb: 1,
              backgroundColor: palette.primary[20],
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

export default AttributeGroupDropdown;
