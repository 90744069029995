import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { showAlert } from "../../common/userFeedback/CustomAlert";

function OpenAiModelsDropdown({ setModel, model, disabled, sx }) {
  const [models, setModels] = useState([]);
  async function getModels() {
    const headers = await getHeaders();
    try {
      const res = await axios.get(`${BASE_URL}tools/general/models`, headers);

      const results = res.data.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setModels(results);
    } catch (error) {
      console.log(error);
      showAlert(error);
    }
  }
  useEffect(() => {
    getModels();
  }, []);

  return (
    <Autocomplete
      options={models}
      getOptionLabel={(option) => option.name}
      value={model}
      onChange={(event, newValue) => setModel(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select model"
          placeholder="Models"
        />
      )}
      sx={{
        minWidth: 200,
        ...sx,
      }}
    />
  );
}

export default OpenAiModelsDropdown;
