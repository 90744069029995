import React, { useEffect, useState } from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import {
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getHeaders } from "../../../utils/apiUtils";
import axios from "axios";
import { showAlert } from "../userFeedback/CustomAlert";
import { BASE_URL } from "../../../config/apiConfig";

function CopyGarmentsToolbar({ onCopy }) {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Tooltip title="Copy Selected Garments">
          <IconButton onClick={onCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </GridToolbarContainer>
  );
}

export default CopyGarmentsToolbar;
