import { Box, Typography, CircularProgress, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import { BASE_URL } from "../../../config/apiConfig";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import { getHeaders } from "../../../utils/apiUtils";

function PipelineSummarySubScreen({ handleSubmit }) {
  const { filters } = usePipelineContext();
  const [garmentCount, setGarmentCount] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);
  useEffect(() => {
    fetchEstimation();
  }, [filters]);

  const fetchEstimation = async () => {
    setDataLoading(true);
    let headers = await getHeaders();
    // map the filters and get the ids of the selected attributes
    const submissionData = {};
    for (const key in filters) {
      submissionData[key] = filters[key].attributes
        ? filters[key].attributes
        : [];
    }

    try {
      const response = await axios.post(
        `${BASE_URL}tools/pipelines/estimate-qualified-garments`,
        {
          filters: submissionData,
        },
        headers
      );
      const { count } = response.data.data;
      setDataLoading(false);
      setGarmentCount(count);
    } catch (error) {
      showAlert(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
      }}
    >
      <Typography variant="h3" component="h3">
        Estimated Qualifying Garment Count:
        {dataLoading ? (
          <CircularProgress size={24} sx={{ marginLeft: 2 }} />
        ) : (
          garmentCount
        )}
      </Typography>
      <Button variant="contained" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
}

export default PipelineSummarySubScreen;
