// src/router/MainRouter.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import CreateDatasetScreen from "../screens/admin/datasets/CreateDatasetScreen";
import DatasetDashboard from "../screens/admin/datasets/DatasetDashboard";
import DatasetPivotTable from "../screens/admin/datasets/DatasetPivotTable";
import GarmentsDatasetDashboard from "../screens/admin/datasets/GarmentsDatasetDashboard";
import GarmentsCatalogueDashboard from "../screens/admin/datasets/GarmentsCatalogueDashboard";
import ClientDashboard from "../screens/default/ClientDashboard";

const DatasetRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DatasetDashboard />} />
      <Route path="create/:id?" element={<CreateDatasetScreen />} />
      <Route path="garments/dataset/:id?" element={<GarmentsDatasetDashboard />} />
      <Route path="garments/catalogue/:id?" element={<GarmentsCatalogueDashboard />} />
      <Route path="garments/analytics/:id?" element={<DatasetPivotTable />} />
      <Route path="dashboard/:id?" element={<ClientDashboard />} />
    </Routes>
  );
};

export default DatasetRoutes;
