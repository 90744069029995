import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Slider,
} from "@mui/material";
import OpenAiModelsDropdown from "../../../components/forms/Dropdowns/OpenAiModelsDropdown";

const PromptSettingsDialog = ({
  open,
  initialPrompt = "",
  initialTemperature = 0.7,
  initialUseContext = false,
  initialModel = { name: "gpt-3.5-turbo", id: "gpt-3.5-turbo" }, // If you have a default model, set it here
  onClose,
  onSave,
}) => {
  const [promptTemplate, setPromptTemplate] = useState(initialPrompt);
  const [useContext, setUseContext] = useState(initialUseContext);
  const [temperature, setTemperature] = useState(initialTemperature);
  const [model, setModel] = useState(initialModel);

  const handleSave = () => {
    onSave({ promptTemplate, temperature, useContext, model });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Prompt Settings</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Adjust the prompt template and parameters for the GPT model.
        </Typography>

        <TextField
          label="Prompt Template"
          multiline
          fullWidth
          minRows={4}
          value={promptTemplate}
          onChange={(e) => setPromptTemplate(e.target.value)}
          sx={{ mt: 2 }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={useContext}
              onChange={(e) => setUseContext(e.target.checked)}
            />
          }
          label="Include Taxonomy"
          sx={{ mt: 2 }}
        />

        <Typography variant="body1" sx={{ mt: 2 }}>
          Temperature: {temperature.toFixed(2)}
        </Typography>
        <Slider
          value={temperature}
          onChange={(e, val) => setTemperature(val)}
          step={0.01}
          min={0}
          max={1}
          valueLabelDisplay="auto"
          sx={{ mt: 2 }}
        />

        <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
          Select OpenAI Model
        </Typography>
        <OpenAiModelsDropdown model={model} setModel={setModel} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptSettingsDialog;
