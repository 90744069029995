import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import JobConfigurationDialog from "../../../components/features/Pipelines/JobConfigurationDialog";
import DraggableList from "../../../components/layout/Interactive/DraggableList";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import { defaultJobs } from "../../../config/pipelineConfig";
function JobConfigurationSubScreen(props) {
  const { setOrderedJobs, orderedJobs } = usePipelineContext();
  const [activeJob, setActiveJob] = useState(null);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Typography variant="h2" gutterBottom>
          Job Configuration
        </Typography>
        <DraggableList
          items={orderedJobs}
          setItems={setOrderedJobs}
          onEditClick={(item) => {
            setActiveJob(item);
          }}
          menuItems={defaultJobs}
          styleMap={{
            job: {
              backgroundColor: "#86d3db", // Light grey background
            },
            qa: {
              backgroundColor: "#89db86", // Light grey background
            },
            function: {
              backgroundColor: "#dba786", // Light grey background
            },
          }}
        />
        <JobConfigurationDialog
          activeJob={activeJob}
          setActiveJob={setActiveJob}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Box>
    </Container>
  );
}

export default JobConfigurationSubScreen;
