import React, { useEffect, useState } from "react";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  Button,
  DialogActions,
} from "@mui/material";
import UserMultiSelect from "../../forms/Dropdowns/UserMultiSelect";
import UserTable from "../../layout/BasicUserTable";
import UserGroupMultiSelect from "../../forms/Dropdowns/UserGroupMultiSelect";
function QAConfigurationDialog({
  activeJob,
  setActiveJob,
  unsavedChanges,
  setUnsavedChanges,
}) {
  const { orderedJobs, setOrderedJobs } = usePipelineContext();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [currentGraduationConfidence, setCurrentGraduationConfidence] =
    useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    if (activeJob) {
      setCurrentGraduationConfidence(activeJob.graduationConfidence);
      setSelectedUsers(activeJob.users);
    }
  }, [activeJob]);

  const handleClose = () => {
    setCurrentGraduationConfidence(0);
    setError("");
    setActiveJob(null);
    setSelectedUsers([]);
  };

  const handleSubmit = () => {
    if (currentGraduationConfidence < 0 || currentGraduationConfidence > 1) {
      setError("Graduation confidence must be between 0 and 1");
      return;
    }
    const newOrderedJobs = orderedJobs.map((job) => {
      if (job.id === activeJob.id) {
        return {
          ...job,
          graduationConfidence: currentGraduationConfidence,
          users: selectedUsers,
        };
      }
      return job;
    });
    setOrderedJobs(newOrderedJobs);
    handleClose();
  };

  return (
    <Dialog open={activeJob}>
      <DialogTitle variant="h3">Configure {activeJob?.name}</DialogTitle>
      <DialogContent>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <TextField
              label="Graduation Confidence"
              value={currentGraduationConfidence}
              onChange={(e) => {
                const value = Math.max(0, Math.min(1, Number(e.target.value))); // Ensures value is between 0 and 1
                setUnsavedChanges(true);
                setCurrentGraduationConfidence(value);
              }}
              type="number"
              inputProps={{ min: 0, max: 1, step: 0.01 }} // Allows step of 0.01 for fine-grained control
              variant="outlined"
              margin="normal"
              sx={{
                mr: 2,
              }}
            />
          </Box>
          <UserMultiSelect setSelectedUsers={setSelectedUsers} />
          <UserGroupMultiSelect setSelectedUsers={setSelectedUsers} />
          <UserTable users={selectedUsers} setUsers={setSelectedUsers} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
      <Typography variant="error" color="error">
        {error}
      </Typography>
    </Dialog>
  );
}
function JobConfigurationDialog({
  activeJob,
  setActiveJob,
  unsavedChanges,
  setUnsavedChanges,
}) {
  const { orderedJobs, setOrderedJobs } = usePipelineContext();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentGraduationConfidence, setCurrentGraduationConfidence] =
    useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    if (activeJob) {
      setCurrentGraduationConfidence(activeJob.graduationConfidence);
      setSelectedUsers(activeJob.users);
    }
  }, [activeJob]);

  const handleClose = () => {
    setCurrentGraduationConfidence(0);
    setError("");
    setActiveJob(null);
    setSelectedUsers([]);
  };

  const handleSubmit = () => {
    if (currentGraduationConfidence < 0 || currentGraduationConfidence > 1) {
      setError("Graduation confidence must be between 0 and 1");
      return;
    }
    const newOrderedJobs = orderedJobs.map((job) => {
      if (job.id === activeJob.id) {
        return {
          ...job,
          graduationConfidence: currentGraduationConfidence,
          users: selectedUsers,
        };
      }
      return job;
    });
    setOrderedJobs(newOrderedJobs);
    handleClose();
  };

  return (
    <Dialog open={activeJob}>
      <DialogTitle variant="h3">Configure {activeJob?.name}</DialogTitle>
      <DialogContent>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <TextField
              label="Graduation Confidence"
              value={currentGraduationConfidence}
              onChange={(e) => {
                const value = Math.max(0, Math.min(1, Number(e.target.value))); // Ensures value is between 0 and 1
                setUnsavedChanges(true);
                setCurrentGraduationConfidence(value);
              }}
              type="number"
              inputProps={{ min: 0, max: 1, step: 0.01 }} // Allows step of 0.01 for fine-grained control
              variant="outlined"
              margin="normal"
              sx={{
                mr: 2,
              }}
            />
          </Box>
          <UserMultiSelect setSelectedUsers={setSelectedUsers} />
          <UserGroupMultiSelect setSelectedUsers={setSelectedUsers} />
          <UserTable users={selectedUsers} setUsers={setSelectedUsers} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
      <Typography variant="error" color="error">
        {error}
      </Typography>
    </Dialog>
  );
}
function WebHookConfigurationDialog({ activeJob, setActiveJob }) {
  const { orderedJobs, setOrderedJobs } = usePipelineContext();

  const [error, setError] = useState("");
  const [functionURl, setFunctionURL] = useState("");

  useEffect(() => {
    if (activeJob) {
    }
  }, [activeJob]);

  const handleClose = () => {
    setError("");
    setActiveJob(null);
  };

  const handleSubmit = () => {
    const newOrderedJobs = orderedJobs.map((job) => {
      if (job.id === activeJob.id) {
        return {
          ...job,
        };
      }
      return job;
    });
    setOrderedJobs(newOrderedJobs);
    handleClose();
  };

  return (
    <Dialog open={activeJob}>
      <DialogTitle variant="h3">Configure {activeJob?.name}</DialogTitle>
      <DialogContent>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          ></Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
      <Typography variant="error" color="error">
        {error}
      </Typography>
    </Dialog>
  );
}

function DialogMapper({
  activeJob,
  setActiveJob,
  unsavedChanges,
  setUnsavedChanges,
}) {
  console.log(activeJob);
  if (activeJob?.mapping_value === "qa") {
    return (
      <QAConfigurationDialog
        activeJob={activeJob}
        setActiveJob={setActiveJob}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
    );
  } else if (activeJob?.mapping_value === "job") {
    return (
      <JobConfigurationDialog
        activeJob={activeJob}
        setActiveJob={setActiveJob}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
    );
  } else if (activeJob?.mapping_value === "function") {
    return (
      <WebHookConfigurationDialog
        activeJob={activeJob}
        setActiveJob={setActiveJob}
      />
    );
  }
}

export default DialogMapper;
