// src/routes/AttributeManagementRoutes.js
import React, { useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import {
  attributeTypes,
  attributeScreenConfig,
} from "../config/attributeTypes";

import {
  usePipelineContext,
  PipelineProvider,
} from "../contexts/PipelineContext";
import CreatePipelineScreen from "../screens/admin/pipelines/CreatePipelineScreen";
import PipelineDashboard from "../screens/admin/pipelines/PipelineDashboard";

const PipelineRoutes = () => {
  return (
    <Routes>
      <Route
        path="/create/:id?"
        element={
          <PipelineProvider>
            <CreatePipelineScreen />
          </PipelineProvider>
        }
      />
      <Route path="/" element={<PipelineDashboard />} />
    </Routes>
  );
};

const PipelineRoutesMain = () => {
  return <PipelineRoutes />;
};

export default PipelineRoutesMain;
