// src/config/attributeTypes.js

export const attributeTypes = {
  image_class: "IMAGE_CLASS",
  brand: "BRAND",
  type: "TYPE",
  style: "STYLE",
};

export const attributeScreenConfig = {
  IMAGE_CLASS: {
    name: "Image Tag",
  },
  BRAND: {
    name: "Brand",
  },
  TYPE: {
    name: "Type",
  },
  STYLE: {
    name: "Style",
  },
};
