// src/screens/auth/LoginScreen.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  signIn,
  confirmSignIn,
  confirmResetPassword,
  resetPassword,
  signOut,
} from "aws-amplify/auth";

import {
  TextField,
  Button,
  CircularProgress,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import myImage from "../../assets/images/Shoes.png"; // Directly import the image
import { useAmpltiudeEventTrackingContext } from "../../contexts/AmplitudeTrackingContext"

const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [screenState, setScreenState] = useState("login");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    setCode("");
    setNewPassword("");
  }, [screenState]);

  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const loginInputTheme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              marginTop: "0.25rem !important",
            },
            'input:focus::placeholder': {
              color: "transparent", //TODO WP we may want to remove this - hides placeholder text on focus
              margin: 0,
            },
            '& label': {
              variant: "subtitle",
              position: "relative",
              marginBottom: -8,
              marginTop: 6,
              zIndex: -999,
            },
            margin: 0,
          },
        },
      },
      MuiInputLabel: {
        marginTop: 2
      },
      //Removes the border cutoff issue after moving input label
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            legend: {
              display: "none",
            },
          },
          root: {
          }
        }
      },
    }
  })

  const handleLogin = async () => {
    let loginSuccess = false;
    // Track Amplitude Event: "Login"
    trackEvent("Login", {
      success: null
    });

    setLoading(true);
    try {
      const { nextStep } = await signIn({ username, password });
      if (
        nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setScreenState("newPassword");
        // Optionally, handle any additional steps if needed
      }
      if (nextStep?.signInStep === "RESET_PASSWORD") {
        // Handle custom challenge
        setScreenState("resetPassword");
      }
      setError("");
      loginSuccess = true;
    } catch (error) {
      console.log(error);
      setError(error.message);
      // Handle errors
      loginSuccess = false;
    }

    // Track Amplitude Event: "Login"
    trackEvent("Login", {
      success: loginSuccess
    });

    setLoading(false);
  };
  const handlePasswordReset = async () => {
    setLoading(true);
    try {
      await confirmResetPassword({
        username,
        confirmationCode: code,
        newPassword,
      });
      // Password reset successful, navigate to login or directly sign in the user
      setError("");
      setScreenState("login");
    } catch (error) {
      console.error("Error resetting password", error);
      setError(error.message);
    }
    setLoading(false);
  };
  const handleRequestCode = async () => {
    setLoading(true);
    try {
      await resetPassword({ username });
      setError("");
      setScreenState("resetPassword");

      // Code sent successfully
    } catch (error) {
      console.error("Error requesting code", error);
      setError("You must enter a valid email to request a code.");
    }
    setLoading(false);
  };

  const handleNewPasswordSubmit = async () => {
    setLoading(true);
    const user = await signIn({ username, password });
    try {
      // Assuming `user` is the user object received from signIn method
      if (newPassword !== confirmNewPassword) {
        setError("Passwords do not match");
        return;
      }
      await confirmSignIn(
        {
          user,
          challengeResponse: "newPassword",
          newPassword: newPassword,
          oldPassword: password,
        }
        // OPTIONAL, the required attributes
      ).catch((err) => {
        console.log(err);
      });
      // User has set the new password and now signed in
      // Redirect or additional logic after successful password reset
      setError("");
    } catch (error) {
      console.error("Error during new password setup", error);
      console.log(error);
      console.log(username, newPassword);
      setError(error.message);

      // Handle errors
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        padding: "1rem",
      }}
    >
      <Typography variant="h1" sx={{ mb: 2 }}>
        BRIDGE
      </Typography>
      {screenState !== "login" && (
        <IconButton onClick={() => setScreenState("login")}>
          <ArrowBack />
        </IconButton>
      )}
      <div>
        <TextField theme={loginInputTheme}
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            mb: 1,
          }}
        />
      </div>
      {screenState == "login" && (
        <TextField theme={loginInputTheme}
          label="Password"
          type="password"
          value={password}
          sx={{
            mb: 1,
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
      )}

      {screenState == "newPassword" && (
        <div>
          <TextField theme={loginInputTheme}
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
      )}
      {screenState == "newPassword" && (
        <Box
          sx={{
            mb: 1,
            mt: 1,
          }}
        >
          <TextField
            label="Confirm New Password"
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </Box>
      )}

      {screenState == "resetPassword" && (
        <>
          <TextField
            label="Code"
            type="code"
            value={code}
            inputProps={{
              autoComplete: "off", // Note: React typically uses camelCase for attributes, but both versions work
            }}
            onChange={(e) => setCode(e.target.value)}
            sx={{
              mb: 1,
            }}
          />
          <TextField
            label="New Password"
            type="password"
            value={newPassword}
            inputProps={{
              autoComplete: "off", // Note: React typically uses camelCase for attributes, but both versions work
            }}
            sx={{
              mb: 1,
            }}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </>
      )}
      {error && (
        <Typography variant="body2" sx={{ color: "red", mb: 1 }}>
          {error}
        </Typography>
      )}
      {screenState == "login" && (
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={() => handleRequestCode()}
        >
          Forgot your password?
        </Typography>
      )}
      {screenState == "login" ? (
        <Button variant="angular" onClick={handleLogin} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Login"}
        </Button>
      ) : screenState == "newPassword" ? (
        <Button variant="angular" onClick={handleNewPasswordSubmit}>
          Set New Password
        </Button>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            variant="angular"
            sx={{
              mb: 1,
            }}
            onClick={handlePasswordReset}
          >
            Reset Password
          </Button>
          <Button onClick={() => handleRequestCode()}>Get Another Code</Button>
        </Box>
      )}
      <img
        src={myImage}
        alt="Login Background"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "20%",
          height: "auto",
          zIndex: -1,
        }}
      />
    </Box>
  );
};

export default LoginScreen;
