import { baseTheme } from "../theme/BaseTheme";
import * as themeTruss from "../theme/TrussTheme";
import * as themeGoat from "../theme/GoatTheme";

const env = process.env.REACT_APP_NODE_ENV;

export const DEFAULT_ENABLE_DARK_MODE = false;

// can be extended to include brand themes e.g. "goat", "depop"
export const themeStyles = {
  truss: {
    hasDarkMode: true,
    cssConfig: themeTruss.theme_TRUSS_light,
    cssConfigDark: themeTruss.theme_TRUSS_dark,
  },
  goat: {
    hasDarkMode: false,
    cssConfig: themeGoat.theme_GOAT,
  },
};

const tryGetDarkModeCssConfig = (
  themeStyleName,
  darkMode,
  hasDarkModeCssConfig
) => {
  return hasDarkModeCssConfig
    ? darkMode
      ? themeStyles[themeStyleName].cssConfigDark
      : themeStyles[themeStyleName].cssConfig
    : themeStyles[themeStyleName].cssConfig;
};

export const getThemeStyling = (themeStyleName, darkMode) => {
  let hasDarkModeCssConfig = themeStyles[themeStyleName].hasDarkMode;
  const themeStyleNameFormatted = themeStyleName.toLowerCase();

  //if the theme doesn't have the css styling
  if (!themeStyles[themeStyleName].cssConfigDark) {
    throw new Error(
      `Cannot retrieve styling for theme '${themeStyleName}'; cssConfig is missing.`
    );
  }

  //if "darkMode" and the theme doesn't have the dark mode css styling
  if (darkMode && !themeStyles[themeStyleName].cssConfigDark) {
    console.log(
      `Cannot retrieve dark mode styling for theme '${themeStyleName}'; cssConfigDark is missing. Using cssConfig instead.`
    );
    hasDarkModeCssConfig = false;
  }

  switch (themeStyleNameFormatted) {
    case "truss":
    case "goat":
      return tryGetDarkModeCssConfig(
        themeStyleNameFormatted,
        darkMode,
        hasDarkModeCssConfig
      );
    default:
      return themeStyles["truss"].cssConfig;
  }
};
