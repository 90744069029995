import React from "react";
import {
  Backdrop,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";

const LoadingOverlay = ({ open, text }) => {
  const theme = useTheme();
  return (
    <Backdrop
      style={{
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
      <Typography variant="h6" style={{ marginLeft: "10px" }}>
        {text}
      </Typography>
    </Backdrop>
  );
};

export default LoadingOverlay;
