/**
 * @fileoverview PipelineContext is a context that provides the state and actions for the pipeline creation form.
 * It is used to manage the state of the pipeline creation form and to provide the state to the components that need it.
 * It is used in the CreatePipelineScreen and JobConfigurationSubScreen components.
 * It is also used to fetch the pipeline details from the API and to update the pipeline details in the API.
 * It is used to manage the state of the pipeline creation form and to provide the state to the components that need it.
 * It is used in the CreatePipelineScreen and JobConfigurationSubScreen components.
 @state {Object} filters - The filters for the pipeline
  @state {String} name - The name of the pipeline
  @state {Number} targetQuantity - The target quantity for the pipeline
  @state {Number} priority - The priority for the pipeline
  @state {Array} orderedJobs - The ordered jobs for the pipeline
  @state {String} pipelineId - The id of the pipeline
  @function fetchPipelineDetails - A function to fetch the pipeline details from the API
 */

import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { showAlert } from "../components/common/userFeedback/CustomAlert";
import { BASE_URL } from "../config/apiConfig";
import { defaultFilters, defaultJobs } from "../config/pipelineConfig";
import { getHeaders } from "../utils/apiUtils";

const PipelineContext = createContext();

export const usePipelineContext = () => useContext(PipelineContext);

export const PipelineProvider = ({ children }) => {
  const [filters, setFilters] = useState(defaultFilters);
  const [name, setName] = useState("");
  const [targetQuantity, setTargetQuantity] = useState(0);
  const [priority, setPriority] = useState(1);
  const [orderedJobs, setOrderedJobs] = useState([]);
  const [pipelineId, setPipelineId] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [isArchived, setIsArchived] = useState(false);
  const [saving, setSaving] = useState(false);

  const fetchPipelineDetails = async (pipelineId) => {
    const headers = await getHeaders();
    await axios
      .get(`${BASE_URL}tools/pipelines/get-pipeline/${pipelineId}`, headers)
      .then((response) => {
        const { data } = response;
        const { name, target_quantity, priority, filters, jobs, datasets } =
          data;
        setName(name);
        setTargetQuantity(target_quantity);
        setPriority(priority);
        setFilters(filters);
        setDatasets(datasets[0]);
        // Map to the default jobs to ensure that all props are present
        const d = jobs.map((job) => {
          const name = job.job;
          let newJob = {};

          defaultJobs.map((defaultJob) => {
            if (defaultJob.jobName === name) {
              newJob = { ...defaultJob, ...job };
              newJob["graduationConfidence"] = job.qa_confidence;
              return newJob;
            }
            return null;
          });
          return newJob;
        });
        setOrderedJobs(d);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (pipelineId) {
      fetchPipelineDetails(pipelineId);
    }
  }, [pipelineId]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaving(true);
    // Here you would typically call a function to update the pipeline context or submit these values to an API
    // Optionally, navigate back or to another screen upon success

    let filtersWithoutJobs = Object.keys(filters);
    const jobs = orderedJobs.map((job) => {
      const { filterMap } = job;
      const localFilter = filters[filterMap];
      filtersWithoutJobs = filtersWithoutJobs.filter(
        (filter) => filter !== filterMap
      );

      return {
        id: job.id,
        filterMap: job.filterMap,
        graduationConfidence: job.graduationConfidence,
        users: job.users,
        filterGroup: localFilter.id,
        jobName: job.jobName,
      };
    });
    let remainingFilters = {};
    filtersWithoutJobs.map((filter) => {
      remainingFilters[filter] = filters[filter] ? filters[filter] : [];
    });

    const submissionData = {
      name,
      targetQuantity,
      pipelineId: pipelineId,
      datasets: [datasets],
      isArchived: isArchived ? 1 : 0,
      priority,
      jobs: jobs,
      filters: remainingFilters,
    };
    console.log(submissionData);
    const headers = await getHeaders();
    await axios
      .post(
        `${BASE_URL}tools/pipelines/save-or-update-pipeline`,
        submissionData,
        headers
      )
      .then((response) => {
        setSaving(false);
      })
      .catch((error) => {
        showAlert(error);
      });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleTargetQuantityChange = (event) => {
    const value = event.target.value;
    setTargetQuantity(value === "" ? null : Math.max(0, parseInt(value, 10)));
  };

  const handlePriorityChange = (event) => {
    const value = event.target.value;
    setPriority(
      value === "" ? null : Math.max(1, Math.min(10, parseInt(value, 10)))
    );
  };
  const handleFilterChange = (type, newFilters) => {
    setFilters({ ...filters, [type]: newFilters });
  };
  const removeFilter = (type, filter) => {
    const newFilters = filters[type].filter((f) => f.id !== filter.id);
    setFilters({ ...filters, [type]: newFilters });
  };
  function resetState() {
    setName("");
    setTargetQuantity(0);
    setPriority(1);
    setFilters({});
    setOrderedJobs([]);
    setPipelineId(null);
    setDatasets(null);
    setIsArchived(false);
  }
  const contextValue = {
    name,
    setName,
    targetQuantity,
    setTargetQuantity,
    saving,
    priority,
    setPriority,
    filters,
    setFilters,
    handleNameChange,
    handleTargetQuantityChange,
    handlePriorityChange,
    handleFilterChange,
    removeFilter,
    orderedJobs,
    setOrderedJobs,
    setDatasets,
    isArchived,
    setIsArchived,
    datasets,
    resetState,
    pipelineId,
    setPipelineId,
    handleSubmit,
    saving,
  };

  return (
    <PipelineContext.Provider value={contextValue}>
      {children}
    </PipelineContext.Provider>
  );
};
