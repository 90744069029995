// src/router/MainRouter.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LayoutWithSidebar from "../components/layout/AppBar/LayoutWithSidebar";
import { useAuth } from "../contexts/AuthContext";
import HomeDashboard from "../screens/default/HomeDashboard";
import AdminRoutes from "./AdminRoutes";
import JobsRoutes from "./JobsRoutes";
import { DataViewColumnProvider } from "../contexts/DataViewColumnContext";
//import { ENABLED_CLIENT } from "../config/clientDataViewConfig"
//import ClientDashboard from "../screens/default/ClientDashboard";

const MainRouter = () => {
  return (
    <Router>
    <DataViewColumnProvider>
      <LayoutWithSidebar />
      <Routes>
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/" element={<HomeDashboard />} /> {/* ENABLED_CLIENT ? <ClientDashboard clientName={ENABLED_CLIENT} /> :  */}
        <Route path="/jobs/*" element={<JobsRoutes />} />
      </Routes>
    </DataViewColumnProvider>
    </Router>
  );
};

export default MainRouter;
