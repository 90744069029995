import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import ZoomImageModal from "../../common/userFeedback/modals/ZoomImageModal";
const NUM_COLUMNS = 3;
function ImageCarousel({ activeImage, setActiveImage, images, imageScrollModal=false }) {
       
  const [open, setOpen] = useState(false);
  const [selectedGarmentImage, setSelectedGarmentImage] = useState(null);

  const handleOpen = (garmentImage) => {
    setSelectedGarmentImage(garmentImage)
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedGarmentImage(null)
    setOpen(false);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault(); // This line prevents the default behavior of the arrow keys
      setActiveImage((prevIndex) => {
        let currentRowStart = Math.floor(prevIndex / NUM_COLUMNS) * NUM_COLUMNS;
        let currentRowEnd = currentRowStart + NUM_COLUMNS - 1;
        if (event.key === "a") {
          return prevIndex - 1 >= currentRowStart
            ? prevIndex - 1
            : currentRowEnd;
        } else if (event.key === "d") {
          return prevIndex + 1 <= currentRowEnd
            ? prevIndex + 1
            : currentRowStart;
        } else if (event.key === "w") {
          return Math.max(prevIndex - NUM_COLUMNS, 0);
        } else if (event.key === "s") {
          return Math.min(prevIndex + NUM_COLUMNS, images.length - 1);
        } else {
          return prevIndex;
        }
      });
    };

    window.addEventListener("keydown", handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [images]);

  
  return (
    <Grid
      container
      spacing={2}
      sx={{
        overflow: "auto",
        pr: 2
      }}
    >
      {images.map((image, index) => (
        <Grid item xs={Math.round((12 / NUM_COLUMNS) * 4) / 4} key={index}
        sx={{
        }}
        justifyContent="center"
        alignItems="center">
          <img
            src={image}
            alt={`Carousel item ${index}`}
            style={{
              width: activeImage === index ? "100%" : "100%",
              objectFit: "contain",
              cursor: "pointer",
              borderWidth: activeImage === index ? 8 : 0,
              borderColor: activeImage === index ? "green" : null,
              borderStyle: activeImage === index ? "solid" : null,
              boxSizing: "border-box !important",
            }}
            className="active-carousel-item"
            onClick={(event) => {
              event.preventDefault();
              setActiveImage(index);
            }}
            
            onContextMenu={(e) => {
              e.preventDefault();
              handleOpen(image);
            }}
        justifyContent="center"
        alignItems="center"
          />
        </Grid>
      ))}
      <ZoomImageModal handleClose={handleClose} openModal={open} image={selectedGarmentImage} />
    </Grid>
  );
}

export default ImageCarousel;
