import React, { useEffect, useState } from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import {
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  Grid,
  useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getHeaders } from "../../../utils/apiUtils";
import axios from "axios";
import { showAlert } from "../userFeedback/CustomAlert";
import { BASE_URL } from "../../../config/apiConfig";
import GroupConfigurationNavigationWidget from "../../navigation/widgets/GroupConfigurationNavigationWidget";
import UserGridCustomToolbar from "./UserGridCustomToolbar";

function UserDashboardToolbar({ onCopy, ...props }) {
    const theme = useTheme();
    return (
    <GridToolbarContainer sx={{display: "inline", justifyContent: "flex-end", float: "right", mt: -3}}>
        <Grid container  sx={{justifyContent: "flex-end", float: "right"}}>
            <Grid item><UserGridCustomToolbar sx={{float: "right", mt: 0.5, mr: 0, color: theme.palette.primary.main}} onAddToGroupClick={onCopy} {...props}/></Grid>

            <Grid item>
                <GroupConfigurationNavigationWidget new_button_text={"New User"} sx={{display: "inline", float: "right"}} />
            </Grid>
        </Grid>
    </GridToolbarContainer>

    
//return (
//    <GridToolbarContainer {...props}>
//      <GridToolbarFilterButton />
//      <GridToolbarDensitySelector />
//      {/*props.someCustomString*/}
//      <GroupConfigurationNavigationWidget new_button_text={"New User"} />
//      <GridToolbarExport />
//      <GridToolbarColumnsButton />
//    </GridToolbarContainer>
//  );
  );
}

export default UserDashboardToolbar;
