import React, { useState, useEffect, forwardRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Modal,
  CircularProgress,
  Button,
  Checkbox,
} from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
const PlotlyRenderers = createPlotlyRenderers(Plot);
const BATCH_SIZE = 1000;
function HoursWorkedPivotTable() {
  const [proposals, setProposals] = useState([]);
  const { palette, typography } = useTheme();
  const [date, setDate] = React.useState([dayjs().subtract(6, "day"), dayjs()]);
  const [dataLoading, setDataLoading] = useState(false);
  const [showQA, setShowQA] = useState(false);
  const refreshData = () => {
    setProposals([]);
    setDataLoading(true);
    fetchDataByJobTypes();
  };
  const fetchData = async (jobType, page = 1, allData = []) => {
    try {
      let headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/analytics/pivots/hours-worked`,
        {
          jobType: jobType,
          startDate: date[0].toISOString(),
          endDate: date[1].toISOString(),
          batch_size: BATCH_SIZE,
          page: page,
        },
        headers
      );
      const newData = [...allData, ...response.data];
      if (response.data.length === BATCH_SIZE) {
        return fetchData(jobType, page + 1, newData); // Fetch next page
      } else {
        return newData;
      }
    } catch (error) {
      console.error(`Error fetching proposals for ${jobType}:`, error);
      return allData; // Return whatever was fetched before the error
    }
  };

  const fetchDataByJobTypes = async () => {
    try {
      setDataLoading(true);
      const jobTypes = ["AGGREGATION"];
      const promises = jobTypes.map((jobType) => fetchData(jobType));
      const results = await Promise.all(promises);
      const concatenatedResults = results.flat();
      console.log(`concatenatedResults: ${JSON.stringify(concatenatedResults)}`)
      setProposals(concatenatedResults);
      setDataLoading(false);
    } catch (error) {
      console.error("Error in fetchDataByJobTypes:", error);
      setDataLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" color="primary.800">
          Hours Worked Pivot Table
        </Typography>
        <Box
          sx={{
            mt: 2,
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            flexDirection: "row",
            display: "flex",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{ start: "Start", end: "End" }}
              onChange={(newValue) => setDate(newValue)}
            />
          </LocalizationProvider>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Checkbox
              checked={showQA}
              onChange={(e) => setShowQA(e.target.checked)}
            />
            <Typography variant="h3">QA</Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={refreshData}>
              Refresh
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            minHeight: "50vh",
          }}
        >
          <PivotTableUI
            data={proposals}
            onChange={setProposals}
            {...proposals}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
      </Box>
    </>
  );
}

export default HoursWorkedPivotTable;
