import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import SelectableList from "../../../components/features/GarmentAttribute/SelectableList";
import ImageWindow from "../../../components/layout/Interactive/ImageWindow";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { jobNamesMap } from "../../../config/jobTypes";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import JobCompleteScreenCover from "../../../components/common/userFeedback/JobCompleteScreenCover";

const getFriendlyName = (toolType) => {
  switch (toolType) {
    case "STYLE":
      return "Style";
    case "TYPE":
      return "Type";
    default:
      return "Garment Attribute";
  }
};

function GarmentAttributeTool() {
  const {
    inputFilters,
    jobDetails,

    toolType,
    filterHardness,
    datasets,
    jobDataLoaded,
    shownAttributes,
  } = useToolContext();
  const { palette } = useTheme();

  const [tagSet, setTagSet] = useState([]);
  const [garmentDetails, setGarmentDetails] = useState({});
  const [images, setImages] = useState([]);
  const [selectedTypeHierachy, setSelectedTypeHierachy] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTypesBank, setSelectedTypesBank] = useState([]); // All selected types
  const [displayTagSet, setDisplayTagSet] = useState([]); // Tags to be displayed based on the selection
  const friendlyName = getFriendlyName(toolType);
  const [presentedTimeStamp, setPresentedTimeStamp] = useState(null);
  const [additionalData, setAdditionalData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [jobComplete, setJobComplete] = useState(false);

  const resetState = () => {
    setGarmentDetails({});
    setImages([]);
    setSelectedTypeHierachy([]);
    setSelectedType(null);
    const rootTags = tagSet.filter((tag) => tag.parent_id === null);
    setDisplayTagSet(rootTags);
    setSelectedTypesBank([]);
    setDataLoading(false);
  };
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const onSelectSelectedType = (type) => {
    if (type) {
      setSelectedType({
        ...type,
        presented_timestamp: presentedTimeStamp,
        proposal_timestamp: getCurrentTimestamp(),
      });
      setSelectedTypeHierachy([...selectedTypeHierachy, type]);
      // Filter tagSet to show only children of the selected type
      const childrenTags = tagSet.filter((tag) => tag.parent_id === type.id);
      setDisplayTagSet(childrenTags);
    }
  };

  const popTypeHierarchy = () => {
    const newSelectedTypeHierarchy = selectedTypeHierachy.slice(0, -1);
    setSelectedTypeHierachy(newSelectedTypeHierarchy);
    setSelectedType(
      newSelectedTypeHierarchy[newSelectedTypeHierarchy.length - 1]
    );

    if (newSelectedTypeHierarchy.length === 0) {
      // Reset to display root tags if hierarchy is popped to the start
      const rootTags = tagSet.filter((tag) => tag.parent_id === null);
      setDisplayTagSet(rootTags);
    } else {
      // Display tags based on the new last item in the hierarchy
      const currentType =
        newSelectedTypeHierarchy[newSelectedTypeHierarchy.length - 1];
      const childrenTags = tagSet.filter(
        (tag) => tag.parent_id === currentType.id
      );
      setDisplayTagSet(childrenTags);
    }
  };

  const fetchTagSet = async () => {
    setDataLoading(true);
    const headers = await getHeaders();
    await axios
      .get(
        `${BASE_URL}tools/jobs/tag-set/${jobDetails.pipeline_job_link_id}`,
        headers
      )
      .then((res) => {
        const ts = res.data.data;

        // Create a set of all valid IDs from tagSet
        const validIds = new Set(ts.map((tag) => tag.id));

        // Filter and modify tagSet, setting parent_id to null if it's not a valid ID
        const updatedTagSet = ts.map((tag) => ({
          ...tag,
          parent_id: validIds.has(tag.parent_id) ? tag.parent_id : null,
        }));

        // Use the updatedTagSet where the parent_id is validated
        setTagSet(updatedTagSet);
      })
      .catch((err) => {
        console.log(err);
      });
    setDataLoading(false);
  };

  const fetchNextItem = async () => {
    setDataLoading(true);
    const headers = await getHeaders();
    console.log(jobDetails);
    const submissionData = {
      filters: inputFilters,
      filterHardness: filterHardness,
      job_id: jobDetails.job_id,
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      datasets: datasets,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      shownAttributes: shownAttributes,
    };
    await axios
      .post(
        `${BASE_URL}tools/summon/next-item/basic/multi-and`,
        submissionData,
        headers
      )
      .then((res) => {
        const parseImages = Array.from({
          length: res.data.data.num_images,
        }).map((_, index) => {
          return `${BASE_IMAGE_URL}${res.data.data.uuid_public_id}-${
            index + 1
          }.jpg`;
        });
        const displayAttributes = shownAttributes.map((x) => {
          return { label: x, value: res.data.data[x] };
        });
        setAdditionalData(displayAttributes);
        setPresentedTimeStamp(getCurrentTimestamp());
        setImages(parseImages);
        setGarmentDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setJobComplete(true);
      });
    setDataLoading(false);
  };

  const skipItem = async () => {
    setDataLoading(true);
    const headers = await getHeaders();
    console.log("Skipping item");
    console.log(garmentDetails);
    const submissionData = {
      tool: toolType,
      job_id: jobDetails.job_id,
      garment_id: garmentDetails.garment_id,
      confidence: 0,
      pipeline_job_id: jobDetails.pipeline_job_link_id,
    };
    axios
      .post(`${BASE_URL}tools/jobs/skip-garment`, submissionData, headers)
      .then((res) => {
        console.log(`jobNamesMap[toolType]: ${jobNamesMap[toolType]}`);
        trackEvent("Proposal", {
          garmentId: garmentDetails.garment_id,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobId: jobDetails.job_id,
          jobName: jobDetails.job,
          toolType: jobNamesMap[jobDetails.job],
          toolName: "GarmentAttributeTool",
          confidence: jobDetails.qa_confidence,
          skipped: true,
          qa: false,
        });
        resetState();
        console.log(res.data);
        fetchNextItem();
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
    setDataLoading(false);
  };

  const classifyItem = async () => {
    setDataLoading(true);
    const headers = await getHeaders();
    if (selectedType === null && selectedTypesBank.length === 0) {
      setDataLoading(false);
      alert("Please select a type before classifying");
      return;
    }
    let proposalIds = [];
    if (selectedType) {
      proposalIds.push(selectedType);
    }
    proposalIds = proposalIds.concat(selectedTypesBank);

    const submissionData = {
      toolType: toolType,
      jobId: jobDetails.job_id,
      garmentId: garmentDetails.garment_id,
      confidence: 0.4,
      proposals: proposalIds,
      pipelineJobId: jobDetails.pipeline_job_link_id,
    };
    axios
      .post(
        `${BASE_URL}tools/classify/basic/multi-and`,
        submissionData,
        headers
      )
      .then((res) => {
        trackEvent("Proposal", {
          garmentId: garmentDetails.garment_id,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobId: jobDetails.job_id,
          jobName: jobDetails.job,
          toolType: jobNamesMap[jobDetails.job],
          toolName: "GarmentAttributeTool",
          confidence: jobDetails.qa_confidence,
          skipped: false,
          qa: false,
        });
        resetState();
        fetchNextItem();
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
    setDataLoading(false);
  };

  const addToBankedSelectedTags = () => {
    if (selectedType) {
      setSelectedTypesBank([...selectedTypesBank, selectedType]);
      setSelectedTypeHierachy([]);
      setSelectedType(null);
      const rootTags = tagSet.filter((tag) => tag.parent_id === null);
      setDisplayTagSet(rootTags);
    }
  };
  const removeFromBankedSelectedTags = (tag) => {
    const newSelectedTypesBank = selectedTypesBank.filter(
      (selectedTag) => selectedTag.id !== tag.id
    );
    setSelectedTypesBank(newSelectedTypesBank);
  };

  useEffect(() => {
    if (tagSet) {
      const rootTags = tagSet.filter((tag) => tag.parent_id === null); // Only tags without a parent

      setDisplayTagSet(rootTags);
    }
  }, [tagSet]);

  useEffect(() => {
    if (jobDataLoaded) {
      fetchNextItem();
      fetchTagSet();
      setDataLoading(false);
    }
  }, [jobDataLoaded]);

  const renderTypeHierachyItem = (item, index) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          border: `1px solid ${palette.primary[800]}`,
          borderRadius: 1,
          mt: 1,
          backgroundColor:
            index === selectedTypeHierachy.length - 1
              ? palette.success[400]
              : palette.primary[100],
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography
            sx={{
              flex: 1,
              color: palette.primary[800],
            }}
            variant="h5"
          >
            {item.name}
          </Typography>
        </Box>
        <Button
          sx={{ ml: 2 }}
          onClick={() => popTypeHierarchy()}
          variant="contained"
          color="error"
        >
          POP
        </Button>
      </Box>
    );
  };

  return (
    <KeyEventHandler
      onDelete={popTypeHierarchy}
      onTab={skipItem}
      onEnter={classifyItem}
      onShiftEnter={addToBankedSelectedTags}
      disableKeys={dataLoading}
    >
      {!jobComplete ? (
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LoadingOverlay open={dataLoading} />
          <Box
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Typography
              variant="h4"
              color={"primary.800"}
              sx={{
                flex: 1,
              }}
            >
              {friendlyName} Tagging Tool
            </Typography>
            <Typography variant="body1">
              Garment ID: {garmentDetails.garment_id}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {additionalData.map((ad) => {
              const { value, label } = ad;
              return (
                <Typography variant="h8">
                  {label}: {value}
                </Typography>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 4,
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography variant="h3" color={"primary.800"}>
                {friendlyName} List
              </Typography>
              <Box
                sx={{
                  pr: 1,
                }}
              >
                {selectedTypeHierachy.map((type, index) =>
                  renderTypeHierachyItem(type, index)
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                  flexDirection: "column",
                  pr: 1,
                }}
              >
                <SelectableList
                  items={displayTagSet}
                  selectedItems={selectedType}
                  onSelect={onSelectSelectedType}
                />
              </Box>
              <Box>
                <Typography variant="h3" color={"primary.800"}>
                  Banked {friendlyName}s
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    pr: 1,
                  }}
                >
                  {selectedTypesBank.map((type) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        p: 1,
                        border: `1px solid ${palette.primary[800]}`,
                        borderRadius: 1,
                        mt: 1,
                        backgroundColor: palette.primary[100],
                      }}
                    >
                      <Typography
                        sx={{
                          flex: 1,
                          color: palette.primary[800],
                        }}
                        variant="h5"
                      >
                        {type.name}
                      </Typography>
                      <Button
                        sx={{ ml: 2 }}
                        onClick={() => removeFromBankedSelectedTags(type)}
                        variant="contained"
                        color="error"
                      >
                        REMOVE
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 4,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <ImageWindow images={images} />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <JobCompleteScreenCover />
      )}
    </KeyEventHandler>
  );
}

function WrappedGarmentAttributeTool() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <GarmentAttributeTool />
    </ToolProvider>
  );
}

export default WrappedGarmentAttributeTool;
