// src/components/SideDrawer.js
import React from "react";
import {
  Drawer,
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
  Divider
} from "@mui/material";
import { Link } from "react-router-dom";
import { navLinks } from "../../../config/navLinksConfig";
import packageJSON from "../../../../package.json"

const MainDrawerWidth = 300;
const formattedAppVersion = JSON.stringify(packageJSON.version).replace("\"", "").replace("\"", "");

const SideDrawer = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  
  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={handleDrawerClose}
      ModalProps={{ keepMounted: true }} // Better open performance on mobile.
    >
      <Box
        sx={{
          width: MainDrawerWidth,
          flexShrink: 0,
          marginTop: "0.5rem"
        }}
      >
        <List>
          {navLinks.map((link, index) => (
            <>
              <ListItem key={index} disablePadding>
                <ListItemButton
                  component={Link}
                  to={link.path}
                  onClick={handleDrawerClose} sx={index === 0 ? {paddingTop: "1.25rem", paddingBottom: "0.75rem", marginTop: "-1rem"} : {paddingTop: "0.75rem", paddingBottom: "0.75rem"}}
                >
                  {/*<Box sx={{mt: "4px"}}>
                    {link.icon}
                  </Box>*/}
                  <ListItemText primaryTypographyProps={{textTransform: "uppercase", fontWeight: "normal"}} primary={link.title} /> {/* sx={{ml: link.icon ? 1.5 : 3.5}} */}
                </ListItemButton>
              </ListItem>
              <Divider sx={{color: `${theme.palette.primary[500]}`}} />
            </>
          ))}
        </List>

      </Box>

      <Typography variant="h3" sx={{px: 1.25, position: 'absolute', bottom: 10, right: 0}}>
        <span variant="h3" style={{color: `${theme.palette.primary[500]}`, paddingRight: '0.75rem'}}>{`version`}</span>{`${formattedAppVersion}`}
      </Typography>
    </Drawer>
  );
};

export default SideDrawer;
