import { Typography, Box, useTheme, Grid, Paper } from "@mui/material";
import React from "react";
import { useToolContext } from "../../../contexts/ToolContext";
/*
  jobPipelineAttributeCount: 100,
    jobAttributeCount: 1000,
    jobPipelineGarmentCount: 10,
    JobGarmentCount: 100,
    */
function ToolCountsModule(props) {
  const { counts } = useToolContext();
  const { palette } = useTheme();
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        {/* Job Pipeline Garment Count */}
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              backgroundColor: "#f5f5f5",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="body_lg">Garments Processed</Typography>
            <Typography variant="body_sm">(in this pipeline)</Typography>
            <Typography variant="h4" color="primary">
              {counts.pipelineTotalGarments}
            </Typography>
          </Paper>
        </Grid>

        {/* Job Garment Count */}
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              backgroundColor: "#f5f5f5",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="body_lg">Garments Tagged</Typography>
            <Typography variant="body_sm">(in this pipeline)</Typography>
            <Typography variant="h4" color="primary">
              {counts.pipelineTaggedGarments}
            </Typography>
          </Paper>
        </Grid>
        {/* Job Garment Count */}
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              backgroundColor: "#f5f5f5",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="body_lg">Garments Processed</Typography>
            <Typography variant="body_sm">(for this style)</Typography>
            <Typography variant="h4" color="primary">
              {counts.pipelineStyleProcessedGarments}
            </Typography>
          </Paper>
        </Grid>

        {/* Job Garment Count */}
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              backgroundColor: "#f5f5f5",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="body_lg">Garments Tagged</Typography>
            <Typography variant="body_sm">(for this style)</Typography>
            <Typography variant="h4" color="primary">
              {counts.pipelineStyleTaggedGarments}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ToolCountsModule;
