import React, { useState } from "react";
import { Button, TextField, Container, Typography, Box } from "@mui/material";
import axios from "axios";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";

const CreateUserGroupScreen = () => {
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Replace with your actual API endpoint
    const headers = await getHeaders();
    axios
      .post(
        `${BASE_URL}tools/admin/create-user-group`,
        {
          bridge_user_group: groupName,
          description,
        },
        headers
      )
      .then(() => {
        showAlert("User group created successfully", "success");
        // Optionally reset form or redirect user
      })
      .catch((error) =>
        console.error("There was an error creating the user group:", error)
      );
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h3" component="h2" gutterBottom>
        Create New User Group
      </Typography>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1, // Add some space between form elements
        }}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="Group Name"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          required
        />
        <Button type="submit" variant="angular" sx={{ mt: 1 }}>
          Create Group
        </Button>
      </Box>
    </Container>
  );
};

export default CreateUserGroupScreen;
