/**
 * @fileoverview DataViewColumnContext is a context that provides the columns for different views of garment data, depending on the client.
 * It is used to provide the correct columns for different clients (e.g. Depop, Ebay, Farfetch...)
 * It is used to provide the correct columns for the Dataset Garments DataGrid for a particular client
 * It is used to provide the correct columns for the Catalogue Garments DataGrid for a particular client
 * It is used to provide the correct columns for the Single Garment screen DataGrid for a particular client
 */

import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { showAlert } from "../components/common/userFeedback/CustomAlert";
import { BASE_URL } from "../config/apiConfig";
import { defaultFilters, defaultJobs } from "../config/pipelineConfig";
import { getHeaders } from "../utils/apiUtils";
import { ViewButton } from "../components/ui/buttons/TableButtons";
import {
  formatCurrency_GBP,
  getProgressColor,
  getGarmentColourComponentsFromArrayOfWords,
  getGarmentColourComponentsFromSentence,
  getArrayOfWords,
  capitalizeWords,
} from "../utils/sharedFunctions";
import {
  getHexColorsFromWords,
  colorIsCloserToWhiteThanBlack,
} from "../utils/colorUtils";
import { isValidAverageTimeToSell } from "../utils/garmentPriceUtils";
import FormattedCurrencyTypography from "../components/ui/typography/FormattedCurrencyTypography";
import ClickableScrollImage from "../components/layout/Interactive/Images/ClickableScrollImage";
import { useNavigate, useParams } from "react-router-dom";
import { getAverageSoldPrice } from "../utils/garmentPriceUtils";
import {
  getFormattedTimestamp,
  formatTimeToSell,
  getFormattedTimeToSellComponent,
} from "../utils/dateUtils";
import {
  Autocomplete,
  Avatar,
  Box,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

const DataViewColumnContext = createContext();

export const useDataViewColumnContext = () => useContext(DataViewColumnContext);

const colorObjectComparator = (colorArray1, colorArray2) => {
  const colorArrayWords1 = colorArray1.map(garmentColor => garmentColor.name)
  const colorArrayWords2 = colorArray2.map(garmentColor => garmentColor.name)
  const colorArrayString1 = colorArrayWords1.join('')
  const colorArrayString2 = colorArrayWords2.join('')
  //https://stackoverflow.com/questions/10198257/comparing-2-strings-alphabetically-for-sorting-purposes
  return colorArrayString1.localeCompare(colorArrayString2);
};

export const DataViewColumnProvider = ({ children, id }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const garmentColumns = [
    {
      field: "image",
      headerName: "IMAGE",
      flex: 1,
      editable: false,
      columnId: 0,
      renderCell: (params) => {
        const uuid_public_id = params.row.uuid_public_id;
        return (
          <ClickableScrollImage num_images={params.row.num_images} uuid_public_id={uuid_public_id} image={`https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${uuid_public_id}-1.jpg`} sxImg={{ height: 96, width: 96, paddingY: 2, float: "left" }} />
        );
      },
      //headerClassName: 'custom-data-grid-header',
      //headerAlign: 'center', //align center in line with image
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      columnId: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "brand",
      headerName: "BRAND",
      flex: 1,
      columnId: 2,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "type",
      headerName: "TYPE",
      flex: 1,
      columnId: 3,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "colour",
      headerName: "COLOUR",
      flex: 1,
      columnId: 4,
      renderCell: (params) =>
        params.value &&
        params.value.every((x) => {
          const hasName = x.name !== null;
          const hasColour =
            x.red !== null && x.green !== null && x.blue !== null;
          return hasName && hasColour;
        }) ? (
          params.value.map((garmentColor, index) => {
            const hasName = garmentColor.name !== null;
            const hasColour =
              garmentColor.red !== null &&
              garmentColor.green !== null &&
              garmentColor.blue !== null;
            let backgroundColor = hasColour
              ? `rgb(${garmentColor.red}, ${garmentColor.green}, ${garmentColor.blue})`
              : "";
            const hasMulticolorGarmentName =
              garmentColor.name.toLowerCase() === "multicolour" ||
              garmentColor.name.toLowerCase() === "multi colour" ||
              garmentColor.name.toLowerCase() === "multi-colour";

            if (hasMulticolorGarmentName) {
              backgroundColor = `linear-gradient(
                45deg,
                rgba(255, 0, 0, 1) 0%,
                rgba(255, 154, 0, 1) 10%,
                rgba(208, 222, 33, 1) 20%,
                rgba(79, 220, 74, 1) 30%,
                rgba(63, 218, 216, 1) 40%,
                rgba(47, 201, 226, 1) 50%,
                rgba(28, 127, 238, 1) 60%,
                rgba(95, 21, 242, 1) 70%,
                rgba(186, 12, 248, 1) 80%,
                rgba(251, 7, 217, 1) 90%,
                rgba(255, 0, 0, 1) 100%)`;
            }
            return hasColour ? (
              <Box
                sx={{ marginRight: 2, overflow: "hidden" }}
                display="inline-block"
              >
                <Box
                  display="inline-block"
                  sx={{
                    mr: 1,
                    mb: "-2px",
                    borderRadius: "50%",
                    backgroundColor: !backgroundColor.startsWith(
                      "linear-gradient"
                    )
                      ? backgroundColor
                      : "",
                    background: backgroundColor.startsWith("linear-gradient")
                      ? backgroundColor
                      : "",
                    border: colorIsCloserToWhiteThanBlack(garmentColor)
                      ? `1px solid black`
                      : "",
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <Typography
                  display="inline"
                  sx={{ textTransform: "capitalize !important" }}
                >
                  {capitalizeWords(
                    hasName ? garmentColor.name.toLowerCase() : ""
                  )}
                </Typography>
              </Box>
            ) : (
              <Typography>{JSON.stringify(garmentColor)}</Typography>
            );
          })
        ) : (
          <Typography
            style={{
              color: params.value ? "inherit" : theme.palette.primary[400],
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {"-"}
          </Typography>
        ),
      sortComparator: colorObjectComparator
    },
    {
      field: "material",
      headerName: "MATERIAL",
      flex: 1,
      columnId: 5,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "avg_sales_price_in_gbp",
      headerName: "AVG RESALE VALUE",
      flex: 1,
      columnId: 6,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value ? formatCurrency_GBP.format(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "average_time_to_sell",
      headerName: "AVG TIME TO SELL",
      flex: 1,
      columnId: 7,
      renderCell: (params) => (
        <Typography
          style={{
            color: isValidAverageTimeToSell(params.value)
              ? "inherit"
              : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {isValidAverageTimeToSell(params.value)
            ? getFormattedTimeToSellComponent(params.value, {
                color: `${theme.palette.primary[800]} !important`,
              })
            : "-"}
        </Typography>
      ),
    },
    {
      field: "uuid_public_id",
      headerName: "CATALOGUE REPRESENTATIVE UUID",
      flex: 1,
      columnId: 8,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "aggregation_count",
      headerName: "GROUP SIZE",
      flex: 1,
      columnId: 9,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "sold",
      headerName: "SOLD",
      flex: 1,
      columnId: 10,
      valueGetter: (params) => params.row.sold_price_in_gbp,
      renderCell: (params) => (
        <Typography>
          {params.value !== null && params.value !== 0 ? `Yes` : `No`}
        </Typography>
      ),
    },
    {
      field: "sold_price_in_gbp",
      headerName: "SALE PRICE",
      flex: 1,
      columnId: 11,
      renderCell: (params) => (
        <FormattedCurrencyTypography value={params.value} nullIfZero={true} />
      ),
    },
    {
      field: "location",
      headerName: "SALE LOCATION",
      flex: 1,
      columnId: 12,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "_condition",
      headerName: "CONDITION",
      flex: 1,
      columnId: 13,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "size",
      headerName: "SIZE",
      flex: 1,
      columnId: 14,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "progress",
      headerName: "COMPLETED",
      flex: 1,
      columnId: 15,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "uuid_public_id",
      headerName: "UUID",
      flex: 1,
      columnId: 16,
      editable: false,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: params.value ? "inherit" : theme.palette.primary[400],
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.value != null ? params.value : "-"}
          </Typography>
        );
      },
      headerClassName: "custom-data-grid-header",
    },
    {
      field: "time_to_sell",
      headerName: "TIME TO SELL",
      flex: 1,
      columnId: 17,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value
            ? getFormattedTimeToSellComponent(params.value, {
                color: `${theme.palette.primary[800]} !important`,
              })
            : "-"}
        </Typography>
      ),
    },
    {
      field: "listed_timestamp",
      headerName: "LISTED DATE",
      flex: 1,
      columnId: 18,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? getFormattedTimestamp(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "sold_timestamp",
      headerName: "SOLD DATE",
      flex: 1,
      columnId: 19,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? getFormattedTimestamp(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "rrp",
      headerName: "RRP",
      flex: 1,
      columnId: 20,
      renderCell: (params) => (
        <FormattedCurrencyTypography value={params.value} />
      ),
    },
    //{
    //  field: "svg_sp",
    //  headerName: "AVG S.P.",
    //  flex: 1,
    //  renderCell: (params) => (
    //    <Typography style={{ color: params.value ? "inherit" : "red" }}>
    //      {params.value != null ? `${formatCurrency_GBP.format(params.value)}` : "N/A"}
    //    </Typography>
    //  ),
    //},
    {
      field: "vendor_code",
      headerName: "SKU",
      flex: 1,
      columnId: 21,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "released",
      headerName: "RELEASED",
      flex: 1,
      columnId: 22,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "vendor",
      headerName: "VENDOR",
      flex: 1,
      columnId: 23,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "care_instruction",
      headerName: "CARE",
      flex: 1,
      columnId: 24,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "capitalize",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "",
      headerName: "",
      flex: 1,
      columnId: 25,
      renderCell: (params) => (
        <ViewButton
          isBlack
          action={() => navigate(`/admin/analytics/garment/${params.row.id}`)}
        />
      ),
    },
    {
      field: "avg_listed_price_in_gbp",
      headerName: "AVG LISTING VALUE",
      flex: 1,
      columnId: 26,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value ? formatCurrency_GBP.format(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "listed_price_in_gbp",
      headerName: "LISTING PRICE",
      columnId: 27,
      flex: 1,
      renderCell: (params) => (
        <FormattedCurrencyTypography value={params.value} nullIfZero={true} />
      ),
    },
    //{
    //  field: "colors_from_words",
    //  headerName: "COLOUR_FROM_WORDS_UNUSED",
    //  flex: 1,
    //  renderCell: (params) => (
    //    params.value ? (
    //      getArrayOfWords(params.value) ? getArrayOfWords(params.value).map((garmentColor, index) =>
    //        <Box sx={{marginRight: 2, overflow: "hidden"}}  display="inline-block">
    //          <Box display="inline-block" sx={{mr: 1, mb: "-2px", borderRadius: "50%", backgroundColor: getHexColorsFromWords(getArrayOfWords(params.value))[index] !== null && getHexColorsFromWords(getArrayOfWords(params.value))[index] !== undefined ? `${getHexColorsFromWords(getArrayOfWords(params.value))[index]} !important` : '', width: "1rem", height: "1rem"}}/>
    //          <Typography display="inline" sx={{textTransform: 'capitalize !important'}}>{capitalizeWords(garmentColor.toLowerCase())}</Typography>
    //        </Box>) : <></>
    //    ) : <Typography style={{ color: params.value ? "inherit" : theme.palette.primary[400],
    //      whiteSpace: "nowrap",
    //      overflow: "hidden",
    //      textOverflow: "ellipsis" }}>
    //      {"-"}
    //    </Typography>
    //  ),
    //},
  ];

  const getColumnFromColumnID = (columnId) => {
    const columnsWithId = garmentColumns.findIndex(x => x.columnId === columnId)
    return garmentColumns[columnsWithId ?? 0]
  }

  const allColumnsConfig = [
    getColumnFromColumnID(0),
    getColumnFromColumnID(1),
    getColumnFromColumnID(2),
    getColumnFromColumnID(3),
    getColumnFromColumnID(4),
    getColumnFromColumnID(5),
    getColumnFromColumnID(6),
    getColumnFromColumnID(26),
    getColumnFromColumnID(7),
    getColumnFromColumnID(8),
    getColumnFromColumnID(9),
    getColumnFromColumnID(10),
    getColumnFromColumnID(11),
    getColumnFromColumnID(12),
    getColumnFromColumnID(13),
    getColumnFromColumnID(14),
    getColumnFromColumnID(15),
    getColumnFromColumnID(24),
    getColumnFromColumnID(25),
  ]

  /* CONFIGURE DATASET/CATALOGUE/SINGLE GARMENT COLUMNS HERE */
  const clientDatasetViewConfig = {
    Depop: {
      columns: [
        getColumnFromColumnID(0),
        getColumnFromColumnID(1),
        getColumnFromColumnID(2),
        getColumnFromColumnID(3),
        getColumnFromColumnID(4),
        getColumnFromColumnID(10),
        getColumnFromColumnID(11),
        getColumnFromColumnID(12),
        getColumnFromColumnID(13),
        getColumnFromColumnID(14),
        getColumnFromColumnID(15),
        getColumnFromColumnID(25),
      ],
    },
    Farfetch: {
      columns: [
        getColumnFromColumnID(0),
        getColumnFromColumnID(1),
        getColumnFromColumnID(2),
        getColumnFromColumnID(3),
        getColumnFromColumnID(5),
        getColumnFromColumnID(24),
        getColumnFromColumnID(4),
        getColumnFromColumnID(25),
      ],
    },
    Selfridges: {
      columns: [
        // SAME AS DEPOP
        getColumnFromColumnID(0),
        getColumnFromColumnID(1),
        getColumnFromColumnID(2),
        getColumnFromColumnID(3),
        getColumnFromColumnID(4),
        getColumnFromColumnID(10),
        getColumnFromColumnID(11),
        getColumnFromColumnID(12),
        getColumnFromColumnID(13),
        getColumnFromColumnID(14),
        getColumnFromColumnID(15),
        getColumnFromColumnID(25),
      ],
    },
    Ebay: {
      columns: [
        // SAME AS DEPOP
        getColumnFromColumnID(0),
        getColumnFromColumnID(1),
        getColumnFromColumnID(2),
        getColumnFromColumnID(3),
        getColumnFromColumnID(4),
        getColumnFromColumnID(10),
        getColumnFromColumnID(11),
        getColumnFromColumnID(12),
        getColumnFromColumnID(13),
        getColumnFromColumnID(14),
        getColumnFromColumnID(15),
        getColumnFromColumnID(25),
      ],
    },
  };

  const clientCatalogueViewConfig = {
    Depop: {
      columns: [
        getColumnFromColumnID(0),
        getColumnFromColumnID(1),
        getColumnFromColumnID(2),
        getColumnFromColumnID(3),
        getColumnFromColumnID(4),
        //garmentColumns[5],
        getColumnFromColumnID(6),
        getColumnFromColumnID(26),
        getColumnFromColumnID(7),
        getColumnFromColumnID(8),
        getColumnFromColumnID(9),
        getColumnFromColumnID(25),
      ],
    },
    Farfetch: {
      columns: [
        getColumnFromColumnID(0),
        getColumnFromColumnID(1),
        getColumnFromColumnID(2),
        getColumnFromColumnID(3),
        getColumnFromColumnID(5),
        getColumnFromColumnID(24),
        getColumnFromColumnID(4),
        getColumnFromColumnID(25),
      ],
    },
    Selfridges: {
      columns: [
        // SAME AS DEPOP
        getColumnFromColumnID(0),
        getColumnFromColumnID(1),
        getColumnFromColumnID(2),
        getColumnFromColumnID(3),
        getColumnFromColumnID(4),
        getColumnFromColumnID(5),
        getColumnFromColumnID(6),
        getColumnFromColumnID(26),
        getColumnFromColumnID(7),
        getColumnFromColumnID(8),
        getColumnFromColumnID(9),
        getColumnFromColumnID(25),
      ],
    },
    Ebay: {
      columns: [
        // SAME AS DEPOP
        getColumnFromColumnID(0),
        getColumnFromColumnID(1),
        getColumnFromColumnID(2),
        getColumnFromColumnID(3),
        getColumnFromColumnID(4),
        //garmentColumns[5],
        getColumnFromColumnID(6),
        getColumnFromColumnID(26),
        getColumnFromColumnID(7),
        //garmentColumns[8],
        //garmentColumns[9],
        getColumnFromColumnID(25),
      ],
    },
  };

  const singleGarmentColumns = [
    getColumnFromColumnID(0),
    getColumnFromColumnID(11),
    getColumnFromColumnID(27),
    getColumnFromColumnID(10),
    getColumnFromColumnID(23),
    getColumnFromColumnID(14),
    getColumnFromColumnID(13),
    getColumnFromColumnID(17),
    getColumnFromColumnID(18),
    getColumnFromColumnID(19),
    getColumnFromColumnID(12),
  ];

  //all fields are enabled by default - configures which fields are hidden/shown on the Single Garment screen
  const clientSingleGarmentViewConfig = {
    columns: singleGarmentColumns,
    Depop: {
      fieldsEnabled: {
        material: false,
        care: false,
        location: false,
      },
    },
    Farfetch: {
      fieldsEnabled: {
        material: true,
        care: true,
        location: false,
      },
    },
    Selfridges: {
      fieldsEnabled: {
        material: false,
        care: false,
        location: false,
      },
    },
  };

  const contextValue = {
    garmentColumns,
    clientDatasetViewConfig,
    clientCatalogueViewConfig,
    clientSingleGarmentViewConfig,
    allColumnsConfig
  };

  return (
    <DataViewColumnContext.Provider value={contextValue}>
      {children}
    </DataViewColumnContext.Provider>
  );
};
