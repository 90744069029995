// These are the themes for brands (e.g. TRUSS, GOAT, Depop) which are used in src/contexts/StyleContext.js. They contain Palette and Component styling

/*
    [TRUSS]
*/
export const theme_TRUSS_dark = {
    palette: {
        primary: {
            main: '#000000',
            offWhite: '#E3E3E3',
            offWhiteLight: '#FAFAFA'
        },
        background: {
            default: '#000000',
        },
        text: {
            primary: '#FFFFFF'
        },
    },
    components: {
        // Changing the sidebar color (https://stackoverflow.com/questions/39138380/how-to-apply-different-color-in-appbar-title-material-ui)
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#000000'
                }
            }
        }
    }
}
export const theme_TRUSS_light = {
    /*
        blank as ThemeContext.js uses (...baseTheme) 
    */
};