// src/context/DebuggerContext.js

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";

const DebuggerContext = createContext();

export const DebuggerProvider = ({ children }) => {
  const [logs, setLogs] = useState(() => {
    // Load logs from localStorage if they exist
    const savedLogs = localStorage.getItem("debuggerLogs");
    return savedLogs ? JSON.parse(savedLogs).slice(-20) : [];
  });

  const debuggerWindowRef = useRef(null);

  // Function to open or close the debugger window
  const toggleDebuggerWindow = () => {
    if (!debuggerWindowRef.current || debuggerWindowRef.current.closed) {
      openDebuggerWindow();
    } else {
      closeDebuggerWindow();
    }
  };

  // Function to open the debugger window
  const openDebuggerWindow = () => {
    debuggerWindowRef.current = window.open(
      "",
      "DebuggerWindow",
      "width=400,height=600"
    );

    debuggerWindowRef.current.document.write(`
        <html>
          <head>
            <title>Debugger</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                background-color: #1e1e1e;
                color: white;
                margin: 0;
                padding: 10px;
              }
              ul {
                list-style: none;
                padding: 0;
              }
              li {
                padding: 5px 0;
                border-bottom: 1px solid #333;
                white-space: pre-wrap;
              }
            </style>
          </head>
          <body>
            <h3>Debugger</h3>
            <ul id="logList"></ul>
            <script>
              window.addEventListener("message", (event) => {
                if (event.data.type === "ADD_LOG") {
                  const logList = document.getElementById("logList");
                  const newLog = document.createElement("li");
                  newLog.textContent = event.data.message;
                  logList.appendChild(newLog);
                  if (logList.children.length > 20) {
                    logList.removeChild(logList.firstChild);
                  }
                }
              });
  
              // Keep the window focused on reload or reopen
              window.focus();
            </script>
          </body>
        </html>
      `);

    // Re-add logs to the debugger window after it is opened or refreshed
    logs.forEach((log) => {
      debuggerWindowRef.current.postMessage(
        { type: "ADD_LOG", message: log },
        "*"
      );
    });

    debuggerWindowRef.current.addEventListener("beforeunload", () => {
      debuggerWindowRef.current = null; // Reset the ref when the window is closed
    });
  };

  // Function to close the debugger window
  const closeDebuggerWindow = () => {
    if (debuggerWindowRef.current && !debuggerWindowRef.current.closed) {
      debuggerWindowRef.current.close();
      debuggerWindowRef.current = null;
    }
  };

  // Function to add logs
  const addLog = (logMessage) => {
    const formattedMessage =
      typeof logMessage === "object"
        ? JSON.stringify(logMessage, null, 2)
        : logMessage;
    const updatedLogs = [...logs, formattedMessage].slice(-20); // Keep only the last 20 logs
    setLogs(updatedLogs);
    localStorage.setItem("debuggerLogs", JSON.stringify(updatedLogs));

    if (debuggerWindowRef.current && !debuggerWindowRef.current.closed) {
      debuggerWindowRef.current.postMessage(
        { type: "ADD_LOG", message: formattedMessage },
        "*"
      );
    }
  };

  // Effect to listen for Ctrl + Shift + D keypress
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.shiftKey && e.code === "KeyD") {
        toggleDebuggerWindow();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    // Open debugger window automatically if it was already open before refresh
    if (window.name === "DebuggerWindow") {
      openDebuggerWindow();
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [logs]);

  return (
    <DebuggerContext.Provider
      value={{
        addLog,
      }}
    >
      {children}
    </DebuggerContext.Provider>
  );
};

export const useDebugger = () => useContext(DebuggerContext);
