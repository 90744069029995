/**
 *
 * @param {Object} props
 * @param {String} props.attributeType
 * @param {Array} props.selectedAttributes
 * @param {Function} props.setSelectedAttributes
 * @returns
 * @description
 * This component is used to select and configure attributes for a given attribute type.
 *
 */
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
  Checkbox,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { getHeaders } from "../../../utils/apiUtils";
import {
  attributeScreenConfig,
  attributeTypes,
} from "../../../config/attributeTypes";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import ToggleableBox from "../../layout/Interactive/ToggleableBox";
import AttributesDAG from "./AttributesDag";

function AttributeSelectionAndConfiguration({
  attributeType,
  selectedAttributes,
  setSelectedAttributes,
}) {
  const isDescendant = (potentialParentId, childId, attributes) => {
    const children = attributes.filter(
      (attr) => attr.parent_id === potentialParentId
    );
    if (children.some((child) => child.id === childId)) {
      return true; // Direct child
    }
    return children.some((child) =>
      isDescendant(child.id, childId, attributes)
    ); // Check descendants
  };

  const ChipLabelWithIcons = ({ name, isRequired, isCarryForward }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      {isRequired ? (
        <CheckCircleOutlineIcon
          sx={{ color: palette.primary[700], fontSize: "20px" }}
        />
      ) : (
        false
      )}
      {isCarryForward ? (
        <KeyboardDoubleArrowLeftIcon
          sx={{ color: palette.primary[700], fontSize: "20px" }}
        />
      ) : (
        false
      )}
      <Typography variant="body2" component="span">
        {name}
      </Typography>
    </Box>
  );
  const { palette } = useTheme();
  const [attributes, setAttributes] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [currentAttribute, setCurrentAttribute] = useState({});
  const [parentAttribute, setParentAttribute] = useState(null);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const headers = await getHeaders();
        const response = await axios.get(
          `${BASE_URL}tools/admin/attribute-names/${attributeType}`,
          headers
        );
        setAttributes(response.data.data);
      } catch (err) {
        alert(`Failed to fetch ${attributeScreenConfig[attributeType]?.name}s`);
      }
    };
    if (attributeType) {
      fetchAttributes();
    }
  }, [attributeType]);

  const handleAddAttribute = (_, newValue) => {
    setSelectedAttributes(newValue); // newValue already contains the new array of selected options
  };

  const handleOpenDialog = (attribute) => {
    setCurrentAttribute(attribute);
    // Find parent attribute if exists
    const parent = attributes.find((attr) => attr.id === attribute.parent_id);
    setParentAttribute(parent || null);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setCurrentAttribute({}); // Clear the current attribute
    setParentAttribute(null);

    setOpenDialog(false);
  };

  const handleConfigureAttribute = () => {
    const updatedAttributes = selectedAttributes.map((attr) => {
      if (attr.id === currentAttribute.id) {
        return {
          ...attr,
          ...currentAttribute,
          parent_id: parentAttribute?.id,
        };
      } else {
        return attr;
      }
    });
    setSelectedAttributes(updatedAttributes);
    handleCloseDialog();
  };
  useEffect(() => {
    console.log(selectedAttributes);
    console.log(attributes);
  }, [selectedAttributes]);
  return (
    <ToggleableBox
      name={
        attributeScreenConfig[attributeType]?.name +
        "s" +
        `(${selectedAttributes.length})`
      }
    >
      <Box
        sx={{
          mt: 1,
          p: 1,
          borderRadius: 1,
          overflow: "auto",
        }}
      >
        <Autocomplete
          disablePortal
          options={attributes}
          multiple
          disableCloseOnSelect
          sx={{
            mt: 2,
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name || ""}
          onChange={handleAddAttribute}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Select ${attributeScreenConfig[attributeType]?.name}`}
            />
          )}
          value={selectedAttributes}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.name}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((attribute, index) => (
              <Chip
                key={attribute.id}
                label={
                  <ChipLabelWithIcons
                    name={
                      attribute.name ? attribute.name : attribute.attribute_name
                    }
                    isRequired={attribute.required}
                    isCarryForward={attribute.carry_forward}
                  />
                }
                onClick={() => handleOpenDialog(attribute)}
                onDelete={() => {
                  setSelectedAttributes(
                    selectedAttributes.filter(
                      (attr) => attr.id !== attribute.id
                    )
                  );
                }}
                sx={{
                  mr: 1,
                  mb: 1,
                  // Ensure your palette.primary[20] is defined or replace it with an existing color
                  backgroundColor: "palette.primary[20]", // This might need adjustment
                }}
                {...getTagProps({ index })}
              />
            ))
          }
        />

        <Box
          sx={{
            height: "30vh",
          }}
        >
          {false ? (
            <AttributesDAG
              attributes={selectedAttributes}
              onDoubleClickAttribute={(d) => {
                //find the attribute from the selectedAttributes
                const attribute = selectedAttributes.find(
                  (attr) => attr.id === d.id
                );
                handleOpenDialog(attribute);
              }}
            />
          ) : null}
        </Box>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Configure Attribute</DialogTitle>
          <DialogContent>
            <Autocomplete
              options={selectedAttributes.filter(
                (attr) =>
                  attr.id !== currentAttribute.id &&
                  !isDescendant(
                    currentAttribute.id,
                    attr.id,
                    selectedAttributes
                  )
              )}
              disabled={true}
              getOptionLabel={(option) => option.name}
              value={parentAttribute}
              onChange={(event, newValue) => {
                setParentAttribute(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Parent Attribute" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentAttribute.carry_forward || false}
                  onChange={(e) =>
                    setCurrentAttribute({
                      ...currentAttribute,
                      carry_forward: e.target.checked,
                    })
                  }
                />
              }
              label="Carry Forward"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentAttribute.required || false}
                  onChange={(e) =>
                    setCurrentAttribute({
                      ...currentAttribute,
                      required: e.target.checked,
                    })
                  }
                />
              }
              label="Required"
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => handleConfigureAttribute("Some Configuration")}
            >
              Save
            </Button>
            <Button onClick={handleCloseDialog}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ToggleableBox>
  );
}

export default AttributeSelectionAndConfiguration;
