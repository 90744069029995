import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography, useTheme } from "@mui/material";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LegendToggle from "@mui/icons-material/LegendToggle";
import { useNavigate } from "react-router-dom";


export const ShowLegendButton = ({ 
  action,
  disabled = false,
  active,
  title,
  sx
}) => {
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    action();
  };
  const {palette} = useTheme();
  return (
    <Box
      onClick={handleClicked}
      sx={{
        cursor: disabled ? "auto" : "pointer",
        border: `1px solid ${disabled ? palette.primary[400] : palette.primary[600]}`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        transition: "linear 0.1s",
        '&:hover .showlegend-button-icon': {
          color: !disabled && palette.primary[100],
          transition: "linear 0.1s"
        },
        '&:hover .showlegend-button-text': {
          color: !disabled && `${palette.primary[100]} !important`,
          transition: "linear 0.1s"
        },
        '&:hover': {
          backgroundColor: !disabled && palette.primary[600],
          transition: "linear 0.1s"
        },
        ...sx
      }}
    >
      <LegendToggle
        sx={{
          color: disabled ? palette.primary[400] : palette.primary[600],
          mr: 0.5,
          fontSize: 16,
        }} className="showlegend-button-icon"
      />
      
      <Typography sx={{ fontWeight: "bold", color: `${disabled ? palette.primary[400] : palette.primary[600]} !important`}} className="showlegend-button-text" variant="btn_txt_sm">
        {title}
      </Typography>
    </Box>
  );
};

export const SplitButton = ({ 
  action,
  disabled = false,
  active,
  title,
  sx
}) => {
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    action();
  };
  const {palette} = useTheme();
  return (
    <Box
      onClick={handleClicked}
      sx={{
        cursor: disabled ? "auto" : "pointer",
        border: `1px solid ${disabled ? palette.primary[400] : palette.primary[600]}`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        transition: "linear 0.1s",
        '&:hover .split-button-icon': {
          color: !disabled && palette.primary[100],
          transition: "linear 0.1s"
        },
        '&:hover .split-button-text': {
          color: !disabled && `${palette.primary[100]} !important`,
          transition: "linear 0.1s"
        },
        '&:hover': {
          backgroundColor: !disabled && palette.primary[600],
          transition: "linear 0.1s"
        },
        ...sx
      }}
    >
      {active ? (
        <CallSplitIcon
          sx={{
            color: disabled ? palette.primary[400] : palette.primary[600],
            mr: 0.5,
            fontSize: 16,
          }} className="split-button-icon"
        />
      ) : (
        <MergeTypeIcon
          sx={{
            color: disabled ? palette.primary[400] : palette.primary[600],
            mr: 0.5,
            fontSize: 16,
          }} className="split-button-icon"
        />
      )}
      <Typography sx={{ fontWeight: "bold", color: `${disabled ? palette.primary[400] : palette.primary[600]} !important`}} className="split-button-text" variant="btn_txt_sm">
        {title}
      </Typography>
    </Box>
  );
};

export const BackButton = ({ disabled = false, active, title, sx }) => {
  const navigate = useNavigate();
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    navigate(-1);
  };
  const { palette } = useTheme();
  return (
    <Box
      onClick={handleClicked}
      sx={{
        cursor: disabled ? null : "pointer",
        border: `1px solid ${
          disabled ? palette.primary[500] : palette.primary[700]
        }`,
        borderRadius: 1,
        p: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor: disabled ? palette.primary[200] : palette.primary[100],
        ...sx,
      }}
    >
      <ArrowBackIcon
        sx={{
          color: disabled ? palette.primary[500] : palette.primary[700],
          mr: 0.5,
          fontSize: 16,
        }}
      />

      <Typography
        sx={{ color: disabled ? palette.primary[500] : palette.primary[700] }}
        variant="btn_txt_sm"
      >
        {title}
      </Typography>
    </Box>
  );
};
