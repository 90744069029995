// src/router/MainRouter.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import GarmentDashboard from "../screens/admin/analytics/GarmentDashboard";
import HoursWorkedPivotTable from "../screens/admin/analytics/HoursWorkedPivotTable";
import AggregationLinkingProgressPivotTable from "../screens/admin/analytics/aggregation/AggregationLinkingProgressPivotTable";
import AggregationAnalyticsDashboard from "../screens/admin/analytics/aggregation/AggregationAnalyticsDashboard";
import AggregationGroupingProgressPivotTable from "../screens/admin/analytics/aggregation/AggregationGroupingProgressPivotTable";
import CataloguingAnalyticsDashboard from "../screens/admin/analytics/cataloguing/CataloguingAnalyticsDashboard";
import CataloguingProgressPivotTable from "../screens/admin/analytics/cataloguing/CataloguingProgressPivotTable";
import AggregationGroupingUserProgressPivotTable from "../screens/admin/analytics/aggregation/AggregationGroupingUserProgressPivotTable";
import CataloguingUserProgressPivotTable from "../screens/admin/analytics/cataloguing/CataloguingUserProgressPivotTable";
import AggregationLinkingUserProgressPivotTable from "../screens/admin/analytics/aggregation/AggregationLinkingUserProgressPivotTable";
import ImageTaggingUserProgressPivotTable from "../screens/admin/analytics/imageTagging/ImageTaggingDatasetProgressPivotTable";
import ImageTaggingAnalyticsDashboard from "../screens/admin/analytics/imageTagging/ImageTaggingAnalyticsDashboard";

const AnalyticsRoutes = () => {
  return (
    <Routes>
      <Route path="/garment/:id" element={<GarmentDashboard />} />
      <Route path="/hours-worked" element={<HoursWorkedPivotTable />} />
      <Route path="/aggregation" element={<AggregationAnalyticsDashboard />} />
      <Route path="/aggregation/linking-progress" element={<AggregationLinkingProgressPivotTable fillPage={true}/>} />
      <Route path="/aggregation/linking-user-speed" element={<AggregationLinkingUserProgressPivotTable fillPage={true}/>} />
      <Route path="/aggregation/grouping-progress" element={<AggregationGroupingProgressPivotTable fillPage={true}/>} />
      <Route path="/aggregation/grouping-user-speed" element={<AggregationGroupingUserProgressPivotTable fillPage={true}/>} />
      <Route path="/cataloguing" element={<CataloguingAnalyticsDashboard />} />
      <Route path="/cataloguing/cataloguing-progress" element={<CataloguingProgressPivotTable fillPage={true}/>} />
      <Route path="/cataloguing/user-speed" element={<CataloguingUserProgressPivotTable fillPage={true}/>} />
      <Route path="/tagging/image-tagging/user-speed" element={<ImageTaggingUserProgressPivotTable fillPage={true}/>} />
      <Route path="/tagging/image-tagging" element={<ImageTaggingAnalyticsDashboard />} />
    </Routes>
  );
};

export default AnalyticsRoutes;
