import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import { attributeTypes } from "../../../config/attributeTypes";
import { useAttributeType } from "../../../contexts/AttributeTypeContext";
import GroupConfigurationNavigationWidget from "../../../components/navigation/widgets/GroupConfigurationNavigationWidget";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import { EditButton } from "../../../components/ui/buttons/TableButtons";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import StyledDataGrid from "../../../components/ui/dataViews/StyledDataGrid";

function DetailPanelContent({ row: rowProp }) {
  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <Typography variant="h3" gutterBottom component="div">
        {rowProp.group_name}
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        {rowProp.description}
      </Typography>

      <StyledDataGrid
        density="compact"
        columns={[
          { field: "id", headerName: "id", flex: 1 },
          { field: "attribute_name", headerName: "Name", flex: 1 },
          { field: "required", headerName: "Required", flex: 1 },
          { field: "carry_forward", headerName: "Carry Forward", flex: 1 },
        ]}
        rows={rowProp.attributes}
        sx={{ flex: 1 }}
        hideFooter
      />
    </Box>
  );
}

function AttributeGroupDashboard() {
  const { attributeType, attributeScreenConfig } = useAttributeType();

  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 300, []);

  useEffect(() => {
    if (!attributeType) return;
    const fetchUsers = async () => {
      let headers = await getHeaders();
      axios
        .get(
          `${BASE_URL}tools/admin/attribute-groups/${attributeType}/?extended=true`,
          headers
        )
        .then((res) => {
          // Reset form fields (optional)
          console.log(res.data.data);
          setUsers(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          showAlert(err);
        });
    };
    fetchUsers();
  }, [attributeType]);

  const columns = [
    {
      field: "group_name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <EditButton onEdit={() => navigate(`create/${params.row.id}`)} />
      ),
    },

    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        width: "100%",
        padding: 2,
        paddingTop: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h2">
          {attributeScreenConfig?.name} Groups Dashboard
        </Typography>
        <GroupConfigurationNavigationWidget
          new_button_text={`New ${attributeScreenConfig?.name} Group`}
          show_groups={false}
        />
      </Box>

      <StyledDataGrid
        rows={users}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={5}
        disableRowSelectionOnClick
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
    </Box>
  );
}

export default AttributeGroupDashboard;
