import { fetchAuthSession } from "@aws-amplify/auth";

export const getCredentials = async () => {
  try {
    console.log("Getting Credentials from Amplify Auth");

    const session = await fetchAuthSession();
    const { accessToken, idToken } = session.tokens ?? {};

    const credentials = {
      access: accessToken,
      id: idToken,
    };

    return credentials;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getHeaders = async () => {
  const credentials = await getCredentials();
  if (!credentials) {
    console.error("Failed to get credentials");
    return null;
  }

  // Construct headers according to the standard Bearer token scheme
  return {
    headers: {
      Authorization: `Bearer ${credentials.id}`, // Use ID token for Authorization header
      // You might not need the 'accesstoken' header if you use the standard Authorization header.
      // If your backend explicitly needs it, uncomment the line below.
      accesstoken: credentials.access,
    },
  };
};
