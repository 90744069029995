import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Container } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { useNavigate, useParams } from "react-router-dom";
import CustomLoadingModal from "../../../components/common/userFeedback/CustomLoadingModal";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";

const CreateDatasetScreen = () => {
  const { id } = useParams(); // Retrieve the ID from the URL if present
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  // Include description in formData state
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (id) {
      fetchDatasetDetails(id);
    }
  }, [id]);

  const fetchDatasetDetails = async (id) => {
    try {
      const headers = await getHeaders();
      const response = await axios.get(
        `${BASE_URL}tools/admin/datasets/${id}`,
        headers
      );
      const { name, description } = response.data.data;
      setFormData({
        name,
        description,
      });
    } catch (error) {
      showAlert(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const headers = await getHeaders();
    const url = `${BASE_URL}tools/admin/datasets/dataset${id ? `/${id}` : ""}`;

    axios
      .post(url, formData, headers)
      .then((response) => {
        showAlert(response);
        setSaving(false);
      })
      .catch((error) => {
        showAlert(error);
      });
  };

  return (
    <>
      <CustomLoadingModal
        waitToShowModal={saving}
        title="Dataset Saved"
        body={`Dataset Saved Successfully!`}
        onDismiss={() => navigate(-1)}
      />
      <Container maxWidth="sm">
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Typography variant="h3">{id ? "Edit" : "Create"} Dataset</Typography>
          <TextField
            required
            fullWidth
            id="name"
            label="Dataset Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
            {id ? "Update" : "Create"}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default CreateDatasetScreen;
