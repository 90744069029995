// src/App.js
import React, { useEffect } from "react";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { theme } from "./theme/BaseTheme";
import { ThemeContext, ThemeStylingProvider } from "./contexts/ThemeContext";
import {
  AmplitudeTrackingContext,
  AmplitudeTrackingProvider,
} from "./contexts/AmplitudeTrackingContext";
import MainRouter from "./routes/MainRouter";
import LoginScreen from "./screens/auth/LoginScreen";
import {
  eventTrackingAPIKey_Amplitude,
  eventTrackingSecretKey_Amplitude,
  getEventTrackingOptions,
} from "./config/eventTracking/eventTrackingConfig";
import DebugComponent from "./components/common/userFeedback/DebugComponent/DebugComponent";
import { DebuggerProvider } from "./contexts/DebuggerContext";

const AppContent = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <MainRouter /> : <LoginScreen />;
};

const eventTrackingOptions = getEventTrackingOptions("Amplitude");

function App() {
  return (
    <DebuggerProvider>
      <AuthProvider>
        <ThemeStylingProvider theme={theme}>
          <AmplitudeTrackingProvider
            apiKey={eventTrackingAPIKey_Amplitude}
            secretApiKey={eventTrackingSecretKey_Amplitude}
            options={eventTrackingOptions}
          >
            <AppContent />
          </AmplitudeTrackingProvider>
        </ThemeStylingProvider>
      </AuthProvider>
    </DebuggerProvider>
  );
}

export default App;
