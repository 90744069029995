// src/config/jobTypes.js

export const jobTypes = {
  TYPE: "TYPE",
  IMAGE_TAGGING: "IMAGE_TAGGING",
  IMAGE_TAGGING_QA: "IMAGE_TAGGING_QA",
  STYLE: "STYLE",
  TYPE_QA: "TYPE_QA",
  STYLE_QA: "STYLE_QA",
  STYLE_MAGNETS: "STYLE_MAGNETS",
};

export const jobNamesMap = {
  manual_image_tagging: "IMAGE_TAGGING",
  type_manual_tagging: "TYPE",
  type_manual_qa: "TYPE_QA",
  manual_image_qa: "IMAGE_TAGGING_QA",
  style_manual_tagging: "STYLE",
  style_manual_qa: "STYLE_QA",
  style_manual_tagging_magnets: "STYLE_MAGNETS",
};

export const jobQaMap = {
  [jobTypes.TYPE_QA]: ["type_manual_tagging"],
  [jobTypes.STYLE_QA]: ["style_manual_tagging", "style_manual_tagging_magnets"],
  [jobTypes.IMAGE_TAGGING_QA]: ["manual_image_tagging"],
};
