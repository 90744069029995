// src/routes/AdminRoutes.jsx
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import HoursWorkedPivotTable from "../screens/admin/analytics/HoursWorkedPivotTable";
import PipelineJobsDashboard from "../screens/admin/analytics/pipelineJobs/PipelineJobsDashboard";
import PipelineJobsPivotTable from "../screens/admin/analytics/pipelineJobs/PipelineJobsPivotTable";
import CreateUserGroupScreen from "../screens/admin/users/CreateUserGroupScreen";
import OnboardingScreen from "../screens/admin/users/OnboardingScreen";
import UserDashboard from "../screens/admin/users/UserDashboard";
import UserGroupsDashboard from "../screens/admin/users/UserGroupsDashboard";
import AttributeManagementRoutes from "./AttributeManagementRoutes";
import DatasetRoutes from "./DatasetsRoutes";
import AnalyticsRoutes from "./AnalyticsRoutes";
import PipelineRoutesMain from "./PipelineRoutes";
import PipelineJobsDataView from "../screens/admin/analytics/pipelineJobs/PipelineJobsDataView";
import AggregationQATool from "../screens/tools/AggregationTool/AggregationQATool";
import CataloguingTool from "../screens/tools/CataloguingTool/CataloguingTool";
import AutomatedTagging from "../screens/admin/demos/AutomatedTagging";

const AdminRoutes = () => {
  const { isAdmin } = useAuth();

  if (!isAdmin) {
    // Redirect non-admin users
    alert("You are not an admin");
    return <Navigate to="/" replace />;
  }

  return (
    <Routes>
      <Route path="users" element={<UserDashboard />} />
      <Route path="users/create" element={<OnboardingScreen />} />
      <Route path="users/groups" element={<UserGroupsDashboard />} />
      <Route path="users/groups/create" element={<CreateUserGroupScreen />} />
      <Route
        path="attribute-management/*"
        element={<AttributeManagementRoutes />}
      />
      <Route path="dataset-management/*" element={<DatasetRoutes />} />
      <Route path="pipelines/*" element={<PipelineRoutesMain />} />
      <Route
        path="pipeline-jobs-analytics"
        element={<PipelineJobsDashboard />}
      />
      <Route
        path="pipeline-jobs-analytics/dataview/:id?"
        element={<PipelineJobsDataView />}
      />
      <Route
        path="pipeline-jobs-analytics/analytics/:id?"
        element={<PipelineJobsPivotTable />}
      />
      <Route path="analytics/*" element={<AnalyticsRoutes />} />
      <Route path="aggregation/qa" element={<AggregationQATool />} />
      <Route
        path="cataloguing/groups"
        element={<CataloguingTool config={"groups"} />}
      />
      <Route
        path="cataloguing/slipstream"
        element={<CataloguingTool config={"slipstream"} />}
      />
      <Route path="automated-tagging" element={<AutomatedTagging />} />
    </Routes>
  );
};

export default AdminRoutes;
