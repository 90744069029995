// src/components/layout/LayoutWithSidebar.js
import { Box } from "@mui/material";
import React, { useState } from "react";
import AppBarComponent, { APP_BAR_HEIGHT } from "./AppBarComponent";
import SideDrawer from "./SideDrawer";

const LayoutWithSidebar = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBarComponent handleDrawerToggle={handleDrawerToggle} />
      <SideDrawer open={drawerOpen} handleDrawerClose={handleDrawerToggle} />
      <main>
        <Box
          style={{
            paddingTop: APP_BAR_HEIGHT,
          }}
        >
          {/* You can add additional layout styling or components here */}
          {children}
        </Box>
      </main>
    </>
  );
};

export default LayoutWithSidebar;
