import React, { useEffect, useState } from "react";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { getHeaders } from "../../../utils/apiUtils";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import { showAlert } from "../../common/userFeedback/CustomAlert";
function PipelineDetails(props) {
  const { name, setName, priority, setPriority, datasets, setDatasets } =
    usePipelineContext();
  const [allDatasets, setAllDatasets] = useState([]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };
  const handleDatasetChange = (event) => {
    setDatasets(event.target.value);
  };
  const fetchDatasets = async () => {
    const headers = await getHeaders();
    const response = await axios
      .get(`${BASE_URL}tools/admin/datasets/listall`, headers)
      .then((response) => {
        setAllDatasets(response.data.data);
      })
      .catch((error) => {
        showAlert(error);
      });
  };
  useEffect(() => {
    fetchDatasets();
  }, []);

  return (
    <Box
      component="form"
      sx={{
        height: "100%",
        width: "100%",
        padding: 2,
        paddingTop: 2,
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Typography variant="h2">Create/Update Pipeline</Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Pipeline Name"
        name="name"
        value={name}
        onChange={handleNameChange}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel shrink={true} id="dataset-label">
          Dataset
        </InputLabel>
        <Select
          labelId="dataset-label"
          id="dataset"
          label="Dataset"
          notched={true}
          value={datasets}
          onChange={handleDatasetChange}
        >
          {allDatasets.map((dataset) => (
            <MenuItem value={dataset.id}>{dataset.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="priority-label">Priority</InputLabel>
        <Select
          labelId="priority-label"
          id="priority"
          value={priority}
          label="Priority"
          onChange={handlePriorityChange}
        >
          <MenuItem value="High">High</MenuItem>
          <MenuItem value="Medium">Medium</MenuItem>
          <MenuItem value="Low">Low</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default PipelineDetails;
