
//Gets average sold price - filters out null and 0 values
export const getAverageSoldPrice = (soldPrices) => {
    let allSoldPrices = soldPrices;
    let averageSoldPrice = null;
    if (allSoldPrices && allSoldPrices.length > 0)
    {
      allSoldPrices = allSoldPrices.filter(x => x != null && x !== 0)
      if (allSoldPrices.length > 0)
      {
        averageSoldPrice = (allSoldPrices.reduce((partialSum, a) => partialSum + a, 0)/allSoldPrices.length).toFixed(2);
      }
    }
    return averageSoldPrice;
  }
  
//Returns a boolean of if the average time to sell is valid; "possibly remove all the ones that are less than a day coz they're likely re-lists or something"
export const isValidAverageTimeToSell = (averageTimeToSell) => {
    return averageTimeToSell && averageTimeToSell > 1
  }
  