import React, { useState } from "react";
import { Box, Typography, IconButton, Collapse, useTheme } from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  TroubleshootSharp,
} from "@mui/icons-material";

function ToggleableBox({ name, children }) {
  const [isOpen, setIsOpen] = useState(TroubleshootSharp); // State to track open/closed
  const { palette } = useTheme();

  const toggleOpen = () => {
    setIsOpen(!isOpen); // Toggle the state
  };

  return (
    <Box
      sx={{
        mt: 1,
        p: 1,
        borderRadius: 1,
        overflow: "auto",
        border: `1px solid ${palette.primary[500]}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h8">{name}</Typography>
        <IconButton onClick={toggleOpen}>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {/* Collapse component controls the opening and closing animation */}
      <Collapse in={isOpen}>{children}</Collapse>
    </Box>
  );
}

export default ToggleableBox;
