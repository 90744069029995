// src/context/AttributeTypeContext.js

import React, { createContext, useContext, useState } from "react";

const AttributeTypeContext = createContext();

export const AttributeTypeProvider = ({ children }) => {
  const [attributeType, setAttributeType] = useState(null);
  const [attributeScreenConfig, setAttributeScreenConfig] = useState(null);

  return (
    <AttributeTypeContext.Provider
      value={{
        attributeType,
        setAttributeType,
        setAttributeScreenConfig,
        attributeScreenConfig,
      }}
    >
      {children}
    </AttributeTypeContext.Provider>
  );
};

export const useAttributeType = () => useContext(AttributeTypeContext);
