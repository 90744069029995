import {
    Autocomplete,
    Avatar,
    Box,
    Grid,
    Divider,
    Typography,
    useTheme
  } from "@mui/material";

const StyledCard = (props) => {
    const theme = useTheme();
    const titleVariant = props.titleVariant ?? "h2"

    return (
        <Box 
        sx={{
            height: "100%",
            width: "100%",
            minWidth: "100px",
            flexDirection: "column",
            border: 1,
            borderColor: theme.palette.primary[300],
            borderRadius: 2,
            color: theme.palette.primary[500],
            backgroundColor: theme.palette.primary[100],
            ...props.sx,
        }}>
            <Box sx={{
                padding: 2,
                borderRadius: "inherit",
                backgroundColor: theme.palette.primary[200],
            }}>
                <Grid container justifyContent={"space-between"} spacing={2}>
                    <Grid item>
                        <Typography variant={titleVariant} sx={{color: theme.palette.primary[500], ...props.sxHeaderTitle}}>{props.title}</Typography>
                    </Grid>
                    
                    <Grid item sx={{my: -0.5}}>
                        {props.headerChildren}
                    </Grid>
                </Grid>
            </Box>
            
            {/* https://stackoverflow.com/questions/69682476/material-ui-divider-thickness */}
            <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 2}}/>
            
            <Box sx={{
                padding: props.bodyPadding ?? 2,
                borderRadius: "inherit"
            }}>
                {props.children}
            </Box>
        </Box>
    )
}

export default StyledCard;