import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import ImageScrollPreview from "./Images/ImageScrollPreview";
import ScrollImageModal from "../../common/userFeedback/modals/ScrollImageModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "50vh",
  overflow: "scroll",
};

const MassImageApprovalsFrame = ({
  garments,
  rejectedTags,
  setRejectedTags,
  disabled = false,
  handleControlClick = () => {},
  reject = true,
  preselectAll = false,
  preDeselectAll = false,
  numberOfColumns = 6,
  toolType,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedGarment, setSelectedGarment] = useState(null);
  const [additionalData, setAdditionalData] = useState([]);
  const handleOpen = (garment) => {
    setSelectedGarment(garment);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {}, [preselectAll, preDeselectAll]);

  return (
    <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Grid container sx={{ width: "100%", marginRight: 0 }}>
        {garments.map((garment) => {
          const isSelected = rejectedTags.some(
            (tag) => tag.id === garment.garment_id
          );
          return (
            <Grid
              item
              xs={12 / (numberOfColumns + 1)}
              key={garment.garment_id}
              onClick={(e) => {
                if ((e.ctrlKey || e.metaKey) && e.shiftKey == false) {
                  // Your specific function for control-click
                  handleControlClick(garment.garment_id);
                } else {
                  if (disabled) {
                    return;
                  }
                  if (isSelected) {
                    setRejectedTags(
                      rejectedTags.filter(
                        (tag) => tag.id !== garment.garment_id
                      )
                    );
                  } else {
                    setRejectedTags([
                      ...rejectedTags,
                      {
                        id: garment.garment_id,
                        proposed_timestamp: getCurrentTimestamp(),
                      },
                    ]);
                  }
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                handleOpen(garment);
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",

                border: isSelected
                  ? reject
                    ? "3px solid red"
                    : "3px solid green"
                  : "1px solid black",
                m: 0.5,
                maxHeight: "12rem",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              <ImageScrollPreview
                images={garment?.images}
                image_index={garment?.image_num}
                showByIndex={garment?.image_num}
              />

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(255,255,255,0.5)",
                  p: 1,
                }}
              >
                <Typography
                  variant="h8"
                  sx={{
                    fontSize: "11px",
                  }}
                >
                  <Typography
                    variant="h8"
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    {garment.BRAND}
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "rgba(255,255,255,0.5)",
                  p: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "11px",
                  }}
                >
                  ID:{garment.garment_id}
                </Typography>
                {garment.confidence ? (
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    (x1000) {Math.round(garment.confidence * 1000)}%
                  </Typography>
                ) : null}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <ScrollImageModal
        handleClose={handleClose}
        openModal={open}
        images={selectedGarment?.images}
        sx={{}}
      />
    </Box>
  );
};

export default MassImageApprovalsFrame;
