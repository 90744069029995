import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";

import ClickableImage from "../../../components/layout/Interactive/Images/ClickableImage";
import { getHeaders } from "../../../utils/apiUtils";
import GarmentPanel from "./GarmentPanel";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import SeedGarment from "../../../components/ui/cards/SeedGarment";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import DatasetDropdown from "../../../components/forms/Dropdowns/DatasetDropdown";
import PineconeIndexDropdown from "../../../components/forms/Dropdowns/PineconeIndexDropdown";

function AggregationTool(props) {
  const theme = useTheme();
  const [garments, setGarments] = useState([]);
  const [seedGarment, setSeedGarment] = useState(null);
  const [garmentID, setGarmentID] = useState(null);
  const [publicGarmentId, setPublicGarmentId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [openSearchIndexes, setOpenSearchIndexes] = useState([]);

  const [selectedGarments, setSelectedGarments] = useState({});
  const [ignoredGarments, setIgnoredGarments] = useState({});
  const [totalAggregations, setTotalAggregations] = useState(0);
  const [totalIgnoredGarments, setTotalIgnoredGarments] = useState(0);
  const [anyDataLoading, setAnyDataLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [similarItemsDataLoading, setSimilarItemsDataLoading] = useState(false);
  const [proposalDataLoading, setProposalDataLoading] = useState(false);
  const [trainingMode, setTrainingMode] = useState(false);
  const [openSearchIndex, setOpenSearchIndex] = useState("vetements");
  const [pagesWithoutTag, setPagesWithoutTag] = useState(0);
  const [tagMadeOnPage, setTagMadeOnPage] = useState(true);
  const [numRecords, setNumRecords] = useState(0);

  const [selectedDataset, setSelectedDataset] = useState(null);
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const [numberOfColumns, setNumberOfColumns] = useState(5); // Default to 3 columns
  console.log(`anyDataLoading: ${anyDataLoading} dataLoading: ${dataLoading} similarItemsDataLoading: ${similarItemsDataLoading} proposalDataLoading: ${proposalDataLoading}`)
  //Lifecycle Methods
  useEffect(() => {
    setDataLoading(true);
    if (openSearchIndex) {
      fetchData();
    } else {
      setDataLoading(false);
    }
    return () => {
      setTotalAggregations(0);
      setSeedGarment(null);
      setGarmentID(null);
      setPage(1);
      setGarments([]);
      setSelectedGarments([]);
    };
  }, [trainingMode, openSearchIndex, selectedDataset]);

  useEffect(() => {
    setAnyDataLoading(dataLoading || similarItemsDataLoading || proposalDataLoading)
  }, [dataLoading, similarItemsDataLoading, proposalDataLoading])

  useEffect(() => {
    if (garmentID) {
      getSimiliarItems();
    }
  }, [garmentID, page]);

  useEffect(() => {
    if (Object.keys(selectedGarments).length > 0) {
      setTagMadeOnPage(true);
      setPagesWithoutTag(1);
    }
  }, [selectedGarments]);

  //API Calls
  async function getSimiliarItems() {
    setSimilarItemsDataLoading(true);
    const headers = await getHeaders();
    let data = {
      garment_id: publicGarmentId,
      offset: (page - 1) * pageSize,
      page_size: pageSize,
      index: openSearchIndex,
    };
    setGarments([]);

    await axios
      .post(`${BASE_URL}tools/aggregation/querypinecone`, data, headers)
      .then((res) => {
        setGarments(res.data.data);
        //set ignored garments to all returned garments
        let newIgnoredGarments = Object.assign({}, ignoredGarments);
        res.data.data.forEach((garment, index) => {
          newIgnoredGarments[garment.garment_id] = {
            score: garment.score,
            rank: index + 1 + (page - 1) * pageSize,
            timestamp: new Date().toISOString(),
          };
        });
        setIgnoredGarments(newIgnoredGarments);
        setSimilarItemsDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        setPagesWithoutTag(3);
        setSimilarItemsDataLoading(false);
      });
  }
  async function fetchData() {
    const start = Date.now();
    let end = Date.now();
    const headers = await getHeaders();
    setSeedGarment(null);
    setGarmentID(null);
    setGarments([]);
    setPage(1);
    setSelectedGarments([]);
    setTagMadeOnPage(false);
    setPagesWithoutTag(0);
    let url = `${BASE_URL}tools/aggregation/next-item/training`;
    if (!trainingMode) {
      url = `${BASE_URL}tools/aggregation/next-item/basic/${openSearchIndex}?garment_count=${numRecords}`;
      if (selectedDataset?.id) {
        url += `&dataset=${selectedDataset.id}`;
      }
    }
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 204) {
          console.log("No more data");
          setTrainingMode(false);
          return;
        }
        const { num_images, uuid_public_id } = res.data.data;
        let newImages = [];
        for (let i = 0; i < num_images; i++) {
          const prefix =
            "https://scraped-garment-images.s3.eu-west-2.amazonaws.com/";
          const suffix = `${uuid_public_id}-${i + 1}.jpg`;
          newImages.push(prefix + suffix);
        }
        console.log(`AGGTOOL: ${JSON.stringify(res.data.data)}`)
        setSeedGarment({ ...res.data.data, images: newImages });
        setPublicGarmentId(res.data.data.uuid_public_id);
        setGarmentID(res.data.data.garment_id);
        end = Date.now();
        console.log(`Execution time: ${end - start} ms`);
        setProposalDataLoading(false);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        end = Date.now();
        console.log(`Execution time: ${end - start} ms`);
        setProposalDataLoading(false);
        setDataLoading(false);
      });
  }

  //Handlers
  const handleNext = () => {
    if (!tagMadeOnPage) {
      setPagesWithoutTag(pagesWithoutTag + 1);
    }
    setTagMadeOnPage(false);
    setPage(page + 1);

    trackEvent("AggregationNextPage", {
      index: openSearchIndex,
      datasetId: selectedDataset?.id,
      garmentId: garmentID,
      aggregations: Object.keys(selectedGarments).length,
      totalAggregations: totalAggregations,
      selectedGarmentIds: Object.keys(selectedGarments),
      ignoredGarmentIds: Object.keys(ignoredGarments),
      jobId: 17,
      jobName: "aggregation",
      toolType: "AGGREGATION",
      toolName: "AggregationTool",
      qa: false,
      pages: page,
    });
  };

  const handleSave = async () => {
    setProposalDataLoading(true);
    const data = {
      garment_id: garmentID,
      job_id: 17,
      selected_garments: selectedGarments,
      ignored_garments: ignoredGarments,
    };
    const headers = await getHeaders();

    if (selectedGarments.length === 0) {
      await fetchData();
      return;
    }
    await axios
      .post(`${BASE_URL}tools/aggregation/classify`, data, headers)
      .then(async (res) => {
        setSelectedGarments({});
        setIgnoredGarments({});
        fetchData();
        setTotalAggregations(
          (prev) => prev + (selectedGarments && Object.keys(selectedGarments) ? Object.keys(selectedGarments).length : 0)
        );
        setTotalIgnoredGarments(
          (prev) => prev + (garments - Object.keys(selectedGarments).length)
        );

        trackEvent("Aggregation", {
          index: openSearchIndex,
          datasetId: selectedDataset?.id,
          garmentId: garmentID,
          aggregations: Object.keys(selectedGarments).length,
          totalAggregations: totalAggregations,
          selectedGarmentIds: Object.keys(selectedGarments),
          ignoredGarmentIds: Object.keys(ignoredGarments),
          jobId: 17,
          jobName: "aggregation",
          toolType: "AGGREGATION",
          toolName: "AggregationTool",
          qa: false,
          skipped: false,
        });
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        setProposalDataLoading(false);
      });
      
  };
  const handleSkip = async () => {
    setProposalDataLoading(true);
    const data = {
      garment_id: garmentID,
      job_id: 17,
    };
    const headers = await getHeaders();

    await axios
      .post(`${BASE_URL}tools/aggregation/skip`, data, headers)
      .then((res) => {
        setSelectedGarments({});
        setIgnoredGarments({});
        fetchData();
        setTotalAggregations(totalAggregations + (selectedGarments ? selectedGarments.length : 0));

        trackEvent("Aggregation", {
          index: openSearchIndex,
          datasetId: selectedDataset?.id,
          garmentId: garmentID,
          aggregations: Object.keys(selectedGarments).length,
          totalAggregations: totalAggregations,
          selectedGarmentIds: Object.keys(selectedGarments),
          ignoredGarmentIds: Object.keys(ignoredGarments),
          jobId: 17,
          jobName: "aggregation",
          toolType: "AGGREGATION",
          toolName: "AggregationTool",
          qa: false,
          skipped: true,
        });
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        setProposalDataLoading(false);
      });
  };

  //Renderers
  const renderGarment = (garment, index) => {
    return (
      <GarmentPanel
        garment={garment}
        xs={12 / numberOfColumns} // Dynamic xs based on the number of columns
        setSelectedGarments={setSelectedGarments}
        setIgnoredGarments={setIgnoredGarments}
        ignoredGarments={ignoredGarments}
        selectedGarments={selectedGarments}
        index={index + 1 + (page - 1) * pageSize} // Calculate index based on page and page size
      />
    );
  };

  return (
    <Box
      sx={{
        height: `calc(100vh-${APP_BAR_HEIGHT}px)`,
        p: 2,
        pt: 0
      }}
    >
      <LoadingOverlay open={anyDataLoading} />
      <Grid
        container
        sx={{
          p: 1,
          pt: 0,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 2
            }}
          >
            <Typography variant="h2">Aggregation Tool</Typography>
            <Typography
              variant="h3"
              sx={{
                marginLeft: 2,
                marginRight: 2,
              }}
            >
              {trainingMode ? "Training Mode" : "Basic Mode"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 2
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleNext}
              sx={{ color: theme.palette.primary[100] }}
              disabled={anyDataLoading || pagesWithoutTag == 3}
            >
              Next Page
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginLeft: 2,
              }}
              onClick={() => {
                if (Object.keys(selectedGarments).length > 0) {
                  handleSave();
                } else {
                  handleSkip();
                }
              }}
              disabled={anyDataLoading || pagesWithoutTag < 3}
            >
              New Garment
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            pb: 2,
          }}
        >
          <DatasetDropdown
            dataset={selectedDataset}
            setDataset={setSelectedDataset}
            sx={{ mr: 2 }}
          />
          <PineconeIndexDropdown
            index={openSearchIndex}
            setIndex={setOpenSearchIndex}
          />
          <Select
            value={numberOfColumns}
            onChange={(e) => setNumberOfColumns(e.target.value)}
            displayEmpty
            sx={{ ml: 2 }}
          >
            <MenuItem value={4}>4 Column</MenuItem>
            <MenuItem value={5}>5 Columns</MenuItem>
            <MenuItem value={6}>6 Columns</MenuItem>
            <MenuItem value={7}>7 Columns</MenuItem>
            <MenuItem value={8}>8 Columns</MenuItem>
          </Select>
          <Divider
            sx={{
              m: 1,
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={0.5}>
            {garments.map((garment, index) => renderGarment(garment, index))}
          </Grid>
        </Grid>

        {!anyDataLoading && !seedGarment ? (
          <Grid item xs={12}>
            <NoDataScreenCover />
          </Grid>
        ) : (
          <>
            <Grid item xs={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  pl: 2,
                }}
              >
                <SeedGarment garment={seedGarment} />
                <Typography variant="body_sm">
                  Session Count: {totalAggregations}
                </Typography>
                <Typography variant="body_sm">
                  Currently Selected: {Object.keys(selectedGarments).length}
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider
          sx={{
            m: 1,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            p: 1,
          }}
        >
          <Typography>Page: {page}</Typography>
          <Typography>Matched Garments: {totalAggregations}</Typography>
          <Typography>Rejected Garments: {totalIgnoredGarments}</Typography>
        </Box>
      </Grid>
    </Box>
  );
}

export default AggregationTool;
