import React from "react";
import { Box, Typography, Button } from "@mui/material";

const NavigationStepper = ({ currentScreen, setCurrentScreen, numScreens }) => {
  const steps = Array.from({ length: numScreens }, (_, i) => i + 1);

  const handleStepClick = (stepIndex) => {
    setCurrentScreen(stepIndex);
  };

  const isStepActive = (index) => currentScreen === index;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        py: 2,
        pl: 2,
        pr: 2,
      }}
    >
      {/* Line before first step */}
      <Box
        sx={{
          height: "3px",
          flex: 1,
          bgcolor: "primary.main",
        }}
      />

      {steps.map((step, index) => (
        <>
          {/* Step Button */}
          <Button
            key={step}
            onClick={() => handleStepClick(index)}
            sx={{
              mx: 1,
              minWidth: "40px",
              minHeight: "40px",
              borderRadius: 2,
              backgroundColor: isStepActive(index)
                ? "primary.main"
                : "grey.300",
              color: "white",
              "&:hover": {
                backgroundColor: isStepActive(index)
                  ? "primary.dark"
                  : "grey.400",
              },
            }}
          >
            {step}
          </Button>

          {/* Line between steps or after last step */}
          <Box
            sx={{
              flex: index === steps.length - 1 ? 1 : 2, // Adjust line length based on position
              bgcolor: index < currentScreen ? "primary.main" : "grey.400",
              height: index < currentScreen ? "3px" : "1px",
            }}
          />
        </>
      ))}
    </Box>
  );
};

export default NavigationStepper;
