import {
    parseISO,
    format,
    startOfQuarter,
    addQuarters,
    isBefore,
} from "date-fns";
import { Box, Typography } from "@mui/material";


// Function to format a date as "Month Day(th/st/nd/rd) Year"
export const formatDateToReadableString = (dateObj) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();
  
    // Determine the correct suffix for the day
    const suffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Special case for 11th, 12th, 13th
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };
  
    return `${month} ${day}${suffix(day)} ${year}`;
  }

// Function to generate quarters between two dates; 
//  generates them in format ["2024-01", "2024-02", "2024-03", "2024-04", "2025-01"]
export const generateQuarters = (start, end, dateFormat="yyyy-QQ") => {
    const quarters = [];
    let current = startOfQuarter(start);

    while (isBefore(current, end) || current.getTime() === end.getTime()) {
        quarters.push(format(current, dateFormat));
        current = addQuarters(current, 1);
    }

    return quarters;
};

export const generateFormattedQuarters = (start, end) => {
    const quarters = generateQuarters(start, end);
    return quarters.map(quarter => {
        quarter.replace("-01", " Jan")
        quarter.replace("-02", " Apr")
        quarter.replace("-03", " Jul")
        quarter.replace("-04", " Oct")
    })
}

//Change Q format from "2018-01" to "Jan 2018"
export const formatQuarter = (quarter) => {
    quarter = quarter.replace("-01", "Jan")
    quarter = quarter.replace("-02", "Apr")
    quarter = quarter.replace("-03", "Jul")
    quarter = quarter.replace("-04", "Oct")
    quarter = `${quarter.substr(4)} ${quarter.substr(0, 4)}`;
    return quarter;
}

export function getCurrentTimestamp() {
  const date = new Date();
  const sqlTimestamp = date.toISOString().slice(0, 23).replace("T", " ");
  return sqlTimestamp;
}

export const getFormattedTimestamp = (timestamp) => {
  const formattedTimestamp = timestamp.slice(0, 16).replace("T", " ");
  return formattedTimestamp;
}


export const getTimeToSellObj = (time) => {
    const yearDays = 365;
    const monthDays = 30;

    if (!time)
    {
        time = 0
    }

    let timeFraction = Number(time);
    timeFraction = Math.floor(timeFraction);
    let days = timeFraction;
    let months = 0;
    let years = 0;
    let timeObj = {
        days: days,
        months: months,
        years: years
    }
    
    if (time)
    {
    
        years = Math.floor(days / yearDays);
        days = days % yearDays;
    
        months = Math.floor(days / monthDays);
        days = Math.floor(days % monthDays); // Ignore the decimal part of days
    
        let result = "";
        if (years > 0) result += `${years}y `;
        if (months > 0) result += `${months}m `;
        if (days > 0) result += `${days}d`;

        timeObj = {
            days: days,
            months: months,
            years: years
        }
    }
  
    return timeObj;
  }

  
export const formatTimeToSell = (time) => {
    const timeObj = getTimeToSellObj(time);
    
    return `${timeObj.years > 0 ? `${timeObj.years}y ` : ''}${timeObj.months > 0 ? ` ${timeObj.months}m ` : ''}${timeObj.days}d`;
}
  
export const getFormattedTimeToSellComponent = (time, sxSuffix) => {
    const timeObj = getTimeToSellObj(time);
    const formattedTimeToSell = formatTimeToSell(time)
  
    return time ? <Typography display="inline">
        {timeObj.years > 0
        ? <>
            {timeObj.years}
            <Typography
                display="inline"
                sx={{ ml: 0, ...sxSuffix, mr: (timeObj.years > 0) && timeObj.months === 0 && timeObj.days === 0 ? 0 : 0.5 }}
            >
                {`y`}
            </Typography>
          </>
        : <></>}
        {timeObj.months > 0
        ? <>
            {timeObj.months}
            <Typography
                display="inline"
                sx={{ ml: 0, ...sxSuffix, mr: timeObj.months > 0 && timeObj.days === 0 ? 0 : 0.5 }}
            >
                {`m`}
            </Typography>
          </>
        : <></>}
        {(timeObj.years > 0 || timeObj.months > 0) && timeObj.days === 0
        ? <></>
        : <>
            {timeObj.days}
            <Typography
                display="inline"
                sx={{ ml: 0, ...sxSuffix }}
            >
                {`d`}
            </Typography>
        </>}
    </Typography> : <></>;
}
  