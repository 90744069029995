import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function GroupConfigurationNavigationWidget({
  new_button_text,
  show_groups = true,
  sx={}
}) {
  const navigate = useNavigate();
  return (
    <Box sx={sx}>
      {show_groups && (
        <Button
          variant="angular"
          color="secondary"
          sx={{ mr: 1 }}
          name="groups"
          onClick={() => {
            navigate("groups");
          }}
        >
          Groups
        </Button>
      )}
      <Button
        variant="angular"
        color="primary"
        onClick={() => {
          navigate("create");
        }}
      >
        {new_button_text}
      </Button>
    </Box>
  );
}

export default GroupConfigurationNavigationWidget;
