import React from "react";
import { IconButton, Typography, Box } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const ImageIndexButtons = ({ index, setIndex }) => {
  const handleDecrement = () => {
    if (index > 1) {
      setIndex(index - 1);
    }
  };

  const handleIncrement = () => {
    setIndex(index + 1);
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={handleDecrement} aria-label="decrement">
        <ArrowLeftIcon />
      </IconButton>
      <Typography variant="h6" sx={{ mx: 2 }}>
        {index}
      </Typography>
      <IconButton onClick={handleIncrement} aria-label="increment">
        <ArrowRightIcon />
      </IconButton>
    </Box>
  );
};

export default ImageIndexButtons;
