import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Button,
  Collapse,
  useTheme,
  Box,
} from "@mui/material";
import { ViewButton } from "./buttons/TableButtons";

function HideableTable({ rows, columns, containerStyle }) {
  const [isVisible, setIsVisible] = useState(false);
  const { palette } = useTheme();

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box sx={containerStyle}>
      <Button
        onClick={handleToggleVisibility}
        variant="outlined"
        sx={{ mb: 1 }}
      >
        {isVisible ? "Hide Details" : "Show Details"}
      </Button>
      <Collapse in={isVisible} timeout="auto" unmountOnExit>
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "#f5f5f5", // Slightly darker background color
            borderRadius: "10px", // Border radius
            border: `1px solid ${palette.primary[600]}`, // Border
            boxShadow: "none", // Flat style
            overflow: "hidden", // Ensure border radius applies to the whole table
          }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.header}
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: palette.primary[200],
                    }} // Slightly darker background for header
                  >
                    {column.header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: palette.primary[200],
                  }} // Slightly darker background for header
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.header}
                      align={column.align || "left"}
                    >
                      {row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Box>
  );
}

HideableTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      align: PropTypes.oneOf(["left", "right", "center"]),
    })
  ).isRequired,
};

export default HideableTable;
