import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Typography, IconButton, useTheme, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
const addItemWithDependencies = (
  destinationIndex,
  itemToAdd,
  allItems,
  currentList
) => {
  let newList = Array.from(currentList);
  const addItems = [itemToAdd];

  // Add dependencies right after the item
  if (itemToAdd.requires && itemToAdd.requires.length > 0) {
    itemToAdd.requires.forEach((dependencyId) => {
      const dependencyItem = allItems.find((item) => item.id === dependencyId);
      if (dependencyItem && !newList.find((item) => item.id === dependencyId)) {
        addItems.push(dependencyItem); // Add dependency if not already in the list
      }
    });
  }

  newList.splice(destinationIndex, 0, ...addItems);
  return newList;
};

// Function to ensure dependencies follow their parent item after reordering
const reorderWithDependencies = (
  sourceIndex,
  destinationIndex,
  currentList
) => {
  let newList = Array.from(currentList);
  const itemBeingMoved = newList[sourceIndex];

  // Function to find the highest index among the dependencies of an item
  const findHighestDependencyIndex = (item, list) => {
    return item.requires?.reduce((highestIndex, depId) => {
      const currentIndex = list.findIndex((listItem) => listItem.id === depId);
      return currentIndex > highestIndex ? currentIndex : highestIndex;
    }, -1);
  };

  // Function to find the lowest index among the items that depend on the given item
  const findLowestDependentIndex = (itemId, list) => {
    return list.reduce((lowestIndex, listItem, index) => {
      return listItem.requires?.includes(itemId) &&
        (lowestIndex === -1 || index < lowestIndex)
        ? index
        : lowestIndex;
    }, -1);
  };

  // Check if the move is valid
  const isMoveValid = () => {
    // If itemBeingMoved is a dependency, ensure it's not moved before its parent
    const lowestDependentIndex = findLowestDependentIndex(
      itemBeingMoved.id,
      newList
    );
    if (
      lowestDependentIndex !== -1 &&
      destinationIndex <= lowestDependentIndex
    ) {
      return false; // Moving a parent item behind its dependency
    }

    // If itemBeingMoved has dependencies, ensure it's not moved after its dependencies
    const highestDependencyIndex = findHighestDependencyIndex(
      itemBeingMoved,
      newList
    );
    if (
      highestDependencyIndex !== -1 &&
      destinationIndex > highestDependencyIndex + 1
    ) {
      return false; // Moving a dependent item ahead of its parent
    }

    return true; // Move is valid
  };

  // Abort the move if it's invalid
  if (!isMoveValid()) {
    console.warn(
      "Invalid move: Cannot move in a way that violates dependency order."
    );
    return currentList;
  }

  // Perform the move
  newList.splice(sourceIndex, 1); // Remove the item from its original position
  newList.splice(
    destinationIndex > sourceIndex ? destinationIndex - 1 : destinationIndex,
    0,
    itemBeingMoved
  ); // Insert the item at its new position

  return newList;
};

function DraggableList({ onEditClick, items, setItems, menuItems, styleMap }) {
  const { palette } = useTheme();
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside any list
    if (!destination) {
      return;
    }

    // Handling dragging from menu to items list
    if (source.droppableId === "menu" && destination.droppableId === "items") {
      const draggedItem = menuItems.find(
        (item) => item.id === result.draggableId
      );
      const newItemsList = addItemWithDependencies(
        destination.index,
        draggedItem,
        menuItems,
        items
      );
      setItems(newItemsList);
    } else if (
      source.droppableId === "items" &&
      destination.droppableId === "items"
    ) {
      // Handle reordering within the items list, ensuring dependencies follow
      const newItemsList = reorderWithDependencies(
        source.index,
        destination.index,
        items
      );
      setItems(newItemsList);
    }
  };
  // Check if item is in the items list
  const isItemInItemsList = (itemId) =>
    items.some((item) => item.id === itemId);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
        {/* Menu Items List */}
        <Droppable droppableId="menu">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                backgroundColor: palette.grey[200],
                padding: 2,
                borderRadius: 2,
                width: "100px",
                overflowY: "auto",
              }}
            >
              {menuItems.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={isItemInItemsList(item.id)}
                >
                  {(provided, snapshot) => (
                    <Tooltip title={item.name} placement="right">
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          userSelect: "none",
                          padding: 1,
                          margin: "0 0 8px 0",
                          backgroundColor: isItemInItemsList(item.id)
                            ? palette.grey[400]
                            : "white",
                          color: isItemInItemsList(item.id)
                            ? palette.grey[600]
                            : "inherit",
                          borderRadius: 1,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                          boxShadow: 1,
                          display: "flex",
                          alignItems: "center",
                          opacity: isItemInItemsList(item.id) ? 0.5 : 1,
                          ...(styleMap[item.mapping_value] || {}), // Apply styles based on mapping_value
                        }}
                      >
                        {item.icon}
                      </Box>
                    </Tooltip>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        {/* Items List */}
        <Droppable droppableId="items">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                backgroundColor: palette.primary[300],
                padding: 2,
                borderRadius: 2,
                flexGrow: 1,
                minHeight: "500px",
              }}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`items-${item.id}`}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        userSelect: "none",
                        padding: 1,
                        marginBottom: 1,
                        backgroundColor: "white",
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        ...(styleMap[item.mapping_value] || {}), // Apply styles based on mapping_value
                      }}
                    >
                      <DragIndicatorIcon />
                      <Typography>{item.name}</Typography>
                      <Box>
                        <IconButton onClick={() => onEditClick(item)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            setItems(items.filter((_, i) => i !== index))
                          }
                        >
                          <DeleteIcon sx={{ color: "red" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    </DragDropContext>
  );
}

export default DraggableList;
