import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import CustomLoadingModal from "../../../components/common/userFeedback/CustomLoadingModal";
import NavigationStepper from "../../../components/features/Pipelines/NavigationStepper";
import { BASE_URL } from "../../../config/apiConfig";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import { getHeaders } from "../../../utils/apiUtils";
import DefineGarmentSubScreen from "./DefineGarmentSubScreen";
import JobConfigurationSubScreen from "./JobConfigurationSubScreen";
import PipelineSummarySubScreen from "./PipelineSummarySubScreen";

function CreatePipelineScreen(props) {
  const { id } = useParams();
  const [currentScreen, setCurrentScreen] = useState(0);
  const navigate = useNavigate();
  const { name, setPipelineId, saving, handleSubmit } = usePipelineContext();
  useEffect(() => {
    if (id) {
      setPipelineId(id);
    }
  }, [id, setPipelineId]);

  return (
    <>
      <CustomLoadingModal
        waitToShowModal={saving}
        title="Pipeline Saved"
        body={`Your Pipeline "${name}" has been saved`}
        onDismiss={() => navigate(-1)}
      />
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavigationStepper
          currentScreen={currentScreen}
          setCurrentScreen={setCurrentScreen}
          numScreens={3}
        />
        {currentScreen === 0 && <DefineGarmentSubScreen />}
        {currentScreen === 1 && <JobConfigurationSubScreen />}
        {currentScreen === 2 && (
          <PipelineSummarySubScreen handleSubmit={handleSubmit} />
        )}
      </Box>
    </>
  );
}

export default CreatePipelineScreen;
